import { permission } from '../decorators';

import { Language } from './Language';
import { getPluralSuffix } from './PluralForms';

export interface TranslateOptions<TResult = string> extends Record<string, unknown> {
  context?: string | string[];
  prefix?: string;
  prefixSeparator?: string;
  ns?: string;
  default?: TResult;
  count?: number;
  returnObjects?: boolean;
  data?: (Record<string, unknown> & { count?: number }) | unknown[];
}

export class Translation {
  private static defaultNS = 'translations';

  private translations: Partial<Record<Language, Record<string, unknown>>> = {
    [Language.CS]: {},
    [Language.EN]: {},
    [Language.SK]: {},
    [Language.PL]: {},
  };

  private missingTranslations: Partial<Record<Language, Record<string, TranslateOptions<UnsafeAny>>>> = {};

  public get language(): Language {
    return this.currentLanguage;
  }

  constructor(private currentLanguage: Language, translations: { language: Language; ns: string; data: unknown }[]) {
    for (const { language, ns, data } of translations) {
      this.translations[language][ns] = data;
    }
  }

  public setLanguage(language: Language): void {
    this.currentLanguage = language;
  }

  public translate<TResult = string>(id: string | string[], tOptions: TranslateOptions<TResult> = {}): TResult {
    const { context, prefix, prefixSeparator = '.', ...options } = tOptions;
    const fallback = (Array.isArray(id) ? id[0] : id) as unknown as TResult;
    const keys = this.resolveKeys(id, tOptions);

    if (!keys.length) return this.fallback(fallback, options);
    if (!Array.isArray(options.data) && typeof options.data?.count === 'number') {
      options.count = options.data.count;
    }
    if (typeof options.count === 'number') {
      keys.unshift(...this.getPluralForms(keys, options.count));
      if (!options.data) {
        options.data = { count: options.count };
      } else if (!Array.isArray(options.data) && typeof options.data?.count !== 'number') {
        options.data.count = options.count;
      }
    }
    for (let key of keys.filter(it => typeof it === 'string')) {
      let ns = options.ns ?? Translation.defaultNS;
      const keyNSMatch = key.match(/^(\w+):/);
      if (keyNSMatch) {
        ns = keyNSMatch[1];
        key = key.slice(keyNSMatch[0].length);
      }
      const resources = this.translations[this.currentLanguage][ns];
      if (!resources) continue;
      const found = resources[key] as TResult | undefined;
      if (typeof found !== 'undefined') {
        return this.interpolate(found, options);
      }
    }
    this.reportMissingTranslation(keys, options);
    return this.fallback(fallback, options);
  }

  private getPluralForms(keys: string[], count: number): string[] {
    const suffix = getPluralSuffix(this.currentLanguage, count);
    if (suffix === '') return [];
    return keys.map(it => `${it}${suffix}`);
  }

  private interpolate<TResult = string>(translation: TResult, options: TranslateOptions<TResult>): TResult {
    if (typeof translation !== 'string') return translation;
    const { data = {} } = options;
    let result: string = translation;
    if (data instanceof Array) {
      for (let i = 0; i < data.length; i++) {
        result = result.replaceAll(new RegExp(`%${i + 1}\\$[ds]`, 'gi'), data[i] as string);
      }
    } else {
      for (const [key, value] of Object.entries(data)) {
        result = result.replaceAll(`{{${key}}}`, value as string);
      }
    }
    result = result.replaceAll(/\$t\(([a-z0-9.]+)\)/gi, (it, ...args) => {
      if (!args.length) return it;
      return this.translate(args[0]);
    });
    return result as unknown as TResult;
  }

  private fallback<TResult = string>(fallback: TResult, { default: defaultValue }: TranslateOptions<TResult>): TResult {
    if (process.env.GATSBY_TRANSLATION_DEBUG === 'true') return defaultValue ?? fallback;
    return (defaultValue ?? '') as TResult;
  }

  public resolveKeys<TResult = string>(
    id: string | string[],
    { context, prefix, prefixSeparator = '.', ...options }: TranslateOptions<TResult> = {},
  ): string[] {
    const keys: string[] = !Array.isArray(id) ? [id] : id;
    const contextKeys = [];
    if (prefix) {
      keys.unshift(...keys.filter(it => !it.startsWith(prefix)).map(it => `${prefix}${prefixSeparator}${it}`));
    }
    for (const key of keys) {
      if (context) {
        if (!Array.isArray(context)) {
          context = [context];
        }
        let combinedCtx = '';
        for (const ctx of context) {
          contextKeys.push(`${key}_${ctx}`);
          combinedCtx += `_${ctx}`;
        }
        if (combinedCtx.length) {
          contextKeys.unshift(`${key}${combinedCtx}`);
        }
      }
    }
    keys.unshift(...contextKeys);

    if (typeof options.count === 'number') {
      keys.unshift(...this.getPluralForms(keys, options.count));
    }

    return keys;
  }

  @permission(
    process.env.NODE_ENV !== 'production' &&
      process.env.GATSBY_TRANSLATION_DEBUG === 'true' &&
      typeof window !== 'undefined',
  )
  private reportMissingTranslation<TResult = string>(keys: string[], options: TranslateOptions<TResult>): void {
    if (!this.missingTranslations[this.currentLanguage]) {
      this.missingTranslations[this.currentLanguage] = {};
    }
    for (const key of keys) {
      this.missingTranslations[this.currentLanguage][key] = options;
    }
    // do not show in test environment
    if (process.env.NODE_ENV !== 'test') {
      console.debug(
        `Missing translation for %c'${keys.join(`', '`)}'`,
        'font-weight: bold',
        `in language '${this.currentLanguage}'`,
        options,
      );
    }
  }

  public extractMissingTranslations(): Partial<Record<Language, Record<string, TranslateOptions<UnsafeAny>>>> {
    const result = this.missingTranslations;
    this.missingTranslations = {};
    return result;
  }
}
