import { permission } from '../decorators';
import type { User } from '../model/User';

import type { EventType } from './EventType';
import { Logger } from './Logger';
import OneTrust, { ConsentGroup } from './OneTrust';
import type { Pageview } from './Pageview';

type MappingResult = string | UnsafeAny[] | ((...args: UnsafeAny[]) => string | UnsafeAny[] | void);
type PageContextMapping = { default?: MappingResult } & Partial<Record<Pageview, MappingResult>>;
export type Mapping = Partial<Record<EventType | Pageview, MappingResult | PageContextMapping>>;

export interface TrackerAdapter {
  readonly isLoaded: boolean;

  load(): void | Promise<void>;

  eventMapping?: Mapping;

  enable?(): void;

  disable?(): void;

  login?(user: User): void;

  logout?(): void;

  signup?(user: User): void;

  pageView?(page: Pageview): void;

  track(event: string, eventData?: Record<string, UnsafeAny>, context?: Record<string, UnsafeAny>): void;

  onAppInit?(): void;
}

interface AdapterCtor {
  requiredConsent?: ConsentGroup;
  debugName?: string;

  new (): TrackerAdapter;
}

class Tracker {
  private logger = new Logger('Tracking', {
    [Logger.Level.DEBUG]: process.env.GATSBY_TRACKING_DEBUG === 'true',
    [Logger.Level.WARNING]: process.env.NODE_ENV !== 'production',
    [Logger.Level.ERROR]: true,
  });
  private adapterCtors: AdapterCtor[] = [];
  private adapterInstances: TrackerAdapter[] = [];
  private adapters: Set<TrackerAdapter> = new Set();
  private lastEventPerAdapter: Map<TrackerAdapter, number> = new Map();
  private pageContext?: Pageview;
  private queue: (['event', EventType, UnsafeAny] | ['pageview', Pageview, UnsafeAny])[] = [];
  private appInitialized = false;

  @permission(process.env.GATSBY_TRACKING_ENABLED !== 'true')
  public addAdapter(adapter: AdapterCtor): void {
    this.logger.debug(`adapter added`, {
      adapter: adapter.debugName ?? adapter.name,
    });
    this.adapterCtors.push(adapter);
    const { requiredConsent = ConsentGroup.NECESSARY } = adapter;
    const instance = new adapter();
    this.adapterInstances.push(instance);
    this.lastEventPerAdapter.set(instance, 0);

    if (OneTrust.isCookieConsentGroupAllowed(requiredConsent)) {
      this.initAdapter(instance, adapter);
    }
  }

  @permission(process.env.GATSBY_TRACKING_ENABLED !== 'true')
  public init() {
    OneTrust.subscribe(groups => {
      for (const [index, adapter] of this.adapterCtors.entries()) {
        const instance = this.adapterInstances[index];
        if (groups[adapter.requiredConsent ?? ConsentGroup.NECESSARY]) {
          if (this.adapters.has(instance)) return;
          this.initAdapter(instance, adapter);
        } else {
          this.logger.debug('adapter disabled', {
            adapter: adapter.debugName ?? adapter.name,
          });
          instance.disable?.();
          this.adapters.delete(instance);
          this.lastEventPerAdapter.set(instance, this.queue.length);
        }
      }
    });
  }

  @permission(process.env.GATSBY_TRACKING_ENABLED !== 'true')
  public track(event: EventType, ...args: UnsafeAny) {
    this.queue.push(['event', event, args]);
    if (!this.appInitialized) return;
    for (const adapter of this.adapters) {
      if (adapter.eventMapping && event in adapter.eventMapping) {
        const parsed = this.parseMapping(adapter.eventMapping[event], ...args);
        if (!parsed) continue;
        adapter.track(...parsed);
      }
    }
  }

  @permission(process.env.GATSBY_TRACKING_ENABLED !== 'true')
  public trackAppInit(...args: UnsafeAny) {
    this.appInitialized = true;
    this.queue.unshift(['event', 'APP_INIT', args]);
    for (const adapter of this.adapterInstances) {
      adapter.onAppInit?.();
    }
    for (const adapter of this.adapters) {
      this.processQueue(adapter);
    }
    this.cleanupQueue();
  }

  @permission(process.env.GATSBY_TRACKING_ENABLED !== 'true')
  public login(user: User) {
    for (const adapter of this.adapters) {
      adapter.login?.(user);
    }
  }

  @permission(process.env.GATSBY_TRACKING_ENABLED !== 'true')
  public logout() {
    for (const adapter of this.adapterInstances) {
      adapter.logout?.();
    }
  }

  @permission(process.env.GATSBY_TRACKING_ENABLED !== 'true')
  public signup(user: User) {
    for (const adapter of this.adapterInstances) {
      adapter.signup?.(user);
    }
  }

  @permission(process.env.GATSBY_TRACKING_ENABLED !== 'true')
  public pageview(page: Pageview, ...args: unknown[]) {
    this.queue.push(['pageview', page, args]);
    if (!this.appInitialized) return;
    this.pageContext = page;
    for (const adapter of this.adapters) {
      if (adapter.eventMapping && page in adapter.eventMapping) {
        const parsed = this.parseMapping(adapter.eventMapping[page], ...args);
        if (!parsed) continue;
        adapter.track(...parsed);
      } else {
        adapter.pageView?.(page);
      }
    }
  }

  public modalView(modal: Pageview, ...args: unknown[]) {
    const pageContext = this.pageContext;
    this.pageview(modal, ...args);
    this.pageContext = pageContext;
  }

  private parseMapping<T extends EventType | Pageview>(
    value: Mapping[T],
    ...args: unknown[]
  ): [string, Record<UnsafeAny, UnsafeAny>, Record<UnsafeAny, UnsafeAny> | undefined] | void {
    if (value === null || value === undefined) return;
    if (typeof value === 'object' && !Array.isArray(value)) {
      if (this.pageContext && this.pageContext in value) {
        return this.parseMapping(value[this.pageContext], ...args);
      }
      if ('default' in value) {
        return this.parseMapping(value.default, ...args);
      }
      return;
    }
    if (value instanceof Function) {
      const result = value(...args) as
        | [string, Record<UnsafeAny, UnsafeAny>, Record<UnsafeAny, UnsafeAny> | undefined]
        | void
        | string;
      if (!result) return;
      if (Array.isArray(result)) return result;
      return [result, undefined, undefined];
    }
    if (Array.isArray(value)) {
      return value as [string, Record<UnsafeAny, UnsafeAny>, Record<UnsafeAny, UnsafeAny> | undefined];
    }
    return [value, undefined, undefined];
  }

  private initAdapter(instance: TrackerAdapter, adapter: AdapterCtor) {
    Promise.resolve(instance.isLoaded ? null : instance.load())
      .catch(error => {
        this.logger.error(`adapter load error`, {
          adapter: adapter.debugName ?? adapter.name,
          error,
        });
      })
      .then(() => {
        this.logger.debug('adapter enabled', {
          adapter: adapter.debugName ?? adapter.name,
        });
        instance.enable?.();
        this.adapters.add(instance);
        if (!this.appInitialized) return;
        this.processQueue(instance);
        this.cleanupQueue();
      });
  }

  private processQueue(instance: TrackerAdapter) {
    const lastIndex = this.lastEventPerAdapter.get(instance) ?? 0;
    const currentContext = this.pageContext;
    for (const [type, event, args] of this.queue.slice(lastIndex)) {
      if (type === 'pageview') {
        this.pageContext = event as Pageview;
      }
      if (instance.eventMapping && event in instance.eventMapping) {
        const parsed = this.parseMapping(instance.eventMapping[event], ...args);
        if (!parsed) continue;
        instance.track(...parsed);
      } else if (type === 'pageview') {
        instance.pageView?.(event as Pageview);
      }
    }
    this.pageContext = currentContext;
  }

  private cleanupQueue() {
    let lastIndex = 0;
    for (const index of this.lastEventPerAdapter.values()) {
      lastIndex = Math.max(lastIndex, index);
    }
    this.queue.splice(0, lastIndex);
    for (const [it, index] of this.lastEventPerAdapter.entries()) {
      this.lastEventPerAdapter.set(it, index - lastIndex);
    }
  }
}

const instance = new Tracker();
instance.init();

export function addDefaultAdapters() {
  if (typeof process.env.GATSBY_GOOGLE_TAG_MANAGER_KEY === 'string') {
    import('./GoogleAnalytics').then(module => {
      instance.addAdapter(module.GoogleAnalytics);
    });
  }
  if (typeof process.env.GATSBY_MOENGAGE_APP_ID === 'string') {
    import('./MoEngage').then(module => {
      instance.addAdapter(module.MoEngage);
    });
  }
}

export default instance;
