import qs from 'query-string';
import { v4 as uuid } from 'uuid';

import { type Country, defaultRegion } from '../services/Country';
import { type Datelike, getYear, isDatelike, isSameMinute, toISOString } from '../services/DateTime';

import { type FiltrationParameters, getPricePerDayMax, getPricePerDayMin } from './FiltrationParameters';
import type { Place } from './Place';
import { countryToCurrency, type CurrencyCode } from './Price';
import { TripType } from './Trip';

interface RangeFilter<T = number> {
  min: T;
  max: T;
}

interface FiltersQuery {
  country_code: Country;
  date_from: string;
  date_to: string;

  driver_under_25_years: boolean;
  delivery_car: boolean;
  travel_abroad: boolean;
  instant_booking: boolean;
  owner_speaks_english: boolean;

  transmission: string[];
  manufacturer: number[];
  model: number;
  equipment: string[];
  type: string[];
  fuel: string[];
  trip_type: TripType;
}

interface Filters extends FiltersQuery {
  seats: RangeFilter;
  price_per_day: RangeFilter;
  mileage_price_over_limit: RangeFilter;
  consumption: RangeFilter;
  raid: RangeFilter;

  order: {
    by: string;
    type: string;
  };
}

export interface SearchFilters extends Filters {
  key: string;
  location: Place;
  first_registration: RangeFilter;

  without_vat: boolean;
  own_insurance: boolean;
  favorites: boolean;
}

export interface SearchFiltersQuery extends Partial<FiltersQuery> {
  lat?: string;
  lon?: string;
  city?: string;
  place_id?: string;

  without_vat?: 'true' | 'false';
  own_insurance?: 'true' | 'false';
  favorites?: 'true' | 'false';

  'seats.min'?: number;
  'seats.max'?: number;
  'price_per_day.min'?: number;
  'price_per_day.max'?: number;
  'mileage_price_over_limit.min'?: number;
  'mileage_price_over_limit.max'?: number;
  'first_registration.min'?: number;
  'first_registration.max'?: number;
  'consumption.min'?: number;
  'consumption.max'?: number;
  'raid.min'?: number;
  'raid.max'?: number;

  'order.by'?: ORDER_BY;
  'order.type'?: ORDER_TYPE;
}

export interface SearchFiltersAPI extends Partial<Filters> {
  currency_code: CurrencyCode;
  lat?: number;
  lon?: number;
  google_location_address?: string;
  first_registration?: RangeFilter<string>;
  without_vat?: boolean;
  own_insurance?: boolean;
  tags?: string[];
}

export const SEATS_MIN = 1;
export const SEATS_MAX = 10;
export const SEATS_STEP = 1;

export const PRICE_PER_DAY_MIN = 0;
export const PRICE_PER_DAY_MAX = 100;
export const PRICE_PER_DAY_STEP = 1;

const getLogBase = (range: number, steps = (PRICE_PER_DAY_MAX - PRICE_PER_DAY_MIN) / PRICE_PER_DAY_STEP): number =>
  Math.log(range) / Math.log(steps);
const getRangeValue = (value: number, base: number, min = 0): number => Math.pow(value, base) + min;

export function computePricePerDayValue(value: number, min: number, max: number): number {
  const base = getLogBase(max - min);
  return getRangeValue(value, base, min);
}

export const MILEAGE_PRICE_OVER_LIMIT_MIN = 0;
export const MILEAGE_PRICE_OVER_LIMIT_MAX = 20;
export const MILEAGE_PRICE_OVER_LIMIT_STEP = 1;

export const RANGE_MIN = 0;
export const RANGE_MAX = 500000;
export const RANGE_STEP = 10;

export const FIRST_REGISTRATION_MIN = 1900;
export const FIRST_REGISTRATION_MAX = (() => getYear(new Date()))();
export const FIRST_REGISTRATION_STEP = 1;

export const CONSUMPTION_MIN = 4;
export const CONSUMPTION_MAX = 20;
export const CONSUMPTION_STEP = 0.5;

export enum ORDER_BY {
  SMART_SEARCH_POINTS = 'smart_search_points',
  PRICE_PER_DAY = 'price_per_day',
  DISTANCE = 'distance',
  CREATED_AT = 'created_at',
  CONFIRMED_AT = 'confirmed_at',
}

export enum ORDER_TYPE {
  ASC = 'asc',
  DESC = 'desc',
}

export const arrayFilters: (keyof SearchFilters)[] = ['fuel', 'transmission', 'manufacturer', 'equipment', 'type'];

export const rangeFilters: (keyof SearchFilters)[] = [
  'price_per_day',
  'mileage_price_over_limit',
  'consumption',
  'first_registration',
  'seats',
];

export const valueFilters: (keyof SearchFilters)[] = ['model', 'trip_type'];

export const booleanFilters: (keyof SearchFilters)[] = [
  'own_insurance',
  'without_vat',
  'delivery_car',
  'travel_abroad',
  'driver_under_25_years',
  'instant_booking',
  'owner_speaks_english',
  'favorites',
];

export const initialSearchFilters: SearchFilters = {
  key: 'initial',

  country_code: defaultRegion,
  date_from: null,
  date_to: null,
  location: {
    name: '',
  },

  transmission: [],
  manufacturer: [],
  model: null,
  equipment: [],
  type: [],
  fuel: [],
  trip_type: TripType.BUSINESS,

  driver_under_25_years: false,
  delivery_car: false,
  travel_abroad: false,
  instant_booking: false,
  without_vat: false,
  own_insurance: false,
  owner_speaks_english: false,
  favorites: false,

  seats: {
    min: SEATS_MIN,
    max: SEATS_MAX,
  },
  price_per_day: {
    min: PRICE_PER_DAY_MIN,
    max: PRICE_PER_DAY_MAX,
  },
  mileage_price_over_limit: {
    min: MILEAGE_PRICE_OVER_LIMIT_MIN,
    max: MILEAGE_PRICE_OVER_LIMIT_MAX,
  },
  first_registration: {
    min: FIRST_REGISTRATION_MIN,
    max: FIRST_REGISTRATION_MAX,
  },
  consumption: {
    min: CONSUMPTION_MIN,
    max: CONSUMPTION_MAX,
  },
  raid: {
    min: RANGE_MIN,
    max: RANGE_MAX,
  },

  order: {
    by: ORDER_BY.SMART_SEARCH_POINTS,
    type: ORDER_TYPE.DESC,
  },
};

export function computeNextFilters(current: SearchFilters, next: Partial<SearchFilters>): SearchFilters {
  if (!hasChanged({ ...next, key: undefined }, current)) return current;
  const key = next.key ?? uuid();
  return { ...current, ...next, key };
}

export function hasChanged(filters: Partial<SearchFilters>, comparison: SearchFilters) {
  if (typeof filters.key === 'string' && filters.key !== comparison.key) {
    return true;
  }

  for (const [filter, value] of Object.entries(filters)) {
    if (Array.isArray(value)) {
      if (value.length !== (comparison[filter] as Array<unknown>).length) {
        return true;
      }
      for (const innerValue of value) {
        if (!(comparison[filter] as Array<unknown>).includes(innerValue)) {
          return true;
        }
      }
    } else if (
      ['date_from', 'date_to'].includes(filter) &&
      !isSameMinute(value as Datelike, comparison[filter] as Datelike)
    ) {
      return true;
    } else if (typeof value === 'object' && value !== null) {
      for (const [key, innerValue] of Object.entries(value)) {
        if (comparison[filter][key] !== innerValue) {
          return true;
        }
      }
    } else if (value !== comparison[filter]) {
      return true;
    }
  }
  return false;
}

export function filtersToAPIData(
  filters: Partial<SearchFilters> = initialSearchFilters,
  defaultFilters: SearchFilters = initialSearchFilters,
  filtrationParams: Partial<FiltrationParameters> = {},
): SearchFiltersAPI {
  const country_code = filters.country_code ?? defaultFilters.country_code;
  const result: SearchFiltersAPI = {
    currency_code: countryToCurrency(country_code),
    country_code,
    date_from: toISOString(filters.date_from),
    date_to: toISOString(filters.date_to),
    order: filters.order ? { ...filters.order } : undefined,
  };

  if (filters.location?.lat && filters.location?.lon) {
    result.lat = filters.location.lat;
    result.lon = filters.location.lon;
  }

  if (filters.location && filters.location?.name !== defaultFilters.location.name) {
    result.google_location_address = filters.location.name;
  }

  for (const filter of booleanFilters) {
    if (!(filter in filters)) continue;
    if (filters[filter] !== defaultFilters[filter]) {
      result[filter] = filters[filter];
    }
  }

  for (const filter of valueFilters) {
    if (!filters[filter]) continue;
    if (filters[filter] !== defaultFilters[filter]) {
      result[filter] = filters[filter];
    }
  }

  for (const filter of arrayFilters) {
    if (!filters[filter]) continue;
    if ((filters[filter] as Array<string | number>).length) {
      result[filter] = filters[filter];
    }
  }

  for (const filter of rangeFilters) {
    if (!filters[filter]) continue;
    const value = filters[filter] as RangeFilter;
    const defaultValue = defaultFilters[filter] as RangeFilter;
    if (value.min === defaultValue.min && value.max === defaultValue.max) {
      continue;
    }
    if (filter === 'price_per_day') {
      const min = filtrationParams?.average_price_per_day_extreme_min
        ? getPricePerDayMin(result.country_code, filtrationParams)
        : defaultValue.min;
      const max = filtrationParams?.average_price_per_day_extreme_max
        ? getPricePerDayMax(result.country_code, filtrationParams)
        : defaultValue.max;
      result[filter] = {
        min: computePricePerDayValue(value.min, min, max),
        max: computePricePerDayValue(value.max, min, max),
      };
      continue;
    }
    if (filter === 'first_registration') {
      result[filter] = {
        min: `${value.min}-01-01T00:00:00Z`,
        max: `${value.max}-01-01T00:00:00Z`,
      };
      continue;
    }
    result[filter] = value;
  }

  return result;
}

export function filtersToQueryString(
  filters: Partial<SearchFilters | SearchFiltersAPI>,
  initial: SearchFilters,
): string {
  const params: SearchFiltersQuery = {};
  const processed = ['key', 'date_from', 'date_to'];

  // Datum/čas
  if (filters.date_from && isDatelike(filters.date_from) && !isSameMinute(filters.date_from, initial.date_from)) {
    params.date_from = toISOString(filters.date_from);
  }
  if (filters.date_to && isDatelike(filters.date_to) && !isSameMinute(filters.date_to, initial.date_to)) {
    params.date_to = toISOString(filters.date_to);
  }

  // Location
  if ((filters as SearchFilters).location && (filters as SearchFilters).location.name !== '') {
    const { location } = filters as SearchFilters;
    params.city = location.name;
    params.lat = location.lat?.toString();
    params.lon = location.lon?.toString();
    params.place_id = location.id;
  }
  processed.push('location');

  Object.entries(filters).forEach(([key, value]) => {
    if (
      key in params ||
      processed.includes(key) ||
      !(key in initial) ||
      initial[key] === value ||
      value === undefined ||
      value === null
    ) {
      return;
    }
    if (Array.isArray(value)) {
      params[key] = value;
    } else if (typeof value === 'object') {
      Object.entries(value as Record<string, unknown>).forEach(([innerKey, innerValue]) => {
        if (initial[key][innerKey] === innerValue) {
          return;
        }
        params[`${key}.${innerKey}`] = innerValue;
      });
    } else {
      params[key] = value;
    }
  });

  return qs.stringify(params);
}

export function queryStringToFilters(queryString: string, initial: SearchFilters): SearchFilters {
  const filters: SearchFilters = { ...initial };

  if (queryString === '') {
    return filters;
  }

  try {
    const query = qs.parse(queryString) as Partial<SearchFiltersQuery>;

    if (query.date_from) {
      filters.date_from = toISOString(query.date_from);
      delete query.date_from;
    }
    if (query.date_to) {
      filters.date_to = toISOString(query.date_to);
      delete query.date_to;
    }

    if (query.lat || query.lon || query.city || query.place_id) {
      filters.location = {
        lat: query.lat ? Number.parseFloat(query.lat) : undefined,
        lon: query.lon ? Number.parseFloat(query.lon) : undefined,
        name: query.city,
        id: query.place_id,
      };
      delete query.lat;
      delete query.lon;
      delete query.city;
      delete query.place_id;
    }

    for (const [k, value] of Object.entries(query)) {
      const key = k.split('.')[0] as keyof SearchFilters;

      if (key === 'manufacturer') {
        filters[key] = !Array.isArray(value)
          ? [Number.parseInt(value as string, 10)]
          : (value as string[]).map(v => Number.parseInt(v, 10));
      } else if (arrayFilters.includes(key)) {
        (filters[key] as (string | number | boolean)[]) = !Array.isArray(value) ? [value] : value;
      } else if (rangeFilters.includes(key)) {
        (filters[key] as RangeFilter) = { ...(initial[key] as RangeFilter) };
        if (query[`${key}.min`]) {
          (filters[key] as RangeFilter).min = Number.parseInt(query[`${key}.min`], 10);
        }
        if (query[`${key}.max`]) {
          (filters[key] as RangeFilter).max = Number.parseInt(query[`${key}.max`], 10);
        }
      } else if (key === 'model') {
        filters[key] = Number.parseInt(value as string, 10);
      } else if (key === 'order') {
        filters[key] = {
          by: query[`${key}.by`] ?? initial.order.by,
          type: query[`${key}.type`] ?? initial.order.type,
        };
      } else if (value === 'true') {
        (filters[key] as boolean) = true;
      } else {
        (filters[key] as UnsafeAny) = value;
      }
    }
  } catch (err) {
    throw new Error(`Parsing URL hash has failed: ${err.message}`);
  }

  return filters;
}
