import React, { useContext, useMemo } from 'react';
import { type HLocation, type NavigateOptions, useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import * as pathToRegexp from 'path-to-regexp';
import queryString from 'query-string';

import type { Language } from './services/Language';
import { useCampaignContext } from './CampaignProvider';
import type RouteEnum from './RouteEnum';

const cache = {};
const cacheLimit = 10000;
let cacheCount = 0;

function compilePath(path) {
  if (cache[path]) return cache[path];

  const generator = pathToRegexp.compile(path);

  if (cacheCount < cacheLimit) {
    cache[path] = generator;
    cacheCount++;
  }

  return generator;
}

function isRelative(path: string): boolean {
  return path.startsWith('../') || path.startsWith('./');
}

export interface Options<TState> extends NavigateOptions<TState> {
  withoutPrefix?: boolean;
}

export interface RouteParams extends Record<string, string> {
  lang?: Language | string;
}

export interface RouteContext<Params extends Record<string, string>> {
  location: HLocation;
  readonly params: Params;
  readonly parametrized: boolean;
  readonly prefix?: string;
  readonly pathWithoutPrefix: string;

  navigate(to: number): Promise<void>;

  navigate<TState = undefined>(to: string, options?: Options<TState>): Promise<void>;

  getPrefixedPath(path: string): string;

  generatePath<P extends RouteParams>(
    route: RouteEnum | string,
    params?: Partial<P>,
    search?: string,
    hash?: string,
  ): string;

  generateCanonicalUrl<P extends RouteParams | Params = Params>(
    path: string,
    params?: Partial<P>,
    query?: string | Record<string, Primitives>,
  ): string;
}

const context = React.createContext<RouteContext<UnsafeAny>>(null);

export function useRouteContext<Params extends Record<string, string> = UnsafeAny>(): RouteContext<Params> {
  return useContext(context);
}

interface RouteProviderProps<Params extends Record<string, string>> {
  params?: Params;
  prefix?: string;
  parametrized?: boolean;
}

export function RouteProvider<Params extends Record<string, string>>(
  props: React.PropsWithChildren<RouteProviderProps<Params>>,
) {
  const location = useLocation();
  const campaign = useCampaignContext();
  const pathWithoutPrefix = useMemo(() => {
    if (typeof props.prefix !== 'string') return location.pathname;
    return location.pathname.replace(`/${props.prefix}`, '');
  }, [location, props.prefix]);

  function getPrefixedPath(path: string): string {
    if (isRelative(path) || typeof props.prefix !== 'string') return path;
    return `/${props.prefix}${path}`;
  }

  const ctx: RouteContext<Params> = {
    location,
    params: props.params,
    prefix: props.prefix,
    parametrized: props.parametrized,
    pathWithoutPrefix,
    navigate<TState = undefined>(to: string | number, options?: Options<TState>): Promise<void> {
      if (typeof to === 'number') return navigate(to);
      let path = campaign.getCampaignPath(to);
      path = options?.withoutPrefix ? path : getPrefixedPath(path);
      return navigate(path, options);
    },
    getPrefixedPath,
    generatePath<P extends RouteParams>(
      route: RouteEnum | string,
      params: Partial<P> = {},
      query: string | Record<string, Primitives> = '',
      hash = '',
    ): string {
      let result = route === '/' ? route : compilePath(route)(params, { pretty: true });
      let search = '';
      if (query) {
        search = typeof query === 'string' ? query : queryString.stringify(query);
      }
      if (search !== '') {
        result += `${search.startsWith('?') ? search : '?' + search}`;
      }
      if (hash !== '') {
        result += `${hash.startsWith('#') ? hash : '#' + hash}`;
      }
      return campaign.getCampaignPath(result);
    },
    generateCanonicalUrl<P extends RouteParams | Params = Params>(
      path: string,
      params?: Partial<P>,
      query?: string | Record<string, Primitives>,
    ): string {
      if (typeof path !== 'string') return pathWithoutPrefix;
      const parsedQuery = typeof query === 'string' ? query : query ? queryString.stringify(query) : undefined;
      return ctx.generatePath<P>(path, (params ?? props.params) as P, parsedQuery);
    },
  };

  return <context.Provider value={ctx}>{props.children}</context.Provider>;
}

RouteProvider.displayName = 'RouteProvider';
