import type { User } from '../model/User';

import { isAfterNow } from './DateTime';

export type Authorization = {
  access_token: string;
  expires: string;
  refresh_token: string;
  user: User;
};

export default class Auth {
  private static access_token = null;
  private static refresh_token = null;
  private static expires = null;
  private static pendingRefresh: Promise<void> = null;
  private static readonly storage_key: string = 'hoppygo_auth_v3';
  private static readonly refresh_token_url: string = '/api/v3/auth/refresh_token';
  public static disabled = false;

  public static hasToken() {
    return this.access_token !== null;
  }

  public static isValid() {
    return this.access_token !== null && isAfterNow(this.expires);
  }

  public static async getAccessToken(required = false): Promise<string> {
    if (this.disabled) {
      return required ? Promise.reject() : null;
    }
    if (!this.hasToken()) {
      return required ? Promise.reject() : null;
    }
    if (!this.isValid()) {
      await this.refresh();
    }
    return this.access_token;
  }

  public static loadAuth() {
    const storedData = window.localStorage.getItem(this.storage_key);
    if (storedData !== null) {
      const data = JSON.parse(storedData);
      if (data.access_token && data.expires && data.refresh_token) {
        this.setAuth(data, false);
      } else {
        window.localStorage.removeItem(this.storage_key);
      }
    }
  }

  public static setAuth({ access_token, refresh_token, expires }: Authorization, persist = true) {
    this.access_token = access_token;
    this.refresh_token = refresh_token;
    this.expires = expires;

    if (persist && typeof window !== 'undefined') {
      window.localStorage.setItem(
        this.storage_key,
        JSON.stringify({
          access_token,
          refresh_token,
          expires,
        }),
      );
    }
  }

  public static refresh(): Promise<void> {
    if (!this.pendingRefresh) {
      this.pendingRefresh = fetch(this.refresh_token_url, {
        method: 'post',
        body: `{ "refresh_token": "${this.refresh_token}" }`,
        headers: {
          'X-Api-Key': process.env.GATSBY_API_KEY,
          'X-App-Token': process.env.GATSBY_XAPP_TOKEN,
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(response => this.setAuth(response))
        .catch(() => this.destroy())
        .finally(() => {
          this.pendingRefresh = null;
        });
    }
    return this.pendingRefresh;
  }

  public static destroy() {
    this.access_token = null;
    this.refresh_token = null;
    this.expires = null;

    if (typeof window !== 'undefined') {
      window.localStorage.removeItem(this.storage_key);
    }
  }
}
