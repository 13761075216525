import { PrivateApiV3 } from '../services/Api';

import type { PriceType } from './Price';
import type { FlexibleDiscount, MyVehicleDiscount, VehiclePriceListItem } from './VehiclePriceList';

export interface VehicleDetailPrices<List = VehiclePriceListItem> {
  price: PriceType;
  price_per_km_min: PriceType;
  price_per_km_max: PriceType;
  price_per_km: PriceType;
  price_per_day_min: PriceType;
  price_per_day_max: PriceType;
  price_list: List[];
}

export interface EstimatedPriceResponse {
  estimated_price: PriceType;
}

export interface DefaultPricesResponse extends EstimatedPriceResponse {
  price_per_day: PriceType;
  price_per_km: PriceType;
}

export function getVehiclePrice(noOfDays: number, prices: VehicleDetailPrices): PriceType {
  const pricePerDay = getVehiclePricePerDay(prices, noOfDays);
  if (!pricePerDay) return null;
  return {
    amount: noOfDays * pricePerDay.amount,
    currency: pricePerDay.currency,
  };
}

export function getVehiclePriceListItem<T extends FlexibleDiscount | MyVehicleDiscount>(
  prices: VehicleDetailPrices<T>,
  noOfDays = 1,
): T {
  if (!Array.isArray(prices?.price_list)) return null;

  const priceListAsc = prices.price_list.sort((a, b) => (a.min_days < b.min_days ? -1 : 1));
  for (let i = priceListAsc.length - 1; i > 0; i--) {
    if (
      priceListAsc[i].min_days <= noOfDays &&
      (priceListAsc[i + 1]?.min_days ?? Number.POSITIVE_INFINITY) > noOfDays
    ) {
      return priceListAsc[i];
    }
  }
  return priceListAsc[0];
}

export function getVehiclePricePerDay(prices: VehicleDetailPrices, noOfDays = 1): PriceType {
  return getVehiclePriceListItem(prices, noOfDays)?.price;
}

export async function fetchVehicleDefaultPrices(vehicleHash: string) {
  try {
    const response = await PrivateApiV3.get<DefaultPricesResponse>(`/my_vehicles/${vehicleHash}/default_prices`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export async function fetchVehiclePublicPrice(vehicleHash: string, price: number) {
  const response = await PrivateApiV3.post<EstimatedPriceResponse>(`/my_vehicles/${vehicleHash}/estimated-price`, {
    price,
  });
  return response.data.estimated_price;
}
