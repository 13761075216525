export enum CookieKeys {
  REGION = 'hg_region',
  LANGUAGE = 'nf_lang',
  USER = 'hg_user',
  CONSENTS = 'hg_consents',
}

interface CookieOptions {
  path?: string;
  domain?: string;
  expires?: Date;
  secure?: boolean;
  sameSite?: 'Strict' | 'Lax' | 'None';
}

const defaultOptions: CookieOptions = {
  path: '/',
  secure: true,
  sameSite: 'Strict',
};

export function setCookie(name: string, value: string | number | boolean, options: CookieOptions = defaultOptions) {
  options = { ...defaultOptions, domain: window.location.hostname, ...options };
  const cookieParts = [`${name}=${value}`];
  if (typeof options.path !== 'undefined') {
    cookieParts.push(`path=${options.path}`);
  }
  if (typeof options.domain !== 'undefined') {
    cookieParts.push(`domain=${options.domain}`);
  }
  if (options.secure === true) {
    cookieParts.push(`secure`);
  }
  if (typeof options.sameSite !== 'undefined') {
    cookieParts.push(`SameSite=${options.sameSite}`);
  }
  if (typeof options.expires !== 'undefined') {
    cookieParts.push(`expires=${options.expires.toUTCString()}`);
  }
  document.cookie = cookieParts.join(';');
}

export function setLanguageCookie(language: string) {
  setCookie(CookieKeys.LANGUAGE, language, { sameSite: 'Lax' });
}

export function deleteCookie(name: string) {
  setCookie(name, '', { expires: new Date(0) });
}

export function getCookie(name: string): UnsafeAny {
  return parseCookies(document.cookie, name);
}

export function parseCookies(cookiesString: string): Record<string, string>;
export function parseCookies(cookiesString: string, name: string): string | undefined;
export function parseCookies(cookiesString: string, name?: string): string | Record<string, string> {
  const result: Record<string, string> = {};
  for (const cookie of cookiesString.split('; ')) {
    const equalCharIndex = cookie.indexOf('=');
    const cookieName = cookie.slice(0, equalCharIndex);
    const value = cookie.slice(equalCharIndex + 1);

    if (name && cookieName === name) return value;
    result[cookieName] = value;
  }
  if (name) return undefined;
  return result;
}
