import { PublicApiV3 } from '../services/Api';
import type { Datelike } from '../services/DateTime';

import type { Attribute } from './Enum';

export interface VehicleAttributes extends Record<string, Datelike | string | number | boolean> {
  consumption: number;
  description: string;
  engine_size: number;
  first_registration: Datelike;
  fuel: string;
  fuel_tank_capacity: number;
  power: number;
  raid: number;
  seats: number;
  spz: string;
  total_weight: number;
  transmission: string;
  type: string;
  instant_booking: boolean;
  price: number;
  custom_price?: number;
  license_plate: string;
  number_of_doors: number;
  trim_style: string;
  non_european: boolean;
  color: string;
  manufacturing_year: string | number;
  delivery_car: boolean;
  travel_abroad: boolean;
  smoking: boolean;
  pets: boolean;
  vignette: boolean;
  wheelchair_platform: boolean;
  hand_control: boolean;
  min_days: number;
  max_days: number;
  is_imported_from_na: boolean;
  is_first_registration_in_pl: boolean;
  number_of_owners: number;
  info_ekspert_vehicle_id: number;
  from_18age: boolean;
  own_insurance_in_registration: boolean;
  electric_battery_capacity: number;
  electric_charging_time: number;
  electric_range: number;
  bank_account: string;
  payment_reference: string;
  owner_speaks_english: boolean;
}

export type VehicleAttributesResponse = {
  value: string | boolean | number;
  trans_code: string;
  label: string;
}[];

const booleanValueKeys: (keyof VehicleAttributes)[] = [
  'instant_booking',
  'non_european',
  'delivery_car',
  'travel_abroad',
  'smoking',
  'pets',
  'vignette',
  'wheelchair_platform',
  'hand_control',
  'is_imported_from_na',
  'is_first_registration_in_pl',
  'from_18age',
  'own_insurance_in_registration',
  'owner_speaks_english',
];

export function parseVehicleAttributes(response: VehicleAttributesResponse) {
  const attributes: Partial<VehicleAttributes> = {};
  for (const attr of response) {
    if (booleanValueKeys.includes(attr.trans_code)) {
      attr.value = Boolean(attr.value === '1');
    }
    attributes[attr.trans_code] = attr.value;
  }
  return attributes;
}

export function findVehicleAttributesByEnum<T = string>(
  attributes: Partial<VehicleAttributes>,
  enums: Attribute<T>[],
  map?: (attribute: unknown) => T,
): Record<string, T> {
  const result: Record<string, T> = {};
  for (const item of enums) {
    let it = attributes[item.trans_code] as unknown;
    if (map) {
      it = map(it);
    }
    result[item.trans_code] = it as T;
  }
  return result;
}

export async function fetchVehicleAttributes(hash: string) {
  const response = await PublicApiV3.get<VehicleAttributesResponse>(`/vehicles/${hash}/attributes`);
  return parseVehicleAttributes(response.data);
}
