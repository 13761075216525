import React, { createContext, useContext, useDebugValue, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { UserState } from '../model/User';
import { AppState } from '../services/AppState';
import { type Country, defaultRegion } from '../services/Country';
import { defaultLanguage, type Language } from '../services/Language';
import type { ReduxState } from '../store';
import { isAppBlockedSelector } from '../store/selectors';

export interface RestrictionAttributes {
  region?: Country | Country[];
  language?: Language | Language[];
  appState?: AppState;
  minAppState?: AppState;
  appBlocked?: boolean;
  userState?: UserState | UserState[];
  force?: boolean;
  only?: boolean;
}

interface RestrictionProviderProps {
  region?: Country;
  language?: Language;
  appState?: AppState;
  appBlocked?: boolean;
  userState?: UserState;
}

class RestrictionContext {
  constructor(
    public readonly appState: AppState = AppState.INITIAL,
    public readonly appBlocked: boolean = false,
    public region: Country = defaultRegion,
    public language: Language = defaultLanguage,
    public userState: UserState = UserState.DRAFT,
  ) {}

  public setRegion(region: Country): void {
    this.region = region;
  }

  public matchesRegion(region: Country | Country[], force?: boolean): boolean {
    if (process.env.GATSBY_RESTRICT_REGION !== 'true' && !force) return false;
    if (Array.isArray(region)) return region.includes(this.region);
    return this.region === region;
  }

  public matchesLanguage(language: Language | Language[]): boolean {
    if (Array.isArray(language)) return language.includes(this.language);
    return this.language === language;
  }

  public matchesUserState(userState: UserState | UserState[]): boolean {
    if (Array.isArray(userState)) return userState.includes(this.userState);
    return this.userState === userState;
  }

  public isRestricted(props: RestrictionAttributes): boolean {
    if (props.only && !props.minAppState) {
      return (
        (props.region && !this.matchesRegion(props.region, props.force)) ||
        (props.language && !this.matchesLanguage(props.language)) ||
        (props.appState && this.appState !== props.appState) ||
        (typeof props.appBlocked === 'boolean' && this.appBlocked !== props.appBlocked) ||
        (props.userState && !this.matchesUserState(props.userState))
      );
    }
    return (
      (props.region && this.matchesRegion(props.region, props.force)) ||
      (props.language && this.matchesLanguage(props.language)) ||
      (props.appState && this.appState === props.appState) ||
      (props.minAppState && this.appState < props.minAppState) ||
      (typeof props.appBlocked === 'boolean' && this.appBlocked === props.appBlocked) ||
      (props.userState && this.matchesUserState(props.userState))
    );
  }

  public extend({ appState, appBlocked, region, language, userState }: RestrictionProviderProps): RestrictionContext {
    return new RestrictionContext(
      appState ?? this.appState,
      appBlocked ?? this.appBlocked,
      region ?? this.region,
      language ?? this.language,
      userState ?? this.userState,
    );
  }
}

const context = createContext<RestrictionContext>(new RestrictionContext());

export function RestrictionProvider(props: React.PropsWithChildren<RestrictionProviderProps>) {
  const ctx = useContext(context);
  const { appState, appBlocked, region, language, userState } = useSelector((state: ReduxState) => ({
    appState: state.app.state,
    appBlocked: isAppBlockedSelector(state),
    region: state.app.region,
    language: state.app.language,
    userState: state.user?.state,
  }));
  const [value, setValue] = useState(
    ctx.extend({
      appState,
      appBlocked,
      language,
      userState,
      ...props,
      region: props.region ?? region,
    }),
  );

  useEffect(() => {
    setValue(prev =>
      prev.extend({
        appState,
        appBlocked,
        language,
        ...props,
        region: props.region ?? region,
      }),
    );
  }, [props, appState, appBlocked, region, language, userState]);

  return <context.Provider value={value}>{props.children}</context.Provider>;
}

export function RestrictionConsumer(props: React.ConsumerProps<RestrictionContext>) {
  return <context.Consumer>{props.children}</context.Consumer>;
}

export function useRegion(local = false): [RestrictionContext['region'], RestrictionContext['setRegion']] {
  const ctx = useContext(context);
  const [region, setRegion] = useState(ctx.region);

  const onChange = region => {
    if (!local) ctx.setRegion(region);
    else setRegion(region);
  };

  return [local ? region : ctx.region, onChange];
}

export function useRestriction(restrictBy: RestrictionAttributes): boolean {
  const ctx = useContext(context);
  const isRestricted = ctx.isRestricted(restrictBy);

  useDebugValue(
    isRestricted,
    value =>
      `${value ? 'Access denied' : 'Access allowed'} (${Object.entries(restrictBy)
        .map(([k, v]) => `${k}: ${v}`)
        .join(', ')})`,
  );

  return isRestricted;
}

interface RestrictionProps extends RestrictionAttributes {
  children: React.ReactNode;
  fallback?: React.ReactNode;
}

export function Restriction({ children, fallback, ...props }: RestrictionProps) {
  const isRestricted = useRestriction(props);

  if (isRestricted) {
    return <>{fallback}</>;
  }

  return <>{children}</>;
}

export interface RegionSwitchChildrenProps {
  region?: Country | Country[];
  force?: boolean;
}

export function RegionSwitch({ children }) {
  const ctx = useContext(context);
  let element;
  React.Children.forEach(children, (child, index) => {
    if (!element && React.isValidElement(child)) {
      const { region, force } = child.props as RegionSwitchChildrenProps;
      if ((region && ctx.matchesRegion(region, force)) || (!region && index === children.length - 1)) {
        element = child;
      }
    }
  });

  if (!element) {
    return null;
  }
  return React.cloneElement(element);
}
