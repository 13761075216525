import { defaultLanguage, Language } from './Language';

export enum Country {
  CZ = 'CZ',
  SK = 'SK',
}

const LanguageMapping = {
  [Language.CS]: {
    country: Country.CZ,
  },
  [Language.PL]: {
    country: Country.CZ,
  },
  [Language.SK]: {
    country: Country.SK,
  },
  [Language.EN]: {
    country: Country.CZ,
  },
};

export const defaultRegion = (process.env.GATSBY_DEFAULT_REGION as Country) ?? Country.CZ;
export const supportedCountries =
  (process.env.GATSBY_SUPPORTED_COUNTRIES?.split(',') as Country[]) ?? Object.values(Country);

export const languageToCountry = (lang: string): Country => {
  return LanguageMapping[lang]?.country ?? LanguageMapping[defaultLanguage].country;
};

export function countryToRegion(country: Country | string): Country {
  switch (country.toUpperCase()) {
    case Country.CZ:
      return Country.CZ;
    case Country.SK:
      return Country.SK;
    default:
      return defaultRegion;
  }
}

export function countryToFilter(country: Country): string {
  return country.toLowerCase();
}
