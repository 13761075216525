export function updateCache<V>(region: string, lang: string, value: V, CACHE_STORAGE_KEY: string) {
  let storageValue = {};
  const key = `${region}-${lang}`;
  const cache = window.localStorage.getItem(CACHE_STORAGE_KEY);
  if (cache) {
    storageValue = JSON.parse(cache);
  }
  if (!storageValue[key]) {
    storageValue[key] = {};
  }
  storageValue[key] = { ...storageValue[key], ...value };
  window.localStorage.setItem(CACHE_STORAGE_KEY, JSON.stringify(storageValue));
}

export function getCachedValue<V>(region: string, lang: string, CACHE_STORAGE_KEY: string): V {
  const cache = window.localStorage.getItem(CACHE_STORAGE_KEY);
  if (cache) {
    const multilangValue = JSON.parse(cache) as { [lang: string]: V };
    return multilangValue[`${region}-${lang}`];
  }
  return null;
}

abstract class BaseStorage<T> {
  constructor(protected storageKey: string, public value: T, private storage: Storage) {}

  public load(): void {
    const value = this.storage.getItem(this.storageKey);
    if (!value) return;
    this.value = JSON.parse(value) as T;
  }

  public save(): void {
    if (this.value === undefined || this.value === null) return;
    this.storage.setItem(this.storageKey, JSON.stringify(this.value));
  }

  public remove(): void {
    this.storage.removeItem(this.storageKey);
  }
}

export class LocalStorage<T> extends BaseStorage<T> {
  constructor(storageKey: string, value?: T) {
    super(storageKey, value ?? null, window.localStorage);
  }
}

export class SessionStorage<T> extends BaseStorage<T> {
  constructor(storageKey: string, value?: T) {
    super(storageKey, value ?? null, window.sessionStorage);
  }
}
