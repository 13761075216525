import { combineReducers } from 'redux';

import * as sumsubUserRegistration from '../modules/UserRegistration/SumsubUserRegistration';

import app from './app';
import carDetail from './carDetail';
import carRegistration from './carRegistration';
import myCars from './cars';
import enums from './enums';
import favoriteVehicles from './favoriteVehicles';
import messages from './messages';
import myProfile from './myProfile';
import order from './order';
import paymentMethods from './paymentMethods';
import publicProfile from './publicProfile';
import rentCar from './rentCar';
import session from './session';
import tripDetail from './tripDetail';
import user from './user';

const reducers = combineReducers({
  user,
  app,
  session,
  enums,
  carDetail,
  carRegistration,
  favoriteVehicles,
  rentCar,
  myCars,
  messages,
  order,
  publicProfile,
  tripDetail,
  myProfile,
  paymentMethods,
  userRegistration: sumsubUserRegistration.reducer,
});

export default reducers;
