import { fetchUser, type User } from '../model/User';
import {
  authorizeUserWithApple,
  authorizeUserWithEmail,
  authorizeUserWithFacebook,
  authorizeUserWithGoogle,
  logoutUser,
} from '../model/UserAuthorization';
import { UserRegistrationActionType } from '../modules/UserRegistration/SumsubUserRegistration';
import type { ApiResponse } from '../services/Api';
import { UserGuard } from '../services/ApiGuard';
import auth, { type Authorization } from '../services/Authorization';
import { CookieKeys, deleteCookie, getCookie, setCookie } from '../services/Cookies';
import { firebaseDestroy } from '../services/Firebase';
import { SentryLogAdapter } from '../services/Sentry';
import Tracking from '../services/Tracking';

import type { Action, ReduxState } from '.';
import { AppActionType, reloadHomepageFeed } from './app';
import { loadMyVehicles } from './cars';
import { initFilters } from './rentCar';

export enum SessionActionType {
  AUTHORIZE = 'session/authorize',
  CLEAR_SESSION = 'session/clear',
  OPEN_SIGN_IN_MODAL = 'session/openSignInModal',
  CLEAR_CALLBACK = 'session/clearCallback',
  FLASH_MESSAGE_SUCCESS = 'flash/success',
  FLASH_MESSAGE_ERROR = 'flash/error',
  FLASH_MESSAGE_INFO = 'flash/info',
  HIDE_FLASH_MESSAGE = 'flash/hide',
}

export interface SessionStore {
  authorized: boolean;
  hasConsents: boolean;
  onAuthorize?: () => void;
  flashMessage: {
    show: boolean;
    message: string;
    className: string;
    error: boolean;
  };
}

const initialState: SessionStore = {
  authorized: false,
  hasConsents: false,
  onAuthorize: undefined,
  flashMessage: {
    show: false,
    message: '',
    className: null,
    error: false,
  },
};

const FLASH_SUCCESS_CLASSNAME = 'bg-success';
const FLASH_SUCCESS_DEFAULT_MESSAGE = 'flash.success';
const FLASH_ERROR_CLASSNAME = 'bg-danger';
const FLASH_ERROR_DEFAULT_MESSAGE = 'flash.error';
const FLASH_INFO_CLASSNAME = 'bg-primary';

export const flashMessageSuccess = (payload?: string) => ({
  type: SessionActionType.FLASH_MESSAGE_SUCCESS,
  payload,
});
export const flashMessageError = (payload?: string) => ({
  type: SessionActionType.FLASH_MESSAGE_ERROR,
  payload,
});
export const flashMessageInfo = (payload: string) => ({
  type: SessionActionType.FLASH_MESSAGE_INFO,
  payload,
});
export const hideFlashMessage = () => ({
  type: SessionActionType.HIDE_FLASH_MESSAGE,
});

function setUserSession(
  dispatch: DispatchFunction,
  getState: GetStateFunction<ReduxState>,
  user: User,
  hasConsents: boolean,
) {
  dispatch({
    type: SessionActionType.AUTHORIZE,
    payload: { user, hasConsents },
  });
  setCookie(CookieKeys.USER, user.hash);
  setCookie(CookieKeys.CONSENTS, hasConsents ? 'true' : 'false');
  if (hasConsents) {
    const state = getState();
    state.session.onAuthorize?.();
    dispatch({ type: SessionActionType.CLEAR_CALLBACK });
    dispatch(loadMyVehicles());
    const region = user.country.alpha2_code;
    if (region !== state.app.region) {
      dispatch(reloadHomepageFeed(region));
      dispatch(initFilters(state.app.language, region));
    }
  }
}

function catchMissingCountry(
  action: () => Promise<ApiResponse<Authorization>>,
): ThunkAction<ReduxState, Promise<boolean>> {
  return async (dispatch, getState) => {
    const response = await action();
    let hasConsents = true;
    auth.setAuth(response.data);
    SentryLogAdapter.setUser(response.data.user);
    if (response.hasMessage(UserGuard.MISSING_USER_COUNTRY, UserGuard.MISSING_USER_CONSENTS)) {
      hasConsents = false;
      auth.disabled = true;
      SentryLogAdapter.setContext('appstate', { state: 'blocked' });
      Tracking.signup(response.data.user);
    } else {
      auth.disabled = false;
      SentryLogAdapter.setContext('appstate', { state: 'unblocked' });
      Tracking.login(response.data.user);
    }
    setUserSession(dispatch, getState, response.data.user, hasConsents);
    return hasConsents;
  };
}

export function loadSession(): ThunkAction<ReduxState> {
  return async (dispatch, getState) => {
    const response = await fetchUser();
    let hasConsents = true;
    SentryLogAdapter.setUser(response.data);
    if (response.hasMessage(UserGuard.MISSING_USER_COUNTRY, UserGuard.MISSING_USER_CONSENTS)) {
      hasConsents = false;
      auth.disabled = true;
      SentryLogAdapter.setContext('appstate', { state: 'blocked' });
    } else {
      auth.disabled = false;
      SentryLogAdapter.setContext('appstate', { state: 'unblocked' });
    }
    Tracking.login(response.data);
    setUserSession(dispatch, getState, response.data, hasConsents);
    return hasConsents;
  };
}

export function loginUserWithEmail(email: string, password: string) {
  return catchMissingCountry(() => authorizeUserWithEmail(email, password));
}

export function loginUserWithFacebook(accessToken: string) {
  return catchMissingCountry(() => authorizeUserWithFacebook(accessToken));
}

export function loginUserWithGoogle(accessToken: string) {
  return catchMissingCountry(() => authorizeUserWithGoogle(accessToken));
}

export function loginUserWithApple(accessToken: string) {
  return catchMissingCountry(() => authorizeUserWithApple(accessToken));
}

export function signOut(): ThunkAction<ReduxState> {
  return async dispatch => {
    dispatch({ type: SessionActionType.CLEAR_SESSION });
    deleteCookie(CookieKeys.USER);
    deleteCookie(CookieKeys.CONSENTS);
    try {
      await Promise.all([logoutUser(), firebaseDestroy()]);
    } catch (err) {} // eslint-disable-line no-empty
    auth.destroy();
    SentryLogAdapter.clearUser();
    Tracking.logout();
  };
}

export function afterSignIn(callback: () => void): ThunkAction<ReduxState> {
  return (dispatch, getState) => {
    const isSignedIn = getState().session.authorized;
    if (isSignedIn) {
      callback();
      return;
    }
    dispatch({ type: SessionActionType.OPEN_SIGN_IN_MODAL, payload: callback });
  };
}

type SessionAction =
  | Action<AppActionType.INIT>
  | Action<UserRegistrationActionType.EXIT, { shouldDestroyUser: boolean }>
  | Action<UserRegistrationActionType.COMPLETE_COUNTRY>
  | Action<SessionActionType.AUTHORIZE, { hasConsents: boolean; user: User }>
  | Action<SessionActionType.CLEAR_SESSION>
  | Action<SessionActionType.OPEN_SIGN_IN_MODAL, () => void>
  | Action<SessionActionType.CLEAR_CALLBACK>
  | Action<SessionActionType.FLASH_MESSAGE_ERROR, string | null>
  | Action<SessionActionType.FLASH_MESSAGE_SUCCESS, string | null>
  | Action<SessionActionType.FLASH_MESSAGE_INFO, string>
  | Action<SessionActionType.HIDE_FLASH_MESSAGE>;

export default function reducer(state = initialState, action: SessionAction): SessionStore {
  if (action.type === AppActionType.INIT) {
    if (!auth.hasToken()) return state;
    return {
      ...state,
      authorized: Boolean(getCookie(CookieKeys.USER)),
      hasConsents: Boolean(getCookie(CookieKeys.CONSENTS)),
    };
  }

  if (action.type === UserRegistrationActionType.EXIT && action.payload.shouldDestroyUser) {
    return {
      ...state,
      authorized: false,
      hasConsents: false,
    };
  }

  if (action.type === UserRegistrationActionType.COMPLETE_COUNTRY) {
    return {
      ...state,
      hasConsents: true,
    };
  }

  if (action.type === SessionActionType.AUTHORIZE) {
    return {
      ...state,
      authorized: true,
      hasConsents: action.payload.hasConsents,
    };
  }
  if (action.type === SessionActionType.CLEAR_SESSION) {
    return {
      ...state,
      authorized: false,
      hasConsents: false,
      onAuthorize: undefined,
    };
  }
  if (action.type === SessionActionType.OPEN_SIGN_IN_MODAL) {
    return {
      ...state,
      onAuthorize: action.payload,
    };
  }
  if (action.type === SessionActionType.CLEAR_CALLBACK) {
    return {
      ...state,
      onAuthorize: undefined,
    };
  }
  if (action.type === SessionActionType.FLASH_MESSAGE_SUCCESS) {
    return {
      ...state,
      flashMessage: {
        show: true,
        message: action.payload || FLASH_SUCCESS_DEFAULT_MESSAGE,
        className: FLASH_SUCCESS_CLASSNAME,
        error: false,
      },
    };
  }
  if (action.type === SessionActionType.FLASH_MESSAGE_ERROR) {
    return {
      ...state,
      flashMessage: {
        show: true,
        message: action.payload || FLASH_ERROR_DEFAULT_MESSAGE,
        className: FLASH_ERROR_CLASSNAME,
        error: true,
      },
    };
  }
  if (action.type === SessionActionType.FLASH_MESSAGE_INFO) {
    return {
      ...state,
      flashMessage: {
        show: true,
        message: action.payload,
        className: FLASH_INFO_CLASSNAME,
        error: false,
      },
    };
  }
  if (action.type === SessionActionType.HIDE_FLASH_MESSAGE) {
    return {
      ...state,
      flashMessage: {
        ...state.flashMessage,
        show: false,
      },
    };
  }

  return state;
}
