import { PrivateApiV3 } from '../services/Api';

import { updateVehicleAttributes } from './Vehicle';

export interface BankAccount {
  bank_account: string;
  payment_reference?: string;
}

export interface UserBankAccounts {
  bank_accounts: string[];
  payment_variables?: string[];
}

export async function fetchUserBankAccounts(): Promise<UserBankAccounts> {
  try {
    const response = await PrivateApiV3.get<UserBankAccounts>('/user/bank_accounts');
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export async function updateVehicleBankAccount(vehicleHash: string, bank_account: BankAccount) {
  return await updateVehicleAttributes(vehicleHash, {
    bank_account: bank_account.bank_account,
    payment_reference: bank_account.payment_reference !== '' ? bank_account.payment_reference : null,
  });
}
