export type Page<T> = {
  page: number;
  total_items: number;
  has_next: boolean;
  items: T[];
};

type PagingLink = { href: string };
export type PagingResponse<T> = {
  page: number;
  pages: number;
  total: number;
  limit: number;
  _links: {
    self: PagingLink;
    first: PagingLink;
    last: PagingLink;
    next?: PagingLink;
  };
  _embedded: {
    items: T[];
  };
};

export const getEmptyPage = <T>(): Page<T> => ({
  page: 0,
  total_items: 0,
  has_next: false,
  items: [],
});

export function isPagingResponse<V>(response: PagingResponse<V> | UnsafeAny): response is PagingResponse<V> {
  return (
    typeof response === 'object' &&
    typeof response.page === 'number' &&
    typeof response._links !== 'undefined' &&
    typeof response._embedded !== 'undefined'
  );
}

export const parsePage = <T>(response: PagingResponse<T>): Page<T> => ({
  page: response.page,
  total_items: !response._embedded.items.length ? 0 : response.total, // TODO: hack
  has_next: response.pages > response.page && response._links.next !== undefined,
  items: response._embedded.items,
});
