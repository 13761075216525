import type { Country } from '../services/Country';

import type { Manufacturer, VehicleEquipment } from './Enum';
import {
  countryToCurrency,
  countryToPricePerDayStep,
  countryToPricePerKmStep,
  type CurrencyCode,
  roundPriceAmount,
} from './Price';
import {
  MILEAGE_PRICE_OVER_LIMIT_MAX,
  MILEAGE_PRICE_OVER_LIMIT_MIN,
  type ORDER_BY,
  type ORDER_TYPE,
  PRICE_PER_DAY_MAX,
  PRICE_PER_DAY_MIN,
} from './SearchFilters';

export interface FiltrationParameters {
  currency: CurrencyCode;
  manufacturers: Manufacturer[];
  equipment: VehicleEquipment[];
  tags: string[];

  average_price_per_day_extreme_max: number;
  average_price_per_day_extreme_min: number;
  average_price_per_day_extreme_step?: number;
  price_per_day_step?: number;

  mileage_price_over_limit_extreme_max: number;
  mileage_price_over_limit_extreme_min: number;
  mileage_price_over_limit_extreme_step?: number;
  price_per_extra_km_over_limit_step?: number;

  raid_extreme_max: number;
  raid_extreme_min: number;
  raid_extreme_step?: number;

  registration_date_extreme_max: number;
  registration_date_extreme_min: number;
  registration_date_extreme_step?: number;

  order_by?: ORDER_BY;
  order_type?: ORDER_TYPE;
}

export function getPricePerDayMin(region: Country, params: Partial<FiltrationParameters>): number {
  return roundPriceAmount(params?.average_price_per_day_extreme_min ?? PRICE_PER_DAY_MIN, countryToCurrency(region), 0);
}

export function getPricePerDayMax(region: Country, params: Partial<FiltrationParameters>): number {
  return roundPriceAmount(params?.average_price_per_day_extreme_max ?? PRICE_PER_DAY_MAX, countryToCurrency(region), 0);
}

export function getPricePerDayStep(region: Country, params: Partial<FiltrationParameters>): number {
  if (typeof params.price_per_day_step !== 'number') return countryToPricePerDayStep(region);
  return roundPriceAmount(params.price_per_day_step, countryToCurrency(region), 0);
}

export function getPricePerKmMin(region: Country, params: Partial<FiltrationParameters>): number {
  return roundPriceAmount(
    params?.mileage_price_over_limit_extreme_min ?? MILEAGE_PRICE_OVER_LIMIT_MIN,
    countryToCurrency(region),
  );
}

export function getPricePerKmMax(region: Country, params: Partial<FiltrationParameters>): number {
  return roundPriceAmount(
    params?.mileage_price_over_limit_extreme_max ?? MILEAGE_PRICE_OVER_LIMIT_MAX,
    countryToCurrency(region),
  );
}

export function getPricePerKmStep(region: Country, params: Partial<FiltrationParameters>): number {
  if (typeof params.price_per_extra_km_over_limit_step !== 'number') return countryToPricePerKmStep(region);
  return roundPriceAmount(params.price_per_extra_km_over_limit_step, countryToCurrency(region));
}
