import { PrivateApiV3, PublicApiV3 } from '../services/Api';
import auth from '../services/Authorization';
import { Country } from '../services/Country';

export type Consents = Record<string, boolean>;

export interface ConsentsSection {
  code?: string;
  title?: string;
  note?: string;
  consents?: {
    code: string;
    title: string;
    description?: string;
    required: boolean;
    checked?: boolean;
  }[];
}

export interface ConsentsDefinition {
  initialValues: Record<string, boolean>;
  schema: Record<string, boolean>;
  sections: ConsentsSection[];
  sectionsWithoutCheckbox?: ConsentsSection[];
}

type ConsentsConfigResponse = {
  code: Country;
  categories: ConsentsSection[];
}[];

const sectionCodesWithoutCheckbox: Partial<Record<Country, string[]>> = {
  [Country.CZ]: ['general'],
  [Country.SK]: ['general'],
};

export async function fetchConsents(country?: Country) {
  const result: Record<Country, Partial<ConsentsDefinition>> = {
    [Country.CZ]: {},
    [Country.SK]: {},
  };
  const response = await PublicApiV3.get<ConsentsConfigResponse>('/configs/consents', { country });

  for (const it of response.data) {
    const initialValues: Record<string, boolean> = {};
    const schema: Record<string, boolean> = {};
    const sections: ConsentsSection[] = [];
    const sectionsWithoutCheckbox: ConsentsSection[] = [];

    for (const category of it.categories) {
      let shouldHideCheckbox = false;
      for (const consent of category.consents) {
        const codesWithoutCheckbox = sectionCodesWithoutCheckbox[it.code] ?? [];
        if (
          (category.code && codesWithoutCheckbox.includes(category.code)) ||
          codesWithoutCheckbox.includes(consent.code)
        ) {
          shouldHideCheckbox = true;
          consent.checked = true;
        }

        initialValues[consent.code] = consent.checked || false;
        schema[consent.code] = consent.required;
      }
      if (shouldHideCheckbox) {
        sectionsWithoutCheckbox.push(category);
      } else {
        sections.push(category);
      }
    }

    result[it.code] = {
      initialValues,
      schema,
      sections,
      sectionsWithoutCheckbox,
    };
  }
  return result as Record<Country, ConsentsDefinition>;
}

export async function updateUserConsents(consents: Consents) {
  auth.disabled = false;

  return await PrivateApiV3.patch('/user/consents', {
    consents: Object.entries(consents).map(([code, value]) => ({
      code,
      value,
    })),
  });
}
