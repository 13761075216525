export enum UserGuard {
  PHONE_IS_NOT_VERIFIED = 'phone_is_not_verified',
  USER_AVATAR_IS_NOT_UPLOADED = 'user_avatar_is_not_uploaded',
  ABOUT_ME_IS_TOO_SHORT = 'about_me_is_too_short',
  BIRTH_NUMBER_NOT_VALID_AGAINST_BIRTHDAY = 'birth_number_not_valid_against_birthday',
  USERNAME_ALREADY_USED = 'username_already_used',
  EMAIL_ALREADY_USED = 'email_already_used',
  EMAIL_IS_NOT_VERIFIED = 'email_is_not_verified',
  USER_BIRTHDAY_IS_UNDEFINED = 'user_birthday_is_undefined',
  USER_IS_TOO_YOUNG = 'user_is_too_young',
  USER_LOCATION_IS_NOT_VALID = 'user_location_is_not_valid',
  MISSING_USER_COUNTRY = 'missing_user_country',
  MISSING_USER_CONSENTS = 'missing_user_consents',
}

export enum CardGuard {
  CARD_NUMBER_MISSING = 'card_number_missing',
  CARD_EXPIRATION_DATE_MISSING_OR_EXPIRED = 'card_expiration_date_missing_or_expired',
  PHOTO_IS_NOT_UPLOADED = 'photo_is_not_uploaded',
}

export enum TripGuard {
  ABORT_MESSAGE_LENGTH_NOT_SUFFICIENT = 'abort_message_length_not_sufficient',
  HAND_IN_PROCESS_NOT_FINISHED = 'hand_in_process_not_finished',
  HAND_OUT_PROCESS_NOT_FINISHED = 'hand_out_process_not_finished',
  HAND_IN_NOT_IN_HAND_IN_WINDOW = 'hand_in_not_in_hand_in_window',
  TRIP_HAS_UNRESOLVED_PROBLEMS = 'trip_has_unresolved_problems',
  NOT_ENOUGH_FINISHED_TRIPS = 'not_enough_finished_trips',
  TRIP_START_NOT_BETWEEN_INTERVAL = 'trip_start_not_between_interval',
  TRIP_END_NOT_BETWEEN_INTERVAL = 'trip_end_not_between_interval',
  INSUFFICIENT_AVERAGE_RATING = 'insufficient_average_rating',
  RESERVATION_REQUEST_BEFORE_TRIP_START_LESS_THAN = 'reservation_request_before_trip_start_less_than',
  USER_HAS_NO_VALID_PAYMENT_METHOD = 'user_has_no_valid_payment_method',
  TRIP_IS_NOT_FULLY_PAID = 'trip_is_not_fully_paid',
  VEHICLE_IS_NOT_ACTIVE = 'vehicle_is_not_active',
  TRIP_DATE_FROM_NOT_IN_FUTURE = 'trip_date_from_not_in_future',
  TRIP_DATE_FROM_GREATER_THAN_TRIP_DATE_TO = 'trip_date_from_greater_than_trip_date_to',
  DRIVER_IS_NOT_ACTIVE = 'driver_is_not_active',
  DRIVER_HAS_ALREADY_SIMILAR_REQUEST = 'driver_has_already_similar_request',
  CANNOT_RENT_OWN_VEHICLE = 'cannot_rent_own_vehicle',
  VEHICLE_HAS_ACTIVE_TRIPS_IN_SELECTED_INTERVAL = 'vehicle_has_active_trips_in_selected_interval',
  DRIVER_HAS_ACTIVE_TRIPS_IN_SELECTED_INTERVAL = 'driver_has_active_trips_in_selected_interval',
  DRIVER_DOES_NOT_HAVE_ACTIVE_DRIVING_CARD = 'driver_does_not_have_active_driving_card',
  DRIVER_DOES_NOT_HAVE_ACTIVE_IDENTIFICATION_CARD = 'driver_does_not_have_active_identification_card',
  VALUE_IS_NOT_CONFIRMED = 'value_is_not_confirmed',
  USER_VERIFICATION_NUMBER_NOT_VERIFIED = 'user_verification_number_not_verified',
  MILEAGE_IS_LESS_THAN_LAST_KNOWN_MILEAGE = 'mileage_is_less_than_last_known_mileage',
  PHOTO_IS_NOT_UPLOADED = 'photo_is_not_uploaded',
}

export enum VehicleGuard {
  PHOTO_IS_NOT_UPLOADED = 'photo_is_not_uploaded',
  VEHICLE_HAS_ACTIVE_TRIPS = 'vehicle_has_active_trips',
  VEHICLE_WAS_NOT_VERIFIED_BY_EXTERNAL_SERVICE = 'vehicle_was_not_verified_by_external_service',
  PARKING_LOCATION_MISSING = 'parking_location_missing',
  MISSING_BANK_ACCOUNT = 'missing_bank_account',
  MISSING_ATTRIBUTE = 'missing_attribute',
  VEHICLE_MODEL_IS_NOT_SET = 'vehicle_model_is_not_set',
  VEHICLE_PRICING_IS_NOT_SET = 'vehicle_pricing_is_not_set',
  MISSING_OR_EMPTY_ATTRIBUTE = 'missing_or_empty_attribute',
  VEHICLE_VIN_CODE_ALREADY_EXISTS = 'vehicle_vin_code_already_exists',
  WRONG_VEHICLE_COUNTRY = 'wrong_vehicle_country',
}
