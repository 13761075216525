import { addDays, startOfDay } from 'date-fns';
import { navigate } from 'gatsby';
import merge from 'lodash/merge';

import { ModalEnum, type ModalOptions } from '../ModalEnum';
import { fetchHomepageFeed, type HomepageFeed } from '../model/HomepageFeed';
import type { User } from '../model/User';
import { UserRegistrationActionType, UserRegistrationSteps } from '../modules/UserRegistration/SumsubUserRegistration';
import RouteEnum from '../RouteEnum';
import { Api } from '../services/Api';
import { AppState } from '../services/AppState';
import auth from '../services/Authorization';
import { CookieKeys, deleteCookie, getCookie, setCookie, setLanguageCookie } from '../services/Cookies';
import { type Country, countryToRegion, defaultRegion } from '../services/Country';
import { changeDateTimeLocale } from '../services/DateTime';
import { defaultLanguage, type Language } from '../services/Language';
import { Logger } from '../services/Logger';
import { Migration } from '../services/Migration';
import OneTrust from '../services/OneTrust';
import { SentryLogAdapter } from '../services/Sentry';
import { getCachedValue } from '../services/Storage';
import Tracking from '../services/Tracking';

import type { Action, ReduxState } from './';
import { loadEnum, reloadEnums } from './enums';
import { initFilters } from './rentCar';
import { isAppBlockedSelector } from './selectors';
import { loadSession, SessionActionType } from './session';

export enum AppActionType {
  INIT = 'app/init',
  SET_STATE = 'app/setState',
  BLOCK_APP = 'app/block',
  UNBLOCK_APP = 'app/unblock',
  SET_LANGUAGE = 'app/onLanguageChange',
  OPEN_MODAL = 'app/openModal',
  CLOSE_MODAL = 'app/closeModal',
  SET_CONFIGS = 'app/setConfigs',
  FEED_LOADING = 'feed/loading',
  FEED_SET = 'feed/set',
  SET_SHOW_TOP_BANNER = 'app/setShowTopBanner',
  SET_ENUMS = 'app/setEnums',
}

export interface AppStore {
  language: Language;
  region: Country;
  state: AppState;
  campaign?: string;
  blocked: boolean;
  showTopBanner: boolean;
  topBannerClosedUntilTime: number;
  modal: ModalEnum;
  modalOptions?: ModalOptions;
  feed: {
    filter?: string;
    data: HomepageFeed;
    loading: boolean;
    error: string;
  };
}

const BANNER_STORAGE_KEY = 'hoppygo_banner';
const CACHE_STORAGE_KEY = 'hoppygo_app';
export const REGION_CONFIG_KEY = 'hoppygo_region';

const initialState: AppStore = {
  language: defaultLanguage,
  region: defaultRegion,
  state: AppState.INITIAL,
  blocked: false,
  showTopBanner: false,
  topBannerClosedUntilTime: null,
  modal: null,
  modalOptions: null,
  feed: {
    data: null,
    loading: true,
    error: null,
  },
};

type AppAction =
  | Action<AppActionType.INIT, DeepPartial<AppStore>>
  | Action<AppActionType.SET_STATE, AppState>
  | Action<SessionActionType.AUTHORIZE, { user: User }>
  | Action<SessionActionType.CLEAR_SESSION>
  | Action<SessionActionType.OPEN_SIGN_IN_MODAL>
  | Action<UserRegistrationActionType.COMPLETE_COUNTRY, Country>
  | Action<
      AppActionType.SET_LANGUAGE,
      {
        language: Language;
        region: Country;
        feed?: { data: HomepageFeed; loading: boolean };
      }
    >
  | Action<AppActionType.SET_SHOW_TOP_BANNER, boolean>
  | Action<AppActionType.OPEN_MODAL, { modal: ModalEnum; options: ModalOptions }>
  | Action<AppActionType.FEED_LOADING>
  | Action<AppActionType.FEED_SET, HomepageFeed>
  | Action<AppActionType.CLOSE_MODAL>;

export default function app(state = initialState, action: AppAction): AppStore {
  if (action.type === AppActionType.INIT) {
    return merge({}, state, action.payload);
  }

  if (action.type === AppActionType.SET_STATE) {
    return { ...state, state: action.payload };
  }

  if (action.type === SessionActionType.AUTHORIZE) {
    return {
      ...state,
      region: countryToRegion(action.payload.user.country?.alpha2_code ?? state.region),
    };
  }

  if (action.type === SessionActionType.CLEAR_SESSION) {
    return { ...state, region: defaultRegion };
  }

  if (action.type === UserRegistrationActionType.COMPLETE_COUNTRY) {
    return { ...state, region: action.payload };
  }

  if (action.type === AppActionType.SET_LANGUAGE) {
    return {
      ...state,
      language: action.payload.language,
      region: countryToRegion(action.payload.region),
      feed: {
        ...state.feed,
        data: action.payload.feed?.data ?? state.feed.data,
        loading: action.payload.feed?.loading ?? state.feed.loading,
      },
    };
  }

  if (action.type === AppActionType.SET_SHOW_TOP_BANNER) {
    let topBannerClosedUntilTime = null;
    if (!action.payload) {
      topBannerClosedUntilTime = startOfDay(addDays(new Date(), 1)).getTime();
      window.localStorage.setItem(BANNER_STORAGE_KEY, topBannerClosedUntilTime);
    }
    return {
      ...state,
      topBannerClosedUntilTime,
      showTopBanner: action.payload,
    };
  }

  if (action.type === AppActionType.OPEN_MODAL) {
    return {
      ...state,
      modal: action.payload.modal,
      modalOptions: action.payload.options,
    };
  }

  if (action.type === SessionActionType.OPEN_SIGN_IN_MODAL) {
    return {
      ...state,
      modal: ModalEnum.SIGN_IN,
      modalOptions: null,
    };
  }

  if (action.type === AppActionType.CLOSE_MODAL) {
    return { ...state, modal: null, modalOptions: null };
  }

  if (action.type === AppActionType.FEED_LOADING) {
    return {
      ...state,
      feed: {
        filter: state.feed.filter,
        loading: true,
        data: state.feed.data,
        error: null,
      },
    };
  }

  if (action.type === AppActionType.FEED_SET) {
    return {
      ...state,
      feed: {
        filter: state.feed.filter,
        loading: false,
        data: action.payload,
        error: null,
      },
    };
  }

  return state;
}

export function init(
  lang: string,
  region: Country,
  campaign?: string,
  vehicleFeedFilter?: string,
): ThunkAction<ReduxState> {
  return async dispatch => {
    Migration.run();

    Api.language = lang;
    changeDateTimeLocale(lang);

    dispatch(loadEnum(null, 'country', 'regions'));

    const regionCookie = getCookie(CookieKeys.REGION);
    if (regionCookie) {
      region = regionCookie as Country;
    }

    const initial: DeepPartial<AppStore> = getCachedValue(region, lang, CACHE_STORAGE_KEY) ?? {};
    initial.state = AppState.LOADING;
    initial.language = lang as Language;
    initial.region = region;
    initial.campaign = campaign;
    if (!initial.feed) {
      initial.feed = { ...initialState.feed };
    }
    initial.feed.filter = vehicleFeedFilter;

    const savedTime = window.localStorage.getItem(BANNER_STORAGE_KEY);
    if (savedTime) {
      try {
        initial.topBannerClosedUntilTime = Number.parseInt(savedTime, 10);
      } catch (_) {} // eslint-disable-line no-empty
    }
    SentryLogAdapter.setLanguage(lang);
    SentryLogAdapter.setRegion(region);
    Tracking.trackAppInit(lang, region);

    auth.loadAuth();
    if (auth.hasToken()) {
      auth
        .refresh()
        .then(() => dispatch(loadSession()))
        .then(hasConsents => {
          if (!hasConsents) {
            dispatch({
              type: AppActionType.SET_STATE,
              payload: AppState.LOADED,
            });
            navigate(RouteEnum.USER_REGISTRATION);
          }
        });
    } else {
      deleteCookie(CookieKeys.USER);
      deleteCookie(CookieKeys.CONSENTS);
    }

    dispatch({ type: AppActionType.INIT, payload: initial });

    fetchHomepageFeed(region, vehicleFeedFilter).then(feed => {
      dispatch({ type: AppActionType.FEED_SET, payload: feed });
    });
    await dispatch(initFilters(lang, region));
    dispatch({ type: AppActionType.SET_STATE, payload: AppState.LOADED });
  };
}

export function setLanguage(language: string, region: Country): ThunkAction<ReduxState> {
  return async (dispatch, getState) => {
    Api.language = language;
    changeDateTimeLocale(language);

    Logger.default.debug('language change', { lang: language, region });
    SentryLogAdapter.setLanguage(language);
    SentryLogAdapter.setRegion(region);
    OneTrust.changeLanguage(language);
    Tracking.track('LANGUAGE_CHANGE', language, region);
    setLanguageCookie(language);
    setCookie(CookieKeys.REGION, region);

    const state = getState();
    const isBlocked = isAppBlockedSelector(state);
    const appState = getState().app;
    try {
      if (language !== appState.language) {
        dispatch(reloadEnums());
      }

      if (isBlocked) {
        if (state.userRegistration.step === UserRegistrationSteps.COUNTRY) {
          region = state.app.region;
        }
        return dispatch({
          type: AppActionType.SET_LANGUAGE,
          payload: { language, region },
        });
      }

      if (region !== appState.region) {
        dispatch(reloadHomepageFeed(region));
        dispatch(initFilters(language, region));
      }
      dispatch({
        type: AppActionType.SET_LANGUAGE,
        payload: { language, region },
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function reloadHomepageFeed(region?: Country): ThunkAction<ReduxState> {
  return async (dispatch, getState) => {
    const state = getState();
    const currentRegion = state.app.region;
    if (!region || region === currentRegion) return;
    const filter = state.app.feed.filter;
    dispatch({ type: AppActionType.FEED_LOADING });
    const feed = await fetchHomepageFeed(region, filter);
    dispatch({ type: AppActionType.FEED_SET, payload: feed });
  };
}

export const setShowTopBanner = (payload: boolean): ActionReturnType => ({
  type: AppActionType.SET_SHOW_TOP_BANNER,
  payload,
});

export function openModal(modal: ModalEnum, options?: ModalOptions): ActionReturnType {
  return {
    type: AppActionType.OPEN_MODAL,
    payload: { modal, options },
  };
}

export const closeModal =
  (success = true): ThunkAction<ReduxState, void> =>
  (dispatch, getState) => {
    const options = getState().app.modalOptions;
    if (success) options?.successCallback?.();
    options?.closeCallback?.(success);
    dispatch({ type: AppActionType.CLOSE_MODAL });
  };
