import React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { languageToCountry } from './services/Country';
import { init } from './store/app';
import { RestrictionProvider } from './views/Restriction';

import './assets/styles/main.scss';

interface Props {
  language: string;
  campaignId?: string;
  vehicleFeedFilter?: string;
  children: React.ReactNode;
}

export default function PageWrapper(props: Props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(init(props.language, languageToCountry(props.language), props.campaignId, props.vehicleFeedFilter));
  }, []);

  return (
    <>
      <RestrictionProvider>{props.children}</RestrictionProvider>
    </>
  );
}
