import type { OrderQuery } from '../model/Order';
import { fetchSearchDetail, type SearchDetail } from '../model/SearchDetail';
import { filtersToAPIData } from '../model/SearchFilters';
import { ApiResponse } from '../services/Api';

import type { Action, ReduxState } from '.';
import { FavoriteVehiclesActionType } from './favoriteVehicles';
import { flashMessageInfo } from './session';

export enum CarDetailActionType {
  LOAD = 'carDetail/load',
  SET = 'carDetail/set',
  ERROR = 'carDetail/error',
}

export interface CarDetailState {
  data: SearchDetail;
  loading: boolean;
  error: string;
}

const initialState: CarDetailState = {
  data: null,
  loading: true,
  error: null,
};

export type CarDetailAction =
  | Action<CarDetailActionType.LOAD, boolean>
  | Action<CarDetailActionType.SET, SearchDetail>
  | Action<CarDetailActionType.ERROR, string>
  | Action<FavoriteVehiclesActionType.ADD | FavoriteVehiclesActionType.REMOVE, string>;

export default function carDetail(state = initialState, action: CarDetailAction): CarDetailState {
  if (action.type === CarDetailActionType.LOAD) {
    return {
      loading: true,
      data: action.payload ? null : state.data,
      error: null,
    };
  }

  if (action.type === CarDetailActionType.ERROR) {
    return {
      loading: false,
      data: null,
      error: action.payload,
    };
  }

  if (action.type === CarDetailActionType.SET) {
    return {
      loading: false,
      data: action.payload,
      error: null,
    };
  }

  if (
    [FavoriteVehiclesActionType.ADD, FavoriteVehiclesActionType.REMOVE].includes(action.type) &&
    state.data?.vehicle.hash === action.payload
  ) {
    const is_favorite = action.type === FavoriteVehiclesActionType.ADD;
    return {
      ...state,
      data: {
        ...state.data,
        vehicle: {
          ...state.data.vehicle,
          is_favorite,
        },
      },
    };
  }

  return state;
}

export function fetchVehicleDetail(hash: string, query: OrderQuery = {}): ThunkAction<ReduxState> {
  return async (dispatch, getState) => {
    const { carDetail, app } = getState();
    dispatch({
      type: CarDetailActionType.LOAD,
      payload: carDetail.data?.vehicle.hash !== hash,
    });
    try {
      const { initialFilters } = (getState() as ReduxState).rentCar;
      const data = filtersToAPIData({ ...query, country_code: app.region }, initialFilters);
      const vehicle = await fetchSearchDetail(hash, data);
      dispatch({ type: CarDetailActionType.SET, payload: vehicle });
      return vehicle;
    } catch (err) {
      if (err instanceof ApiResponse) {
        dispatch(flashMessageInfo(err.message));
        dispatch({ type: CarDetailActionType.SET, payload: err.data });
        return;
      }
      dispatch({ type: CarDetailActionType.ERROR, payload: err.message });
    }
  };
}
