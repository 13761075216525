import { cancelTrip, declineTrip, fetchTrip, isOwner, isTripRequest, payTrip, type Trip } from '../model/Trip';

import type { ReduxState } from '.';
import { flashMessageError, flashMessageSuccess } from './session';

export interface TripDetailStore {
  loading: boolean;
  error: string;
  data: Trip;
}

enum ActionType {
  LOAD_BEGIN = 'tripDetail/load/begin',
  LOAD_SUCCESS = 'tripDetail/load/success',
  LOAD_ERROR = 'tripDetail/load/error',
  PAY = 'tripDetail/pay',
  SET = 'tripDetail/set',
}

const initialState: TripDetailStore = {
  loading: true,
  error: null,
  data: null,
};

type Action =
  | { type: ActionType.LOAD_BEGIN }
  | { type: ActionType.LOAD_ERROR; payload: string }
  | { type: ActionType.LOAD_SUCCESS; payload: Trip }
  | { type: ActionType.PAY }
  | { type: ActionType.SET; payload: Trip };

export default function tripDetail(state = initialState, action: Action) {
  if (action.type === ActionType.LOAD_BEGIN) {
    return { ...state, loading: true };
  }

  if (action.type === ActionType.LOAD_ERROR) {
    return { ...state, loading: false, error: action.payload };
  }

  if (action.type === ActionType.LOAD_SUCCESS) {
    return { ...state, loading: false, error: null, data: action.payload };
  }

  if (action.type === ActionType.PAY) {
    const trip = { ...state.data };
    trip.paid = true;
    return { ...state, data: trip };
  }

  return state;
}

export const loadTripDetail =
  (hash: string): ThunkAction<ReduxState> =>
  async (dispatch, getState) => {
    if (getState().tripDetail.data?.hash === hash) return;

    dispatch({ type: ActionType.LOAD_BEGIN });
    try {
      const trip = await fetchTrip(hash);
      dispatch({ type: ActionType.LOAD_SUCCESS, payload: trip });
    } catch (error) {
      dispatch({ type: ActionType.LOAD_ERROR, payload: error.message });
    }
  };

export const payTripDetail =
  (nonce: string, deviceData: UnsafeAny): ThunkAction<ReduxState> =>
  async (dispatch, getState) => {
    const { data } = getState().tripDetail;
    if (!data) return;
    try {
      await payTrip(data.hash, nonce, deviceData);
      dispatch(flashMessageSuccess('global.paymentSuccess'));
      dispatch({ type: ActionType.PAY });
    } catch (error) {
      dispatch(flashMessageError(error.message));
    }
  };

export const cancelTripDetail =
  (reason: string): ThunkAction<ReduxState> =>
  async (dispatch, getState) => {
    const { data } = getState().tripDetail;
    try {
      let trip: Trip;
      if (isOwner(getState().user, data) && isTripRequest(data)) {
        trip = await declineTrip(data.hash, reason);
      } else {
        trip = await cancelTrip(data.hash, reason);
      }
      dispatch({ type: ActionType.SET, payload: trip });
    } catch (error) {
      dispatch(flashMessageError(error?.message));
    }
  };
