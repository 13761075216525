import { type ChatDetail, fetchChatList } from '../model/Chat';
import { firebaseAuth } from '../services/Firebase';
import type { Page } from '../services/Paging';

import type { ReduxState } from '.';

const START_LOADING = 'messages/loading';
export const SET_CHATS_LIST = 'messages/SET_CHAT_LIST';
export const SET_NEXT_CHATS_LIST = 'messages/SET_NEXT_CHATS_LIST';
export const SET_MESSAGES_ERROR = 'messages/SET_MESSAGES_ERROR';
export const SET_CHAT = 'messages/SET_CHAT';

export interface MessagesState {
  data?: Page<ChatDetail>;
  error?: string;
  loading?: boolean;
  selectedChatDetail?: ChatDetail;
}

const initialState: MessagesState = {
  data: null,
  error: null,
  loading: false,
  selectedChatDetail: null,
};

export default function reducer(state = initialState, action): MessagesState {
  if (action.type === START_LOADING) {
    return {
      ...initialState,
      loading: true,
    };
  }

  if (action.type === SET_CHATS_LIST) {
    return {
      ...state,
      data: action.payload.data,
      loading: false,
    };
  }

  if (action.type === SET_NEXT_CHATS_LIST) {
    action.payload.items = [...state.data.items, ...action.payload.items];
    return {
      ...state,
      data: action.payload,
      loading: false,
    };
  }

  if (action.type === SET_CHAT) {
    const selectedChat = {
      ...action.payload,
      last_message: action.payload.last_message
        ? {
            ...action.payload.last_message,
            seen_by_me: true,
          }
        : null,
    };
    return {
      ...state,
      selectedChatDetail: selectedChat,
      data: {
        ...state.data,
        items: state.data.items.map(item => {
          if (item.chat_id === action.payload.chat_id) {
            return { ...selectedChat };
          }
          return item;
        }),
      },
      loading: false,
    };
  }

  if (action.type === SET_MESSAGES_ERROR) {
    return {
      data: null,
      loading: false,
      error: action.payload,
    };
  }

  return state;
}

const setChatsList = payload => ({ type: SET_CHATS_LIST, payload });

const setNextChatsList = payload => ({ type: SET_NEXT_CHATS_LIST, payload });

const setMessagesError = payload => ({ type: SET_MESSAGES_ERROR, payload });

export const selectChat =
  (chat: ChatDetail): ThunkAction<ReduxState> =>
  dispatch => {
    dispatch({ type: SET_CHAT, payload: chat });
  };

let loader: Promise<UnsafeAny>;
export const loadChatsList = (): ThunkAction<ReduxState> => async dispatch => {
  if (loader) return await loader;

  dispatch({ type: START_LOADING });
  loader = Promise.all([firebaseAuth(), fetchChatList()])
    .then(([, response]) => {
      dispatch(setChatsList({ data: response }));
    })
    .catch((error?: Error) => {
      dispatch(setMessagesError(error.message));
    })
    .finally(() => {
      loader = null;
    });

  await loader;
};

export const loadNextChatsList =
  (page: number): ThunkAction<ReduxState> =>
  async dispatch => {
    const response = await fetchChatList(page);
    dispatch(setNextChatsList(response));
  };
