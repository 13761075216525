import { composeWithDevTools } from '@redux-devtools/extension';
import { applyMiddleware, createStore as createReduxStore } from 'redux';
import thunk from 'redux-thunk';

import type { UserRegistrationStore } from '../modules/UserRegistration/SumsubUserRegistration';

import type { AppStore } from './app';
import type { CarDetailState } from './carDetail';
import type { CarRegistrationState } from './carRegistration';
import type { MyCarsState } from './cars';
import type { EnumsStore } from './enums';
import type { FavoriteVehiclesState } from './favoriteVehicles';
import type { MessagesState } from './messages';
import type { MyProfileState } from './myProfile';
import type { OrderState } from './order';
import type { PaymentMethodsState } from './paymentMethods';
import type { PublicProfileStore } from './publicProfile';
import reducers from './reducers';
import type { RentCarState } from './rentCar';
import type { SessionStore } from './session';
import type { TripDetailStore } from './tripDetail';
import type { UserState } from './user';

export interface ReduxState {
  app: AppStore;
  session: SessionStore;
  enums: EnumsStore;
  rentCar: RentCarState;
  favoriteVehicles: FavoriteVehiclesState;
  myCars: MyCarsState;
  carRegistration: CarRegistrationState;
  user: UserState;
  userRegistration: UserRegistrationStore;
  carDetail: CarDetailState;
  messages: MessagesState;
  order: OrderState;
  publicProfile: PublicProfileStore;
  tripDetail: TripDetailStore;
  myProfile: MyProfileState;
  paymentMethods: PaymentMethodsState;
}

export interface Action<T = string, P = null> {
  type: T;
  payload: P;
  language?: string;
  region?: string;
}

export interface ReduxData<T> {
  loading: boolean;
  error: string;
  data: T;
}

export interface ReduxPagedData<T> extends ReduxData<T> {
  loadingMore: boolean;
}

export default function createStore(preloadedState) {
  const middleware = applyMiddleware(thunk);
  const enhancers = composeWithDevTools(middleware);
  const store = createReduxStore(reducers, preloadedState, enhancers);
  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(reducers));
  }
  return store;
}
