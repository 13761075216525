import { type OriginalResponse, PrivateApiV3, PublicApiV3 } from '../services/Api';
import type { Datelike } from '../services/DateTime';
import { getEmptyPage, type Page, type PagingResponse } from '../services/Paging';

import type { Document } from './Document';

export type Rating = {
  comment: string;
  value: number;
};

export type RatingItem = {
  user_hash: string;
  avatar_document?: Document;
  first_name: string;
  last_name: string;
  value: number;
  comment: string;
  created: Datelike;
};

export interface RatingCategory {
  type: string;
  name: string;
  value: number;
}

export enum RatingType {
  FROM_OWNERS = 'from_owners',
  FROM_DRIVERS = 'from_drivers',
}

export type RatingTypes = Partial<Record<RatingType, number>>;

export interface Ratings extends Page<RatingItem> {
  average: number;
  categories: RatingCategory[];
}

export interface UserRatings extends Ratings {
  ratingTypes: RatingTypes;
}

interface RatingsAPI extends PagingResponse<RatingItem> {
  rating_average_float: number;
  rating_average_int: number;
  rating_categories: RatingCategory[];
  rating_by_type: {
    type: RatingType;
    total: number;
  }[];
}

export async function fetchUserRatings(hash: string, type: RatingType, page?: number): Promise<UserRatings> {
  const response = await PublicApiV3.get<Page<RatingItem>, OriginalResponse<RatingsAPI>>(
    `/user/${hash}/ratings/${type}`,
    {
      page,
    },
  );
  const ratingTypes: RatingTypes = {};
  for (const it of response.originalData.data.rating_by_type) {
    ratingTypes[it.type] = it.total;
  }
  return {
    ...response.data,
    categories: response.originalData.data.rating_categories,
    average: response.originalData.data.rating_average_float,
    ratingTypes,
  };
}

export async function fetchPrivateUserRatings(hash: string, type: RatingType, page?: number): Promise<UserRatings> {
  try {
    const response = await PrivateApiV3.get<Page<RatingItem>, OriginalResponse<RatingsAPI>>(
      `/user/${hash}/ratings/${type}`,
      {
        page,
      },
    );
    const ratingTypes: RatingTypes = {};
    for (const it of response.originalData.data.rating_by_type) {
      ratingTypes[it.type] = it.total;
    }
    return {
      ...response.data,
      categories: response.originalData.data.rating_categories,
      average: response.originalData.data.rating_average_float,
      ratingTypes,
    };
  } catch {
    return {
      ...getEmptyPage<RatingItem>(),
      categories: [],
      average: null,
      ratingTypes: {},
    };
  }
}

export async function fetchVehicleRatings(hash: string, page?: number): Promise<Ratings> {
  const response = await PrivateApiV3.get<Page<RatingItem>, OriginalResponse<RatingsAPI>>(`/vehicles/${hash}/ratings`, {
    page,
  });
  return {
    ...response.data,
    categories: response.originalData.data.rating_categories,
    average: response.originalData.data.rating_average_float,
  };
}
