import { PrivateApiV3 } from './Api';

interface SumsubTokenApiResponse {
  access_token: string;
}

export async function fetchSumsubToken() {
  const response = await PrivateApiV3.get<SumsubTokenApiResponse>('/sumsub/token');
  return response.data.access_token;
}

export async function notifySumSubCompletion() {
  await PrivateApiV3.post('/sumsub/hook/completed');
}
