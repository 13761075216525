import React, { useContext, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { CookieKeys, setLanguageCookie } from './services/Cookies';
import { type Language, supportedLanguages } from './services/Language';
import { Translation } from './services/Translation';

export type TFunction = Translation['translate'];

export interface TranslationContext {
  language: Language;
  languages: Language[];
  translate: TFunction;
  resolveKeys: Translation['resolveKeys'];
  extractMissingTranslations: Translation['extractMissingTranslations'];

  changeLanguage(language: Language): void;
}

const context = React.createContext<TranslationContext>(null);

export function useTranslationContext() {
  return useContext(context);
}

interface TranslationProviderProps {
  language?: Language;
  translations?: Record<string, unknown>;
}

export function TranslationProvider({ language, children }: React.PropsWithChildren<TranslationProviderProps>) {
  const data = useStaticQuery(graphql`
    query {
      locales: allLocale {
        edges {
          node {
            ns
            data
            language
          }
        }
      }
    }
  `);
  const translations = (data?.locales?.edges ?? []).map(({ node }) => ({
    language: node.language,
    ns: node.ns,
    data: JSON.parse(node.data),
  }));
  const service = useRef(new Translation(language, translations));

  const ctx: TranslationContext = {
    language,
    languages: supportedLanguages,
    changeLanguage(language: Language) {
      service.current.setLanguage(language);
      localStorage.setItem(CookieKeys.LANGUAGE, language);
      setLanguageCookie(language);
    },
    translate(id, options) {
      return service.current.translate(id, options);
    },
    resolveKeys(keys, options) {
      return service.current.resolveKeys(keys, options);
    },
    extractMissingTranslations() {
      return service.current.extractMissingTranslations();
    },
  };

  return (
    <context.Provider value={ctx}>
      {children}
      <MissingTranslationsExtractButton />
    </context.Provider>
  );
}

TranslationProvider.displayName = 'TranslationProvider';

function MissingTranslationsExtractButton() {
  const { extractMissingTranslations } = useTranslationContext();

  if (process.env.NODE_ENV === 'production' || process.env.GATSBY_TRANSLATION_DEBUG !== 'true') return null;

  return (
    <button
      onClick={() => {
        const translations = extractMissingTranslations();
        console.group('Missing translations');
        console.log(JSON.stringify(translations, null, 2));
        console.groupEnd();
      }}
      style={{ position: 'fixed', bottom: 0, right: 0, zIndex: 9999 }}
      type="button"
    >
      Extract missing translations
    </button>
  );
}
