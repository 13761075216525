import { PublicApiV3 } from '../services/Api';

export interface APIConfigItem {
  trans_code: string;
  value: string;
}

export type RegionAPIConfig = APIConfigItem[];

export async function fetchConfigs() {
  const response = await PublicApiV3.get<RegionAPIConfig>('/configs/visible');
  return response.data;
}
