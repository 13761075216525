export enum Language {
  CS = 'cs',
  EN = 'en',
  PL = 'pl',
  SK = 'sk',
  DEV = 'dev',
}

const LanguageMapping = {
  [Language.CS]: {
    apiHeader: 'cs_CZ',
    fbLanguage: 'cs_CZ',
  },
  [Language.PL]: {
    apiHeader: 'pl_PL',
    fbLanguage: 'pl_PL',
  },
  [Language.SK]: {
    apiHeader: 'sk_SK',
    fbLanguage: 'sk_SK',
  },
  [Language.EN]: {
    apiHeader: 'en_US',
    fbLanguage: 'en_US',
  },
};

export const defaultLanguage = (process.env.GATSBY_DEFAULT_LANGUAGE as Language) ?? Language.CS;
export const supportedLanguages = (process.env.GATSBY_SUPPORTED_LANGUAGES?.split(',') as Language[]) ?? [
  Language.CS,
  Language.EN,
  Language.PL,
  Language.SK,
];
export const devSupportedLanguages = [...supportedLanguages, Language.DEV];

export const languageToApiHeader = (lang: string) => {
  return LanguageMapping[lang]?.apiHeader ?? LanguageMapping[defaultLanguage].apiHeader;
};

export const languageToFBLang = (lang: string): string => {
  return LanguageMapping[lang]?.fbLanguage ?? LanguageMapping[defaultLanguage].fbLanguage;
};
