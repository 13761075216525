import { type ApiResponse, type OriginalShared, PublicApiV3 } from '../services/Api';
import type { Page, PagingResponse } from '../services/Paging';

import type { FiltrationParameters } from './FiltrationParameters';
import { filtersToAPIData, type SearchFilters } from './SearchFilters';
import type { Trip } from './Trip';

export type SearchResults = Page<Trip>;

interface SearchOriginalResponse extends OriginalShared {
  data: PagingResponse<Trip> & { filtration_parameters: FiltrationParameters };
}

export const fetchSearch = async (
  filters?: Partial<SearchFilters>,
  defaultFilters?: SearchFilters,
  params?: Partial<FiltrationParameters>,
  page?: number,
): Promise<ApiResponse<SearchResults, SearchOriginalResponse>> => {
  const body = filtersToAPIData(filters, defaultFilters, params);
  return (await PublicApiV3.post('/vehicles/search', body, {
    limit: 10,
    page,
  })) as ApiResponse<SearchResults, SearchOriginalResponse>;
};

export const fetchSearchResults = async (
  filters: Partial<SearchFilters>,
  defaultFilters?: SearchFilters,
  params?: Partial<FiltrationParameters>,
  page?: number,
): Promise<Page<Trip>> => {
  const response = await fetchSearch(filters, defaultFilters, params, page);
  return response.data;
};

export const fetchFiltrationParameters = async (): Promise<Partial<FiltrationParameters>> => {
  try {
    const response = await fetchSearch();
    if (!('filtration_parameters' in response.originalData.data)) {
      return {};
    }
    return response.originalData.data.filtration_parameters;
  } catch (error) {
    return {};
  }
};
