import { fetchUserRatings, type RatingType, type UserRatings } from '../model/Ratings';
import { fetchPublicProfile, type PublicUser } from '../model/User';

import type { Action, ReduxPagedData, ReduxState } from '.';
import { flashMessageError } from './session';

enum PublicProfileActionType {
  LOAD_START = 'publicProfile/loadStart',
  SET = 'publicProfile/set',
  ERROR = 'publicProfile/error',

  REVIEWS_LOAD = 'publicProfile/reviews/load',
  REVIEWS_SET = 'publicProfile/reviews/set',
  REVIEWS_ERROR = 'publicProfile/reviews/error',
  REVIEWS_LOAD_MORE = 'publicProfile/reviews/loadMore',
  REVIEWS_SET_MORE = 'publicProfile/reviews/setMore',
}

export interface PublicProfileStore {
  loading: boolean;
  error: string;
  data: PublicUser;
  language: string;
  ratingType: RatingType;
  reviews: ReduxPagedData<UserRatings>;
}

const initialState: PublicProfileStore = {
  loading: true,
  error: null,
  data: null,
  language: null,
  ratingType: null,
  reviews: {
    loading: true,
    loadingMore: false,
    error: null,
    data: null,
  },
};

export function loadPublicProfile(hash: string, lang: string): ThunkAction<ReduxState> {
  return async (dispatch, getState) => {
    const state = getState().publicProfile;
    if (state.data?.hash === hash && lang === state.language) return;

    try {
      dispatch({ type: PublicProfileActionType.LOAD_START, payload: lang });
      const response = await fetchPublicProfile(hash);
      dispatch({ type: PublicProfileActionType.SET, payload: response });
    } catch (error) {
      dispatch({ type: PublicProfileActionType.ERROR, payload: error.message });
    }
  };
}

export function loadPublicProfileReviews(hash: string, type: RatingType): ThunkAction<ReduxState> {
  return async (dispatch, getState) => {
    const { publicProfile: state, app } = getState();
    if (state.data?.hash === hash && state.ratingType === type && state.reviews.data) return;

    dispatch({ type: PublicProfileActionType.REVIEWS_LOAD, payload: type });
    await dispatch(loadPublicProfile(hash, app.language));

    try {
      const reviews = await fetchUserRatings(hash, type);
      dispatch({
        type: PublicProfileActionType.REVIEWS_SET,
        payload: { ratings: reviews, type },
      });
    } catch (error) {
      dispatch({
        type: PublicProfileActionType.REVIEWS_ERROR,
        payload: error.message,
      });
    }
  };
}

export function loadPublicProfileReviewsNextPage(hash: string, type: RatingType): ThunkAction<ReduxState> {
  return async (dispatch, getState) => {
    const { data } = getState().publicProfile.reviews;
    if (!data || !data.has_next) return;
    dispatch({ type: PublicProfileActionType.REVIEWS_LOAD_MORE });
    try {
      const reviews = await fetchUserRatings(hash, type, data.page + 1);
      dispatch({
        type: PublicProfileActionType.REVIEWS_SET_MORE,
        payload: reviews,
      });
    } catch (error) {
      dispatch(flashMessageError(error.message));
    }
  };
}

type PublicProfileAction =
  | Action<PublicProfileActionType.LOAD_START, string>
  | Action<PublicProfileActionType.SET, PublicUser>
  | Action<PublicProfileActionType.ERROR, string>
  | Action<PublicProfileActionType.REVIEWS_LOAD, RatingType>
  | Action<PublicProfileActionType.REVIEWS_SET, { ratings: UserRatings; type: RatingType }>
  | Action<PublicProfileActionType.REVIEWS_ERROR, string>
  | Action<PublicProfileActionType.REVIEWS_LOAD_MORE>
  | Action<PublicProfileActionType.REVIEWS_SET_MORE, UserRatings>;

export default function publicProfile(state = initialState, action: PublicProfileAction): PublicProfileStore {
  if (action.type === PublicProfileActionType.LOAD_START) {
    return {
      ...state,
      loading: true,
      error: null,
      data: null,
      language: action.payload,
    };
  }

  if (action.type === PublicProfileActionType.SET) {
    return {
      ...state,
      loading: false,
      error: null,
      data: action.payload,
    };
  }

  if (action.type === PublicProfileActionType.ERROR) {
    return {
      ...state,
      loading: false,
      error: action.payload,
      data: null,
    };
  }

  if (action.type === PublicProfileActionType.REVIEWS_LOAD) {
    return {
      ...state,
      ratingType: action.payload,
      reviews: {
        loading: true,
        loadingMore: false,
        error: null,
        data: state.reviews.data,
      },
    };
  }

  if (action.type === PublicProfileActionType.REVIEWS_ERROR) {
    return {
      ...state,
      reviews: {
        loading: false,
        loadingMore: false,
        error: action.payload,
        data: null,
      },
    };
  }

  if (action.type === PublicProfileActionType.REVIEWS_SET) {
    return {
      ...state,
      ratingType: action.payload.type,
      reviews: {
        loading: false,
        loadingMore: false,
        error: null,
        data: action.payload.ratings,
      },
    };
  }

  if (action.type === PublicProfileActionType.REVIEWS_LOAD_MORE) {
    return {
      ...state,
      reviews: {
        loading: false,
        loadingMore: true,
        error: null,
        data: state.reviews.data,
      },
    };
  }

  if (action.type === PublicProfileActionType.REVIEWS_SET_MORE) {
    return {
      ...state,
      reviews: {
        loading: false,
        loadingMore: false,
        error: null,
        data: {
          ...state.reviews.data,
          has_next: action.payload.has_next,
          page: action.payload.page,
          items: [...state.reviews.data.items, ...action.payload.items],
        },
      },
    };
  }

  return state;
}
