import {
  addUserFavoriteVehicle,
  deleteUserFavoriteVehicle,
  type FavoriteVehicles,
  fetchUserFavoriteVehicles,
} from '../model/FavoriteVehicles';

import type { ReduxState } from './index';

export enum FavoriteVehiclesActionType {
  LOADING = 'favoriteVehicles/loading',
  SET = 'favoriteVehicles/set',
  ADD = 'favoriteVehicles/add',
  REMOVE = 'favoriteVehicles/remove',
  LOADING_MORE = 'favoriteVehicles/loadingMore',
  SET_MORE = 'favoriteVehicles/setMore',
}

export function loadFavoriteVehicles(): ThunkAction<ReduxState> {
  return async dispatch => {
    dispatch({ type: FavoriteVehiclesActionType.LOADING });
    const result = await fetchUserFavoriteVehicles();
    dispatch({ type: FavoriteVehiclesActionType.SET, payload: result });
  };
}

export function loadMoreFavoriteVehicles(): ThunkAction<ReduxState> {
  return async (dispatch, getState) => {
    const { page = 1 } = getState().favoriteVehicles.data;
    dispatch({ type: FavoriteVehiclesActionType.LOADING_MORE });
    const result = await fetchUserFavoriteVehicles(page + 1);
    dispatch({ type: FavoriteVehiclesActionType.SET_MORE, payload: result });
  };
}

export function addFavoriteVehicle(hash: string): ThunkAction<ReduxState> {
  return async dispatch => {
    dispatch({ type: FavoriteVehiclesActionType.ADD, payload: hash });
    try {
      await addUserFavoriteVehicle(hash);
    } catch (e) {
      dispatch({ type: FavoriteVehiclesActionType.REMOVE, payload: hash });
      throw e;
    }
  };
}

export function removeFavoriteVehicle(hash: string): ThunkAction<ReduxState> {
  return async dispatch => {
    await deleteUserFavoriteVehicle(hash);
    dispatch({ type: FavoriteVehiclesActionType.REMOVE, payload: hash });
  };
}

export interface FavoriteVehiclesState {
  loading: boolean;
  loadingMore: boolean;
  data: FavoriteVehicles;
}

const initialState: FavoriteVehiclesState = {
  loading: true,
  loadingMore: false,
  data: null,
};

type Action =
  | { type: FavoriteVehiclesActionType.LOADING }
  | { type: FavoriteVehiclesActionType.SET; payload: FavoriteVehicles }
  | { type: FavoriteVehiclesActionType.ADD }
  | { type: FavoriteVehiclesActionType.REMOVE; payload: string }
  | { type: FavoriteVehiclesActionType.LOADING_MORE }
  | { type: FavoriteVehiclesActionType.SET_MORE; payload: FavoriteVehicles };

export default function favoriteVehicles(state = initialState, action: Action): FavoriteVehiclesState {
  if (action.type === FavoriteVehiclesActionType.LOADING) {
    return { ...state, loading: true };
  }

  if (action.type === FavoriteVehiclesActionType.SET) {
    return { loading: false, loadingMore: false, data: action.payload };
  }

  if (action.type === FavoriteVehiclesActionType.ADD && state.data !== null && !state.loading) {
    return initialState;
  }

  if (action.type === FavoriteVehiclesActionType.REMOVE && state.data) {
    let found = false;
    const items = state.data.items.filter(it => {
      if (it.hash === action.payload) {
        found = true;
        return false;
      }
      return true;
    });
    return {
      ...state,
      data: {
        ...state.data,
        items,
        total_items: found ? state.data.total_items - 1 : state.data.total_items,
      },
    };
  }

  if (action.type === FavoriteVehiclesActionType.LOADING_MORE) {
    return { ...state, loadingMore: true };
  }

  if (action.type === FavoriteVehiclesActionType.SET_MORE) {
    const items = state.data.items.slice().concat(action.payload.items);
    return {
      ...state,
      loadingMore: false,
      data: {
        ...state.data,
        page: action.payload.page,
        has_next: action.payload.has_next,
        items,
      },
    };
  }

  return state;
}
