enum LogLevel {
  DEBUG = 0,
  WARNING = 1,
  ERROR = 2,
}

export class Logger {
  public static Level = LogLevel;
  public static default = new Logger();
  private static adapters: Set<LogAdapter> = new Set();

  private readonly levelPermissions: Record<LogLevel, boolean>;

  constructor(
    protected namespace?: string,
    levelPermissions: Partial<Record<LogLevel, boolean>> = {
      [LogLevel.DEBUG]: true,
      [LogLevel.WARNING]: true,
      [LogLevel.ERROR]: true,
    },
  ) {
    this.levelPermissions = {
      [LogLevel.DEBUG]: levelPermissions[LogLevel.DEBUG] ?? true,
      [LogLevel.WARNING]: levelPermissions[LogLevel.WARNING] ?? true,
      [LogLevel.ERROR]: levelPermissions[LogLevel.ERROR] ?? true,
    };
  }

  public debug(message: string, data?: Record<string, unknown>): void {
    this.message(LogLevel.DEBUG, message, data);
    for (const adapter of Logger.adapters) {
      adapter.debug?.(message, data);
    }
  }

  public warning(message: string, data?: Record<string, unknown>): void {
    this.message(LogLevel.WARNING, message, data);
    for (const adapter of Logger.adapters) {
      adapter.warning?.(message, data);
    }
  }

  public error(error: string | Error, data?: Record<string, unknown>): void {
    const message = error instanceof Error ? error.message : error;
    this.message(LogLevel.ERROR, message, data);
    for (const adapter of Logger.adapters) {
      adapter.error(error, data);
    }
  }

  private message(level: LogLevel, ...message: UnsafeAny[]): void {
    const permission = this.levelPermissions[level];
    if (permission !== true) return;
    // prettier-ignore
    switch (level) {
			case LogLevel.DEBUG:
				console.debug(`${this.namespace}:`, ...message);
				break;
			case LogLevel.ERROR:
				console.error(`${this.namespace}:`, ...message);
				break;
			default:
				console.log(`${this.namespace}:`, ...message);
				break;
		}
  }

  public static addAdapter(adapter: new () => LogAdapter): void {
    this.adapters.add(new adapter());
  }
}

export interface LogAdapter {
  debug?(message: string, data?: Record<string, unknown>): unknown;

  warning?(message: string, data?: Record<string, unknown>): unknown;

  error(message: string | Error, data?: Record<string, unknown>): unknown;
}
