import { PrivateApiV3, PublicApiV3 } from '../services/Api';
import type { Authorization } from '../services/Authorization';

import type { User } from './User';

export async function createUser(email: string, password: string, invitationCode?: string) {
  return await PublicApiV3.post<User>('/auth/register', {
    email,
    username: email,
    password,
    password_confirm: password,
    invitation_code: invitationCode,
  });
}

export async function authorizeUserWithEmail(email: string, password: string) {
  return await PublicApiV3.post<Authorization>('/auth/login', {
    username: email,
    password,
  });
}

export async function authorizeUserWithFacebook(accessToken: string) {
  return await PublicApiV3.post<Authorization>('/auth/facebook', {
    access_token: accessToken,
  });
}

export async function authorizeUserWithGoogle(accessToken: string) {
  return await PublicApiV3.post<Authorization>('/auth/google', {
    access_token: accessToken,
  });
}

export async function authorizeUserWithApple(accessToken: string) {
  return await PublicApiV3.post<Authorization>('/auth/apple', {
    access_token: accessToken,
  });
}

export async function logoutUser() {
  await PrivateApiV3.post('/auth/logout', {});
}

export async function requestPasswordReset(email: string) {
  await PublicApiV3.post('/auth/password_reset', {
    username: email,
  });
}

const EMAIL_VERIFICATION_KEY = 'hoppygo_emailverification';

export function watchEmailVerification(callback: () => void) {
  const listener = (event: StorageEvent) => {
    if (event.key === EMAIL_VERIFICATION_KEY && event.newValue === JSON.stringify(true)) {
      callback();
    }
  };
  window.addEventListener('storage', listener);
  return () => {
    window.removeEventListener('storage', listener);
  };
}

export async function sendUserEmailVerificationCode(code: string) {
  await PublicApiV3.get(`/auth/verify_email/${code}`);
  window.localStorage.setItem(EMAIL_VERIFICATION_KEY, JSON.stringify(true));
}

export async function changePassword(hash: string, password: string, password_confirm: string) {
  await PublicApiV3.post('/auth/password_change', {
    hash,
    password,
    password_confirm,
  });
}
