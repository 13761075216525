import { PrivateApiV3, PublicApiV3 } from '../services/Api';

import type { PriceType } from './Price';

export interface FlexibleDiscount {
  min_days: number;
  discount_range: {
    min: number;
    max: number;
  };
}

export interface MyVehicleDiscount {
  min_days: number;
  flexible_discount?: number;
  discount_range?: {
    min: number;
    max: number;
  };
}

export interface VehiclePriceListItem<Price = PriceType> extends MyVehicleDiscount {
  price: Price;
}

export async function fetchVehiclePriceList(hash: string) {
  try {
    const response = await PrivateApiV3.get<VehiclePriceListItem[]>(`/vehicles/${hash}/prices`);
    return response.data;
  } catch (_) {
    return [];
  }
}

export async function fetchSearchDetailPriceList(hash: string) {
  try {
    const response = await PublicApiV3.get<VehiclePriceListItem[]>(`/vehicles/${hash}/driver_prices`);
    return response.data;
  } catch (_) {
    return [];
  }
}

export async function updateVehiclePriceList(
  hash: string,
  price_list: VehiclePriceListItem<number>[],
  price_per_km: number,
) {
  const response = await PrivateApiV3.put<VehiclePriceListItem[]>(`/vehicles/${hash}/prices`, {
    price_list,
    price_per_km,
  });
  return response.data;
}

export function calculatePriceAfterDiscount(price_per_day: number, discount: number): number {
  const discountVal = (100 - discount) / 100;
  return Math.ceil(price_per_day * discountVal);
}

export async function updateVehiclePrices(
  hash: string,
  price_per_day: number,
  price_per_km: number,
  flexibleDiscounts: MyVehicleDiscount[],
) {
  return await updateVehiclePriceList(
    hash,
    [
      {
        min_days: 0,
        price: price_per_day,
      },
      ...flexibleDiscounts.map(item => ({
        min_days: item.min_days,
        price: calculatePriceAfterDiscount(price_per_day, item.flexible_discount),
        flexible_discount: item.flexible_discount,
      })),
    ],
    price_per_km,
  );
}
