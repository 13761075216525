/**
 * Decorator to enable/disable a method
 * @param enabled Condition in which to enable the method
 */
export function permission(enabled: boolean): MethodDecorator {
  return (target, propertyKey) => {
    if (enabled) return;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    Object.defineProperty(target, propertyKey, () => {});
  };
}
