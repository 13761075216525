import * as yup from 'yup';

import { range } from '../helpers';
import { PrivateApiV3 } from '../services/Api';
import { type Datelike, getEndOfDay, getStartOfDay, SEC_IN_DAY, toDate, toISOString } from '../services/DateTime';

import { MAX_DAYS_DEFAULT, MIN_DAYS_DEFAULT, updateVehicleAttributes } from './Vehicle';

export type VehicleCalendar = {
  disabled_week_days: number[];
  exception_dates: Date[];
  unavailable_dates: Date[];
  reservation_dates: Datelike[];
  min_days: number;
  max_days: number;
};

export type DateRange = {
  date_from: number;
  date_to: number;
};

export type WeekDayRange = {
  day_from: number;
  day_to: number;
};

export type VehicleCalendarAPI = {
  disabled_week_days: WeekDayRange[];
  exception_dates: DateRange[];
  unavailable_dates: DateRange[];
  reservation_dates: string[];
  min_days: number;
  max_days: number;
};

const dateToInterval = day => ({
  date_from: Math.floor(getStartOfDay(day).getTime() / 1000),
  date_to: Math.floor(getEndOfDay(day).getTime() / 1000),
});

const intervalToDate = ({ date_from }) => toDate(date_from * 1000);

export function parseCalendarResponse(response: VehicleCalendarAPI): VehicleCalendar {
  const calendar: VehicleCalendar = {
    disabled_week_days: [],
    unavailable_dates: [],
    exception_dates: [],
    reservation_dates: response.reservation_dates,
    min_days: response.min_days,
    max_days: response.max_days,
  };
  if (calendar.min_days === null || calendar.min_days <= 0 || calendar.min_days === MIN_DAYS_DEFAULT) {
    calendar.min_days = null;
  }
  if (calendar.max_days === null || calendar.max_days <= 0) {
    calendar.max_days = null;
  }
  response.disabled_week_days.forEach(({ day_from, day_to }) => {
    const start = Math.round(day_from / SEC_IN_DAY);
    const end = Math.round(day_to / SEC_IN_DAY);
    if (end - start > 1) {
      calendar.disabled_week_days.push(...range(start, end));
    } else {
      calendar.disabled_week_days.push(start);
    }
  });
  calendar.unavailable_dates = response.unavailable_dates.map(intervalToDate);
  calendar.exception_dates = response.exception_dates.map(intervalToDate);
  return calendar;
}

export function prepareCalendarRequest(calendar: VehicleCalendar): VehicleCalendarAPI {
  const request: VehicleCalendarAPI = {
    disabled_week_days: [],
    unavailable_dates: [],
    exception_dates: [],
    reservation_dates: calendar.reservation_dates.map(date => toISOString(date)),
    min_days: calendar.min_days ?? MIN_DAYS_DEFAULT,
    max_days: calendar.max_days,
  };
  request.disabled_week_days = calendar.disabled_week_days.map(disabledWeekday => ({
    day_from: disabledWeekday * SEC_IN_DAY,
    day_to: disabledWeekday * SEC_IN_DAY + SEC_IN_DAY,
  }));
  request.unavailable_dates = calendar.unavailable_dates.map(dateToInterval);
  request.exception_dates = calendar.exception_dates.map(dateToInterval);
  if (calendar.min_days === MIN_DAYS_DEFAULT) {
    request.min_days = null;
  }
  if (calendar.max_days === MAX_DAYS_DEFAULT) {
    request.max_days = null;
  }
  return request;
}

export function getVehicleCalendarValidationSchema() {
  return yup.object<VehicleCalendar>({
    reservation_dates: yup.array().of(yup.date()),
    unavailable_dates: yup.array().of(yup.date()),
    exception_dates: yup.array().of(yup.date()),
    disabled_week_days: yup.array().of(yup.number()),
    min_days: yup.number().nullable(),
    max_days: yup.number().nullable(),
  });
}

export async function fetchVehicleCalendar(hash: string) {
  const response = await PrivateApiV3.get<VehicleCalendarAPI>(`/vehicles/${hash}/calendar`);
  return parseCalendarResponse(response.data);
}

export const updateVehicleCalendar = async (hash: string, calendar: VehicleCalendar) => {
  const request = prepareCalendarRequest(calendar);
  await PrivateApiV3.put(`/vehicles/${hash}/unavailable_dates`, {
    by_day_of_week: request.disabled_week_days,
    by_date: request.unavailable_dates,
    exception_dates: request.exception_dates,
  });
  await updateVehicleAttributes(hash, {
    min_days: request.min_days,
    max_days: request.max_days,
  });
  return request;
};
