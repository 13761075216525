// reference: https://docs.translatehouse.org/projects/localization-guide/en/latest/l10n/pluralforms.html?id=l10n/pluralforms
const resolvers = {
  1: (n: number): string => (n ? `_plural` : ``),
  2: (n: number): string => {
    if (n === 1) return `_0`;
    if (n >= 2 && n < 5) return `_1`;
    return `_2`;
  },
  3: (n: number): string => {
    if (n === 1) return `_0`;
    if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) return `_1`;
    return `_2`;
  },
};

const rules = [
  [1, 'en'],
  [2, 'cs', 'sk'],
  [3, 'pl'],
];

export function getPluralSuffix(language: string, count: number): string {
  const rule = rules.find(it => it.slice(1).includes(language));
  return resolvers[rule[0]](Math.abs(count));
}
