import { fetchPrivateUserRatings, type RatingType, type UserRatings } from '../model/Ratings';

import type { ReduxPagedData, ReduxState } from './index';
import { flashMessageError } from './session';
import { UserActionType } from './user';

export interface MyProfileState {
  reviews: ReduxPagedData<UserRatings>;
}

const initialState: MyProfileState = {
  reviews: {
    loading: true,
    loadingMore: false,
    error: null,
    data: null,
  },
};

export enum MyProfileActionType {
  REVIEWS_LOAD = 'myProfile/loadReviews',
  REVIEWS_SET = 'myProfile/setReviews',
  REVIEWS_LOAD_MORE = 'myProfile/loadReviewsNextPage',
  REVIEWS_SET_MORE = 'myProfile/setReviewsNextPage',
}

export function loadMyReviews(type: RatingType): ThunkAction<ReduxState> {
  return async (dispatch, getState) => {
    dispatch({ type: MyProfileActionType.REVIEWS_LOAD });
    const reviews = await fetchPrivateUserRatings(getState().user.hash, type);
    dispatch({ type: MyProfileActionType.REVIEWS_SET, payload: reviews });
  };
}

export function loadMyReviewsNextPage(type: RatingType): ThunkAction<ReduxState> {
  return async (dispatch, getState) => {
    const { page, has_next } = getState().myProfile.reviews.data;
    if (!has_next) return;
    try {
      const reviews = await fetchPrivateUserRatings(getState().user.hash, type, page + 1);
      dispatch({
        type: MyProfileActionType.REVIEWS_SET_MORE,
        payload: reviews,
      });
    } catch (error) {
      dispatch(flashMessageError(error.message));
    }
  };
}

type Action =
  | { type: MyProfileActionType.REVIEWS_LOAD }
  | { type: MyProfileActionType.REVIEWS_SET; payload: UserRatings }
  | { type: MyProfileActionType.REVIEWS_LOAD_MORE }
  | { type: MyProfileActionType.REVIEWS_SET_MORE; payload: UserRatings }
  | { type: UserActionType.DESTROY_USER };

export default function myProfile(state = initialState, action: Action): MyProfileState {
  if (action.type === MyProfileActionType.REVIEWS_LOAD) {
    return {
      ...state,
      reviews: {
        loading: true,
        loadingMore: false,
        error: null,
        data: state.reviews.data,
      },
    };
  }
  if (action.type === MyProfileActionType.REVIEWS_SET) {
    return {
      ...state,
      reviews: {
        loading: false,
        loadingMore: false,
        error: null,
        data: action.payload,
      },
    };
  }
  if (action.type === MyProfileActionType.REVIEWS_LOAD_MORE) {
    return {
      ...state,
      reviews: {
        loading: false,
        loadingMore: true,
        error: null,
        data: state.reviews.data,
      },
    };
  }
  if (action.type === MyProfileActionType.REVIEWS_SET_MORE) {
    return {
      ...state,
      reviews: {
        loading: false,
        loadingMore: false,
        error: null,
        data: {
          ...state.reviews.data,
          has_next: action.payload.has_next,
          page: action.payload.page,
          items: [...state.reviews.data.items, ...action.payload.items],
        },
      },
    };
  }
  if (action.type === UserActionType.DESTROY_USER) {
    return initialState;
  }
  return state;
}
