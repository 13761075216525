import type { FiltrationParameters } from '../model/FiltrationParameters';
import { roundPriceAmount } from '../model/Price';
import { fetchSearch, fetchSearchResults, type SearchResults } from '../model/Search';
import { hasChanged, initialSearchFilters, type SearchFilters } from '../model/SearchFilters';
import type { Country } from '../services/Country';

import type { ReduxState } from './';
import { FavoriteVehiclesActionType } from './favoriteVehicles';
import { UserActionType } from './user';

export enum RentCarActionType {
  FETCH_VEHICLES_BEGIN = 'rentCar/FETCH_VEHICLES_BEGIN',
  FETCH_VEHICLES_SUCCESS = 'rentCar/FETCH_VEHICLES_SUCCESS',
  FETCH_MORE_VEHICLES_SUCCESS = 'rentCar/FETCH_MORE_VEHICLES_SUCCESS',
  FETCH_VEHICLES_ERROR = 'rentCar/FETCH_VEHICLES_ERROR',
  SET_FILTERS = 'rentCar/SET_FILTERS',
  RESET_FILTERS = 'rentCar/RESET_FILTERS',
  INIT_FILTERS = 'rentCar/INIT_FILTERS',
}

export interface RentCarState {
  data: SearchResults;
  filtration_parameters: Partial<FiltrationParameters>;
  loading: boolean;
  error: string;
  filters: SearchFilters;
  initialFilters: SearchFilters;
  changed: boolean;
}

export const initialValues: RentCarState = {
  data: null,
  filtration_parameters: {},
  loading: true,
  error: null,
  filters: { ...initialSearchFilters },
  initialFilters: initialSearchFilters,
  changed: false,
};

const setRange = (params: Partial<FiltrationParameters>, name: string, fallback, fraction?: number) => ({
  min: params[`${name}_min`] ? roundPriceAmount(params[`${name}_min`], params.currency, fraction) : fallback.min,
  max: params[`${name}_max`] ? roundPriceAmount(params[`${name}_max`], params.currency, fraction) : fallback.max,
});

function computeInitialFilters(initials: SearchFilters, params?: Partial<FiltrationParameters>): SearchFilters {
  if (!params) return initials;
  return {
    ...initials,
    order: {
      by: params.order_by ?? initials.order.by,
      type: params.order_type ?? initials.order.type,
    },
    first_registration: setRange(params, 'registration_date_extreme', initials.first_registration),
    mileage_price_over_limit: setRange(params, 'mileage_price_over_limit_extreme', initials.mileage_price_over_limit),
  };
}

export default function rentCar(state = initialValues, action) {
  if (action.type === RentCarActionType.FETCH_VEHICLES_SUCCESS) {
    return {
      ...state,
      loading: false,
      error: null,
      data: action.payload,
    };
  }

  if (action.type === RentCarActionType.FETCH_MORE_VEHICLES_SUCCESS) {
    const { data } = state;
    const items = data.items.slice();

    const newData: SearchResults = action.payload;
    newData.items = items.concat(newData.items);

    return {
      ...state,
      loading: false,
      data: newData,
    };
  }

  if (action.type === RentCarActionType.FETCH_VEHICLES_BEGIN) {
    const filters = {
      ...state.filters,
      ...action.payload,
    };
    return {
      ...state,
      data: null,
      loading: true,
      error: null,
      filters,
      changed: hasChanged(filters, state.initialFilters),
    };
  }

  if (action.type === RentCarActionType.FETCH_VEHICLES_ERROR) {
    return {
      ...state,
      loading: false,
      error: action.payload,
      data: null,
    };
  }

  if (action.type === RentCarActionType.SET_FILTERS) {
    const filters = {
      ...state.filters,
      ...action.payload,
    };
    const changed = hasChanged(filters, state.initialFilters);
    return {
      ...state,
      filters,
      changed,
    };
  }

  if (action.type === RentCarActionType.RESET_FILTERS) {
    return {
      ...state,
      filters: { ...state.initialFilters },
      changed: false,
      data: null,
      error: null,
      loading: true,
    };
  }

  if (action.type === RentCarActionType.INIT_FILTERS) {
    return { ...state, ...action.payload };
  }

  if (action.type === UserActionType.DESTROY_USER && state.data) {
    const items = state.data.items.map(it => {
      return { ...it, vehicle: { ...it.vehicle, is_favorite: false } };
    });
    return { ...state, data: { ...state.data, items } };
  }

  if (action.type === FavoriteVehiclesActionType.SET && state.data) {
    const items = state.data.items.map(it => {
      const is_favorite = action.payload.items.some(other => other.hash === it.vehicle.hash);
      return { ...it, vehicle: { ...it.vehicle, is_favorite } };
    });
    return { ...state, data: { ...state.data, items } };
  }

  if (action.type === FavoriteVehiclesActionType.ADD && state.data) {
    const items = state.data.items.map(it => {
      const shouldChange = it.vehicle.hash === action.payload;
      return {
        ...it,
        vehicle: {
          ...it.vehicle,
          is_favorite: shouldChange ? true : it.vehicle.is_favorite,
        },
      };
    });
    return { ...state, data: { ...state.data, items } };
  }

  if (action.type === FavoriteVehiclesActionType.REMOVE && state.data) {
    const items = state.data.items.map(it => {
      const shouldChange = it.vehicle.hash === action.payload;
      return {
        ...it,
        vehicle: {
          ...it.vehicle,
          is_favorite: shouldChange ? false : it.vehicle.is_favorite,
        },
      };
    });
    return { ...state, data: { ...state.data, items } };
  }

  return state;
}

export function initFilters(language: string, region: Country): ThunkAction<ReduxState> {
  return async (dispatch, getState) => {
    const payload: Partial<RentCarState> = {};
    try {
      const response = await fetchSearch({ country_code: region });
      if (response.originalData.data.filtration_parameters) {
        payload.filtration_parameters = response.originalData.data.filtration_parameters;
      }
      const { initialFilters } = getState().rentCar;
      payload.initialFilters = computeInitialFilters(initialFilters, payload.filtration_parameters);
      payload.filters = { ...payload.initialFilters };
      payload.changed = false;
    } catch (error) {
      console.log(error);
    }
    dispatch({
      type: RentCarActionType.INIT_FILTERS,
      payload,
      language,
      region,
    });
  };
}

export function fetchVehicles(filters: Partial<SearchFilters> = {}, page?: number): ThunkAction<ReduxState> {
  return async (dispatch, getState) => {
    if (typeof page !== 'number') {
      const state = getState().rentCar;
      if (!state.data || hasChanged(filters, state.filters)) {
        dispatch({
          type: RentCarActionType.FETCH_VEHICLES_BEGIN,
          payload: filters,
        });
      } else {
        return;
      }
    }

    try {
      const state: ReduxState = getState();
      const response = await fetchSearchResults(
        { ...state.rentCar.filters, ...filters },
        state.rentCar.initialFilters,
        state.rentCar.filtration_parameters,
        page,
      );
      dispatch({
        type:
          typeof page === 'number'
            ? RentCarActionType.FETCH_MORE_VEHICLES_SUCCESS
            : RentCarActionType.FETCH_VEHICLES_SUCCESS,
        payload: response,
      });
    } catch (err) {
      dispatch({
        type: RentCarActionType.FETCH_VEHICLES_ERROR,
        payload: err.message,
      });
    }
  };
}
