import { PrivateApiV3 } from '../services/Api';

import type { UserVerification } from './UserVerification';

export interface UserData {
  forename: string;
  surname: string;
  birthday?: string;
  phone: string;
  sex?: string;
  locale: string;
  birth_number?: string;
  birth_number_screen_visited?: boolean;
  bank_account_number?: string;
  payment_variable?: string;
  payment_interval?: string;
  about_me?: string;
  verifications: UserVerification;
}

export async function updateUserProfile(data: Partial<UserData>) {
  return await PrivateApiV3.patch<UserData>('/user/profile', data);
}

export async function setIDNumber(id_number: string | null, skipped = false) {
  return await PrivateApiV3.patch('/user/profile/id-number', {
    birth_number: id_number,
    skipped_screen: skipped,
  });
}
