import merge from 'lodash/merge';

import { type BankAccount, updateVehicleBankAccount } from '../model/BankAccount';
import { type Document, DocumentType, removeVehicleDocument, uploadVehicleDocument } from '../model/Document';
import type { VehicleParking } from '../model/Location';
import {
  fetchMyVehicleDetail,
  fetchMyVehicles,
  fetchMyVehicleUserBenefits,
  type MyVehicle,
  type MyVehicleDetail,
  type UserBenefit,
} from '../model/MyVehicle';
import { confirmTrip, declineTrip } from '../model/Trip';
import {
  activateVehicle,
  deactivateVehicle,
  deleteVehicle,
  updateVehicleAttributes,
  updateVehicleLocation,
} from '../model/Vehicle';
import type { VehicleAttributes } from '../model/VehicleAttributes';
import { updateVehicleCalendar, type VehicleCalendar } from '../model/VehicleCalendar';
import { type DefaultPricesResponse, fetchVehicleDefaultPrices } from '../model/VehicleDetailPrices';
import { type MyVehicleDiscount, updateVehiclePrices } from '../model/VehiclePriceList';
import type { Page } from '../services/Paging';

import { AppActionType } from './app';
import { CarRegistrationActionType } from './carRegistration';
import type { Action, ReduxState } from './index';
import { flashMessageError } from './session';

export const CAR_REGISTRATION_ONBOARDING = 'hoppygo_car_registration_onboarding';

export enum CarRegistrationOnboarding {
  WELCOME = 'welcome',
  EARN_MONEY = 'earn_money',
  VERIFIED_USERS = 'verified_users',
  ABOUT_CAR = 'about_car',
  AVAILABILITY = 'availability',
  REGISTER = 'register',
  START_REGISTRATION = 'start_registration',
}

export enum MyCarsActionType {
  FETCH_MY_VEHICLES_BEGIN = 'cars/FETCH_MY_VEHICLES_BEGIN',
  FETCH_MY_VEHICLES_SUCCESS = 'cars/FETCH_MY_VEHICLES_SUCCESS',
  FETCH_MY_VEHICLES_ERROR = 'cars/FETCH_MY_VEHICLES_ERROR',
  OPEN_DETAIL = 'cars/OPEN_DETAIL',
  SELECT = 'cars/SELECT',
  UPDATE_SELECTED = 'cars/UPDATE_SELECTED',

  UPDATE_DETAIL = 'cars/UPDATE_DETAIL',
  ADD_PHOTO = 'cars/ADD_PHOTO',
  REMOVE_PHOTO = 'cars/REMOVE_PHOTO',
  REMOVE = 'cars/REMOVE',
  SET_ONBOARDING = 'cars/SET_ONBOARDING',
}

export interface MyCarsState {
  data: Page<MyVehicle>;
  loading: boolean;
  error: string;
  selected: string;
  detail: MyVehicleDetail;
  loadingDetail: boolean;
  defaultPrices: DefaultPricesResponse;
  carToRemove?: string;
  userBenefits?: UserBenefit[];
  hasSeenCarOnboarding: boolean;
  carOnboardingStep: CarRegistrationOnboarding;
}

export const initialValues: MyCarsState = {
  data: null,
  loading: false,
  error: null,
  selected: null,
  detail: null,
  loadingDetail: false,
  defaultPrices: null,
  hasSeenCarOnboarding: false,
  carOnboardingStep: null,
};

type MyCarsAction =
  | Action<AppActionType.INIT>
  | Action<MyCarsActionType.FETCH_MY_VEHICLES_BEGIN>
  | Action<MyCarsActionType.FETCH_MY_VEHICLES_ERROR, string>
  | Action<MyCarsActionType.FETCH_MY_VEHICLES_SUCCESS, Page<MyVehicleDetail>>
  | Action<MyCarsActionType.SELECT, MyVehicleDetail>
  | Action<MyCarsActionType.UPDATE_SELECTED, Partial<MyVehicleDetail>>
  | Action<
      MyCarsActionType.OPEN_DETAIL,
      {
        detail: MyVehicleDetail;
        defaultPrices: DefaultPricesResponse;
        userBenefits: UserBenefit[];
      }
    >
  | Action<MyCarsActionType.UPDATE_DETAIL, DeepPartial<MyVehicleDetail>>
  | Action<MyCarsActionType.ADD_PHOTO, Document>
  | Action<MyCarsActionType.REMOVE_PHOTO, string>
  | Action<MyCarsActionType.REMOVE, string>
  | Action<MyCarsActionType.SET_ONBOARDING, CarRegistrationOnboarding>
  | Action<CarRegistrationActionType.EXIT, MyVehicleDetail>;

export default function myCars(state = initialValues, action: MyCarsAction): MyCarsState {
  if (action.type === AppActionType.INIT) {
    const carOnboardingStep = window.localStorage.getItem(CAR_REGISTRATION_ONBOARDING);
    if (!carOnboardingStep) {
      window.localStorage.setItem(CAR_REGISTRATION_ONBOARDING, CarRegistrationOnboarding.WELCOME);

      return {
        ...state,
        carOnboardingStep: CarRegistrationOnboarding.WELCOME,
        hasSeenCarOnboarding: false,
      };
    }

    if (carOnboardingStep === CarRegistrationOnboarding.START_REGISTRATION) {
      return {
        ...state,
        carOnboardingStep: CarRegistrationOnboarding.START_REGISTRATION,
        hasSeenCarOnboarding: true,
      };
    }

    return {
      ...state,
      carOnboardingStep: carOnboardingStep as CarRegistrationOnboarding,
      hasSeenCarOnboarding: false,
    };
  }

  if (action.type === MyCarsActionType.SET_ONBOARDING) {
    window.localStorage.setItem(CAR_REGISTRATION_ONBOARDING, action.payload);

    return {
      ...state,
      carOnboardingStep: action.payload,
      hasSeenCarOnboarding: action.payload === CarRegistrationOnboarding.START_REGISTRATION,
    };
  }

  if (action.type === CarRegistrationActionType.EXIT) {
    const nextState = { ...state };
    let found = false;
    nextState.data = {
      ...state.data,
      items: state.data.items.map(it => {
        if (it.hash === action.payload.hash) {
          found = true;
          return action.payload;
        }
        return it;
      }),
    };
    if (!found) {
      nextState.data.items.unshift(action.payload);
      nextState.data.total_items += 1;
    }
    if (state.selected === action.payload.hash) {
      nextState.detail = action.payload;
    }
    return nextState;
  }

  if (action.type === MyCarsActionType.FETCH_MY_VEHICLES_BEGIN) {
    return {
      ...initialValues,
      carOnboardingStep: state.carOnboardingStep,
      hasSeenCarOnboarding: state.hasSeenCarOnboarding,
      loading: true,
    };
  }

  if (action.type === MyCarsActionType.FETCH_MY_VEHICLES_SUCCESS) {
    const selected = action.payload.items[0] ?? null;
    return {
      ...state,
      error: null,
      loading: false,
      data: action.payload,
      selected: selected?.hash,
      loadingDetail: true,
    };
  }

  if (action.type === MyCarsActionType.FETCH_MY_VEHICLES_ERROR) {
    return {
      ...state,
      error: action.payload,
      loading: false,
      data: null,
    };
  }

  if (action.type === MyCarsActionType.SELECT) {
    const nextState = {
      ...state,
      selected: action.payload.hash,
      detail: action.payload,
      userBenefits: null,
      loadingDetail: true,
    };

    if (state.carToRemove) {
      nextState.data.items = nextState.data.items.filter(it => it.hash !== state.carToRemove);
      nextState.data.total_items -= 1;
      nextState.carToRemove = undefined;
    }

    return nextState;
  }

  if (action.type === MyCarsActionType.UPDATE_SELECTED) {
    const nextState = { ...state };
    nextState.data.items = nextState.data.items.map(it => {
      if (it.hash === state.selected) {
        return merge({}, it, action.payload);
      }
      return it;
    });

    return nextState;
  }

  if (action.type === MyCarsActionType.OPEN_DETAIL) {
    const nextState = {
      ...state,
      selected: action.payload.detail.hash,
      detail: action.payload.detail,
      defaultPrices: action.payload.defaultPrices,
      userBenefits: action.payload.userBenefits,
      loadingDetail: false,
    };

    if (state.carToRemove) {
      nextState.data.items = nextState.data.items.filter(it => it.hash !== state.carToRemove);
      nextState.data.total_items -= 1;
      nextState.carToRemove = undefined;
    }

    return nextState;
  }

  if (action.type === MyCarsActionType.UPDATE_DETAIL) {
    return {
      ...state,
      detail: merge({}, state.detail, action.payload),
    };
  }

  if (action.type === MyCarsActionType.ADD_PHOTO) {
    let documents = [...state.detail.documents];
    if (action.payload.type !== DocumentType.PHOTO && documents.some(it => it.type === action.payload.type)) {
      documents = documents.map(it => (it.type === action.payload.type ? action.payload : it));
    } else {
      documents.push(action.payload);
    }
    return { ...state, detail: { ...state.detail, documents } };
  }

  if (action.type === MyCarsActionType.REMOVE_PHOTO) {
    const documents = state.detail.documents.filter(it => it.hash !== action.payload);
    return { ...state, detail: { ...state.detail, documents } };
  }

  if (action.type === MyCarsActionType.REMOVE) {
    return { ...state, carToRemove: action.payload };
  }

  return state;
}

export function loadOnboarding(): ActionReturnType {
  const onboardingStep = window.localStorage.getItem(CAR_REGISTRATION_ONBOARDING);

  return {
    type: MyCarsActionType.SET_ONBOARDING,
    payload: onboardingStep || CarRegistrationOnboarding.WELCOME,
  };
}

export function updateOnboarding(carOnboarding: CarRegistrationOnboarding): ActionReturnType {
  window.localStorage.setItem(CAR_REGISTRATION_ONBOARDING, carOnboarding);

  return {
    type: MyCarsActionType.SET_ONBOARDING,
    payload: carOnboarding,
  };
}

export function openMyVehicleDetail(hash?: string, force = false): ThunkAction<ReduxState> {
  return async (dispatch, getState) => {
    const firstState = getState().myCars;
    if (hash === firstState.detail?.hash && !force) {
      return;
    }
    if (!firstState.data || firstState.loading) {
      await dispatch(loadMyVehicles());
    }

    const state = getState().myCars;
    if (!state.data?.total_items) {
      return;
    }

    const { carToRemove } = state;
    let selectedCar = state.data.items.find(car => car.hash === hash);
    if (!selectedCar) {
      selectedCar = state.data.items[0];
    }
    if (carToRemove && selectedCar.hash === carToRemove) {
      selectedCar = state.data.items.find(it => it.hash !== carToRemove);
    }
    dispatch({ type: MyCarsActionType.SELECT, payload: selectedCar });
    try {
      const detail = await fetchMyVehicleDetail(selectedCar.hash);
      const defaultPrices = await fetchVehicleDefaultPrices(selectedCar.hash);
      const userBenefits = await fetchMyVehicleUserBenefits(selectedCar.hash);
      dispatch({
        type: MyCarsActionType.OPEN_DETAIL,
        payload: { detail, defaultPrices, userBenefits },
      });
    } catch (error) {
      dispatch(flashMessageError(error.message));
    }
  };
}

let promise: () => Promise<void>;

export function loadMyVehicles(): ThunkAction<ReduxState> {
  return async dispatch => {
    if (!promise) {
      promise = async () => {
        dispatch({ type: MyCarsActionType.FETCH_MY_VEHICLES_BEGIN });
        try {
          const response = await fetchMyVehicles();
          dispatch({
            type: MyCarsActionType.FETCH_MY_VEHICLES_SUCCESS,
            payload: response,
          });
        } catch (err) {
          dispatch({
            type: MyCarsActionType.FETCH_MY_VEHICLES_ERROR,
            payload: err.message,
          });
        }
      };
    }

    return await promise().then(() => {
      promise = null;
    });
  };
}

export function removeMyVehicle(hash: string, reason: string): ThunkAction<ReduxState, Promise<string>> {
  return async (dispatch, getState) => {
    await deleteVehicle(hash, reason);
    const { data } = getState().myCars;
    let nextSelected: string;
    if (data.total_items - 1 > 0) {
      nextSelected = data.items.find(it => it.hash !== hash)?.hash;
    }
    dispatch({ type: MyCarsActionType.REMOVE, payload: hash });
    return nextSelected;
  };
}

export function confirmTripRequest(hash: string): ThunkAction<ReduxState> {
  return async (dispatch, getState) => {
    const { selected, data } = getState().myCars;
    const selectedCar = data.items.find(it => it.hash === selected);
    await confirmTrip(hash);
    dispatch({
      type: MyCarsActionType.UPDATE_DETAIL,
      payload: {
        incoming_requests: selectedCar.incoming_requests - 1,
        confirmed_reservations: selectedCar.confirmed_reservations + 1,
      } as Partial<MyVehicleDetail>,
    });
  };
}

export function declineTripRequest(hash: string): ThunkAction<ReduxState> {
  return async (dispatch, getState) => {
    const { selected, data } = getState().myCars;
    const selectedCar = data.items.find(it => it.hash === selected);
    await declineTrip(hash);
    dispatch({
      type: MyCarsActionType.UPDATE_DETAIL,
      payload: {
        incoming_requests: selectedCar.incoming_requests - 1,
      } as Partial<MyVehicleDetail>,
    });
  };
}

export function updateMyVehicleCalendar(hash: string, calendar: VehicleCalendar): ThunkAction<ReduxState> {
  return async dispatch => {
    const response = await updateVehicleCalendar(hash, calendar);
    const payload: DeepPartial<MyVehicleDetail> = {
      calendar: response,
    };
    dispatch({ type: MyCarsActionType.UPDATE_DETAIL, payload });
  };
}

export function updateMyVehiclePricing(
  price: number,
  price_per_km: number,
  flexibleDiscounts: MyVehicleDiscount[],
): ThunkAction<ReduxState> {
  return async (dispatch, getState) => {
    const { selected } = getState().myCars;
    const price_list = await updateVehiclePrices(selected, price, price_per_km, flexibleDiscounts);
    const payload: DeepPartial<MyVehicleDetail> = {
      prices: {
        price_per_km: { amount: price_per_km },
        price_list,
      },
    };
    dispatch({ type: MyCarsActionType.UPDATE_DETAIL, payload });
  };
}

export function uploadMyVehiclePhoto<T extends DocumentType>(type: T, dataURI: string): ThunkAction<ReduxState> {
  return async (dispatch, getState) => {
    const { selected } = getState().myCars;
    const document = await uploadVehicleDocument(selected, type, dataURI);
    dispatch({ type: MyCarsActionType.ADD_PHOTO, payload: document });
    return document;
  };
}

export function removeMyVehiclePhoto(hash: string): ThunkAction<ReduxState> {
  return async (dispatch, getState) => {
    await removeVehicleDocument(getState().myCars.selected, hash);
    dispatch({ type: MyCarsActionType.REMOVE_PHOTO, payload: hash });
  };
}

export function updateMyVehicleParking(location: VehicleParking): ThunkAction<ReduxState> {
  return async (dispatch, getState) => {
    await updateVehicleLocation(getState().myCars.selected, location);
    const payload: DeepPartial<MyVehicleDetail> = {
      locations: [location],
    };
    dispatch({ type: MyCarsActionType.UPDATE_DETAIL, payload });
  };
}

export function updateMyVehicleAccount(bankAccount: BankAccount): ThunkAction<ReduxState> {
  return async (dispatch, getState) => {
    await updateVehicleBankAccount(getState().myCars.selected, bankAccount);
    const payload: DeepPartial<MyVehicleDetail> = {
      attributes: { ...bankAccount },
    };
    dispatch({ type: MyCarsActionType.UPDATE_DETAIL, payload });
  };
}

export function updateMyVehicleAttributes(attributes: Partial<VehicleAttributes>): ThunkAction<ReduxState> {
  return async (dispatch, getState) => {
    await updateVehicleAttributes(getState().myCars.selected, attributes);
    const payload: DeepPartial<MyVehicleDetail> = {
      attributes,
    };
    dispatch({ type: MyCarsActionType.UPDATE_DETAIL, payload });
  };
}

export function activateMyVehicle(hash: string): ThunkAction<ReduxState> {
  return async dispatch => {
    const vehicleDetail = await activateVehicle(hash);
    const payload: DeepPartial<MyVehicleDetail> = {
      state: vehicleDetail.state,
    };
    dispatch({ type: MyCarsActionType.UPDATE_DETAIL, payload });
  };
}

export function deactivateMyVehicle(hash: string, text: string): ThunkAction<ReduxState> {
  return async dispatch => {
    const vehicleDetail = await deactivateVehicle(hash, text);
    const payload: DeepPartial<MyVehicleDetail> = {
      state: vehicleDetail.state,
    };
    dispatch({ type: MyCarsActionType.UPDATE_DETAIL, payload });
  };
}
