import { addYears } from 'date-fns';

import { CookieKeys, getCookie, setCookie, setLanguageCookie } from './Cookies';
import { LocalStorage } from './Storage';

export class Migration {
  private static storage: LocalStorage<number>;
  private static migrations: Array<() => void> = [migrateLanguageSetting, migrateRegionSetting, migrateLanguageCookie];

  public static run(): void {
    if (typeof window === 'undefined') return;

    this.storage = new LocalStorage<number>('hoppygo_migrations', 0);
    this.storage.load();
    let i = this.storage.value;
    if (i >= this.migrations.length) return;
    for (; i < this.migrations.length; i++) {
      const migration = this.migrations[i];
      migration();
    }
    this.storage.value = i;
    this.storage.save();
  }
}

function migrateLanguageSetting() {
  const oldKey = 'gatsby-i18next-language';
  const oldValue = window.localStorage.getItem(oldKey);
  if (!oldValue) return;
  const newKey = CookieKeys.LANGUAGE;
  window.localStorage.setItem(newKey, oldValue);
  setCookie(newKey, oldValue, { expires: addYears(new Date(), 1) });
  window.localStorage.removeItem(oldKey);
}

function migrateRegionSetting() {
  const oldKey = 'hoppygo_region';
  const oldValue = window.localStorage.getItem(oldKey);
  if (!oldValue) return;
  setCookie(CookieKeys.REGION, oldValue, { expires: addYears(new Date(), 1) });
  window.localStorage.removeItem(oldKey);
}

function migrateLanguageCookie() {
  const value = getCookie(CookieKeys.LANGUAGE);
  if (!value) return;
  setLanguageCookie(value);
}
