import { PrivateApiV3 } from '../services/Api';

import { DocumentType } from './Document';
import { type User, UserState } from './User';

export interface UserVerification {
  confirmed: boolean;
  basic_info: boolean;
  email: boolean;
  phone: boolean;
  identity_card_front: boolean;
  identity_card_back: boolean;
  driving_license_front: boolean;
  driving_license_back: boolean;
}

export function isUserAvatarUploaded(user: User): boolean {
  return !!user.avatar_document;
}

export function isUserGeneralInfoFilled(user: User): boolean {
  return user.verification.basic_info;
}

export function isUserCompanyFilled(user: User): boolean {
  return !!user.company;
}

export function isUserIDCardVerified({ verification, id_card }: User): boolean {
  return (
    verification.identity_card_front &&
    verification.identity_card_back &&
    id_card !== null &&
    id_card.expiration_date !== null &&
    id_card.identification_number !== null
  );
}

export function isUserDLCardVerified({ verification, driving_license }: User): boolean {
  return (
    verification.driving_license_front &&
    verification.driving_license_back &&
    driving_license !== null &&
    driving_license.expiration_date !== null &&
    driving_license.identification_number !== null
  );
}

export function isUserIDCardUploaded(user: User): boolean {
  return (
    user.id_card?.documents?.filter(
      d =>
        d.state !== 'deleted' && [DocumentType.ID_FRONT, DocumentType.ID_BACK, DocumentType.ID_SELFIE].includes(d.type),
    ).length === 3
  );
}

export function isUserDLCardUploaded(user: User): boolean {
  return (
    user.driving_license?.documents?.filter(
      d => d.state !== 'deleted' && [DocumentType.DL_FRONT, DocumentType.DL_BACK].includes(d.type),
    ).length >= 2
  );
}

export function isUserIDCardInfoFilled(user: User): boolean {
  return user.id_card?.expiration_date !== null && user.id_card?.identification_number !== null;
}

export function isUserDLCardInfoFilled(user: User): boolean {
  return user.driving_license?.has_expiration_date === false || user.driving_license?.expiration_date !== null;
}

export function isUserEmailVerified(user: User): boolean {
  return user.verification.email;
}

export function isUserPhoneVerified(user: User): boolean {
  return user.verification.phone;
}

const profilePartIsFilledCheckFunctions = [
  isUserGeneralInfoFilled,
  isUserIDCardUploaded,
  isUserDLCardUploaded,
  isUserIDCardInfoFilled,
  isUserDLCardInfoFilled,
  isUserEmailVerified,
  isUserPhoneVerified,
];

export function isUserProfileFilled(user: User): boolean {
  if (user.state === UserState.ACTIVE) {
    return true;
  }
  for (const check of profilePartIsFilledCheckFunctions) {
    if (!check(user)) {
      return false;
    }
  }
  return true;
}

export function isUserProfileWaitingForVerification(user: User): boolean {
  return isUserProfileFilled(user) && !user.verification.confirmed;
}

export async function requestEmailVerification() {
  await PrivateApiV3.put('/user/verify_email');
}

export async function requestPhoneVerification() {
  await PrivateApiV3.put('/user/verify_phone');
}

export async function sendPhoneVerificationCode(code: string) {
  await PrivateApiV3.get(`/user/verify_phone/${code}`);
}
