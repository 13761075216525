import { PrivateApiV3 } from '../services/Api';
import { TripGuard } from '../services/ApiGuard';
import { isSameOrBefore } from '../services/DateTime';
import type { Page } from '../services/Paging';

import type { Document } from './Document';
import type { OrderQuery } from './Order';
import { isTripInsurance, type SelectedOrderProducts, type TripInsurance } from './OrderProduct';
import type { PriceType } from './Price';
import type { Rating } from './Ratings';
import type { TripPricing } from './TripPricing';
import type { TripPricingBreakdown } from './TripPricingBreakdown';
import type { Driver, Owner, User } from './User';
import type { Vehicle } from './Vehicle';

export enum TripState {
  DRAFT = 'reservation_draft',
  REQUEST = 'reservation_request',
  WAITING_FOR_HANDOVER = 'waiting_for_handover',
  CANCELLED_BY_SYSTEM = 'reservation_canceled_by_system',
  CANCELLED_DUE_PAYMENT = 'reservation_canceled_due_payment',
  CANCELLED = 'reservation_canceled',
  CANCELLED_IN_PROGRESS = 'trip_canceled',
  EXPIRED = 'reservation_expired',
  DECLINED = 'reservation_declined',
  HAND_IN = 'hand_in_in_progress', // owner -> driver
  IN_PROGRESS = 'in_progress',
  HAND_OUT = 'hand_out_in_progress', // driver -> owner
  WAITING_FOR_COMPLETION = 'waiting_for_completion',
  FINISHED = 'finished',
}

export enum TripsGroup {
  PLANNED = 'planned',
  CONFIRMED = 'confirmed',
  IN_PROGRESS = 'in_progress',
  REQUESTED = 'requested',
  FINISHED = 'finished',
  CANCELED = 'canceled',
}

export enum TripsRole {
  DRIVER = 'driver',
  OWNER = 'owner',
}

export enum TripType {
  BUSINESS = 'business',
  PRIVATE = 'private',
}

export interface HandoverStatus {
  documents: Document[];
  fuel_level: number;
  fuel_level_confirmed_by_driver: boolean;
  fuel_level_confirmed_by_owner: boolean;
  is_verification_number_verified: boolean;
  mileage: number;
  mileage_confirmed_by_driver: boolean;
  mileage_confirmed_by_owner: boolean;
  note: string;
}

export interface Trip<V = Vehicle> {
  hash: string;
  chat_id: string;
  state: TripState;
  state_name: string;
  type: TripType;
  date_from: string;
  date_to: string;
  created: string;
  confirm_to: string;
  handover_from: string;
  trip_length: number;
  total_mileage: number;

  vehicle: V;
  owner: Owner;
  driver: Driver;
  insurances: TripInsurance[];
  active_handover?: HandoverStatus;

  request_group: number;
  abort_message: string;
  aborted_by_user: 'driver' | 'owner' | 'admin';
  free_cancellation_time: string | null;

  paid: boolean;
  penalty: boolean;
  method_token: string;
  price_to_pay: PriceType;
  is_prolong_to_be_paid: boolean;

  price: PriceType;
  price_total: PriceType;
  price_per_day: PriceType;
  price_per_day_with_insurance: PriceType;
  trip_price_without_discount: PriceType;
  long_term_discount: PriceType;
  percentage_long_term_discount: number;
  pricing: TripPricing;
  pricing_breakdown: TripPricingBreakdown;
  voucher_used: boolean;

  user_rating_by_driver: Rating;
  user_rating_by_owner: Rating;
  vehicle_rating_by_driver: Rating;
  user_rating_by_driver_skipped: boolean;
  user_rating_by_owner_skipped: boolean;
  vehicle_rating_by_driver_skipped: boolean;
  rating_skippable: boolean;
}

export interface TripInHandover<V = Vehicle> extends Trip<V> {
  active_handover: HandoverStatus;
}

export interface TripFinished<V = Vehicle> extends TripInHandover<V> {
  start_mileage: number;
  end_mileage: number;
  start_fuel_level: number;
  end_fuel_level: number;
  total_mileage: number;
  total_length_in_sec: number;
}

export const isOwner = (user: User, trip: Trip) => user.hash === trip.owner.hash;

export const isDriver = (user: User, trip: Trip) => user.hash === trip.driver.hash;

export const isDriverAndHasRated = (user: User, trip: Trip) =>
  isDriver(user, trip) && (trip.vehicle_rating_by_driver || trip.vehicle_rating_by_driver_skipped);

export const isOwnerAndHasRated = (user: User, trip: Trip) =>
  isOwner(user, trip) && (trip.user_rating_by_owner || trip.user_rating_by_owner_skipped);

export function canUserRateTrip(user: User, trip: Trip): boolean {
  return (
    [TripState.HAND_OUT, TripState.WAITING_FOR_COMPLETION, TripState.FINISHED].includes(trip.state) &&
    ((isOwner(user, trip) && !(trip.user_rating_by_owner || trip.user_rating_by_owner_skipped)) ||
      (isDriver(user, trip) && !(trip.vehicle_rating_by_driver || trip.vehicle_rating_by_driver_skipped)))
  );
}

export const isTripCancelled = (trip: Trip): boolean =>
  [
    TripState.DECLINED,
    TripState.EXPIRED,
    TripState.CANCELLED,
    TripState.CANCELLED_BY_SYSTEM,
    TripState.CANCELLED_DUE_PAYMENT,
    TripState.CANCELLED_IN_PROGRESS,
  ].includes(trip.state);

export const isTripRequest = (trip: Trip): boolean => trip.state === TripState.REQUEST;

export const isTripWaitingForHandover = (trip: Trip): boolean =>
  [TripState.WAITING_FOR_HANDOVER, TripState.HAND_IN].includes(trip.state);

export const isTripWaitingForReturn = (trip: Trip | TripInHandover): trip is TripInHandover =>
  [TripState.IN_PROGRESS, TripState.HAND_OUT].includes(trip.state);

export const isTripHandoverInProgress = (trip: Trip | TripInHandover): trip is TripInHandover =>
  [TripState.HAND_IN, TripState.HAND_OUT].includes(trip.state) && !!trip.active_handover;

export const hasTripPenalty = (trip: Trip): boolean => trip.penalty;

export const isTripPaid = (trip: Trip): boolean => trip.paid;

export const isTripFinished = (trip: Trip | TripInHandover | TripFinished): trip is TripFinished =>
  [TripState.WAITING_FOR_COMPLETION, TripState.FINISHED].includes(trip.state);

export const isTripInstantBooking = (trip: Trip): boolean => trip.vehicle.instant_booking;

export const isTripHandoverWaitingForConfirmation = (trip: Trip | TripInHandover): trip is TripInHandover => {
  if (!isTripHandoverInProgress(trip)) return false;
  return !isHandoverConfirmedByDriver(trip.active_handover) || !isHandoverConfirmedByOwner(trip.active_handover);
};

export const isHandoverConfirmedByDriver = (handover: HandoverStatus) => {
  return handover.fuel_level_confirmed_by_driver && handover.mileage_confirmed_by_driver;
};

export const isHandoverConfirmedByOwner = (handover: HandoverStatus) => {
  return (
    handover.is_verification_number_verified &&
    handover.fuel_level_confirmed_by_owner &&
    handover.mileage_confirmed_by_owner
  );
};

export const isTripReadyForHandover = (trip: Trip): boolean => isSameOrBefore(trip.handover_from, new Date());

export async function fetchTrips(role: TripsRole, group: TripsGroup, vehicleHash = undefined, page?: number) {
  const response = await PrivateApiV3.get<Page<Trip>>('/trips', {
    vehicleHashCode: vehicleHash,
    role,
    group,
    page,
  });
  return response.data;
}

export async function fetchTrip(hash: string) {
  const response = await PrivateApiV3.get<Trip>(`/trips/${hash}`, {}, { catchAllMessages: true });
  return response.data;
}

export interface TripPricingData {
  selectedMandatoryInsurance: number;
  selectedProducts: SelectedOrderProducts;
  useReward: boolean;
  saleCode: string;
}

interface TripAdditionalServiceState {
  id: number;
  data?: UnsafeAny;
}

export async function fetchTripPricing(hash: string, query: OrderQuery, order: TripPricingData) {
  const insurances: number[] = [order.selectedMandatoryInsurance];
  const additionalServices: TripAdditionalServiceState[] = [];
  for (const it of Object.values(order.selectedProducts)) {
    if (isTripInsurance(it)) {
      insurances.push(it.id);
    } else {
      additionalServices.push({ id: it.id, data: it.data });
    }
  }
  const response = await PrivateApiV3.post<Trip>('/trips/pricing', {
    hash_code: hash,
    date_from: query.date_from,
    date_to: query.date_to,
    insurance_products: insurances,
    additional_services: additionalServices,
    payment_method_nonce: null,
    init_message: '',
    use_reward: order.useReward,
    sale_code: order.saleCode,
  });
  response.findMessageNotIn(TripGuard.USER_HAS_NO_VALID_PAYMENT_METHOD);
  if (response.message) {
    throw response;
  }
  return response.data;
}

export interface TripCreationData extends TripPricingData {
  nonce: string;
  deviceData?: unknown;
  message: string;
}

export async function createTrip(hash: string, query: OrderQuery, data: TripCreationData) {
  const insurances: number[] = [data.selectedMandatoryInsurance];
  const additionalServices: TripAdditionalServiceState[] = [];
  for (const it of Object.values(data.selectedProducts)) {
    if (isTripInsurance(it)) {
      insurances.push(it.id);
    } else {
      additionalServices.push({ id: it.id, data: it.data });
    }
  }
  const response = await PrivateApiV3.post<Trip>(
    '/trips',
    {
      hash_code: hash,
      date_from: query.date_from,
      date_to: query.date_to,
      insurance_products: insurances,
      additional_services: additionalServices,
      payment_method_nonce: data.nonce,
      device_data: data.deviceData,
      init_message: data.message,
      use_reward: data.useReward,
      sale_code: data.saleCode,
    },
    {},
    { catchAllMessages: true },
  );
  return response.data;
}

export async function declineTrip(hash: string, decline_message = '') {
  const response = await PrivateApiV3.post<Trip>(
    `/trips/${hash}/decline`,
    { decline_message },
    {},
    { catchAllMessages: true },
  );
  return response.data;
}

export async function cancelTrip(hash: string, cancel_message: string) {
  const response = await PrivateApiV3.post<Trip>(
    `/trips/${hash}/cancel`,
    { cancel_message },
    {},
    { catchAllMessages: true },
  );
  return response.data;
}

export async function confirmTrip(hash_code: string) {
  await PrivateApiV3.post(`/trips/${hash_code}/confirm`);
}

export async function payTrip(hash: string, payment_method_nonce: string, device_data?: string) {
  await PrivateApiV3.post(`/trips/${hash}/pay`, {
    payment_method_nonce,
    device_data,
  });
}
