import { trim } from 'lodash';
import qs from 'query-string';
import slug from 'slug';

import { defaultLanguage, type Language } from './services/Language';

/**
 * Password must be at least 8 chars long, contain 1 number,
 * 1 uppercase letter and 1 special char
 * @param str {string}
 * @returns {boolean}
 */
export const isPasswordValid = (str: string): boolean => {
  if (str.length < 8) {
    return false;
  }
  if (!/[0-9]/.test(str)) {
    return false;
  }
  if (!/[A-Z]/.test(str)) {
    return false;
  }
  return /[^a-zA-Z\d\s:]/.test(str);
};

export function webalize(str: string): string {
  return slug(str, { remove: /[0-9]/g });
}

export function getSearchQueryParameter(searchQuery: string, parameter: string): string | number | boolean | string[] {
  const params = qs.parse(searchQuery);
  return parameter in params ? params[parameter] : null;
}

export function pad(s) {
  return s < 10 ? `0${s}` : s;
}

export function scrollParentIntoView(element, parent, offset = 0) {
  const parentRect = parent.getBoundingClientRect();
  const childRect = element.getBoundingClientRect();

  const isViewable = childRect.top >= parentRect.top && childRect.top <= parentRect.top + parent.clientHeight;

  if (!isViewable) {
    parent.scrollTop = childRect.top + parent.scrollTop - offset - parentRect.top;
  }
}

export const range = <V = number>(from: number, to: number, initialValue?: V): V[] => {
  return new Array(to - from).fill(0).map((_, i) => (initialValue !== undefined ? initialValue : from + i)) as V[];
};

export function toArray<T>(value: T | T[]): T[] {
  if (Array.isArray(value)) return value;
  return [value];
}

export function camelcaseKeys<T>(object: T): T extends object ? Record<string, unknown> : T;
export function camelcaseKeys(object: unknown) {
  if (object === undefined || object === null || typeof object !== 'object') return object;
  const result = {};
  for (const [key, value] of Object.entries(object)) {
    const camelcaseKey = (key as string).replace(/(\w)_(\w)/g, (_, g1, g2) => {
      return `${g1}${g2.toUpperCase()}`;
    });
    if (!Array.isArray(value) && typeof value !== 'function' && typeof value === 'object') {
      result[camelcaseKey] = camelcaseKeys(value);
    } else {
      result[camelcaseKey] = value;
    }
  }
  return result;
}

export function formatNumber(
  value: number,
  lang: Language = defaultLanguage,
  fraction?: number | [number, number],
): string {
  const options: Intl.NumberFormatOptions = {
    style: 'decimal',
  };
  if (typeof fraction === 'number') {
    options.minimumFractionDigits = fraction;
    options.maximumFractionDigits = fraction;
  }
  if (Array.isArray(fraction)) {
    options.minimumFractionDigits = fraction[0];
    options.maximumFractionDigits = fraction[1];
  }
  return value?.toLocaleString(lang, options);
}

export function hasMoreFractions(value: number, fractions = 0): boolean {
  const power = fractions - 1 ? fractions - 1 : 0;
  return (value * Math.pow(10, power)) % Math.pow(10, power) !== 0;
}

export function getNumberFractions(value: number, max = 10): number {
  let count = 0;
  while (!hasMoreFractions(value * 10 ** count, max) && isFinite(10 ** count)) {
    count++;
  }
  return count;
}

export function roundToFractions(value: number, fractions = 0): number {
  const power = fractions ? fractions : 0;
  return Math.round(value * Math.pow(10, power)) / Math.pow(10, power);
}

export function floorToFractions(value: number, fractions = 0): number {
  const power = fractions ? fractions : 0;
  return Math.floor(value * Math.pow(10, power)) / Math.pow(10, power);
}

export function stripTrailingSlash(url: string): string {
  return url.replace(/\/$/, '');
}

export const isBrowser = typeof window !== 'undefined';

export function normalizeString(str: string): string {
  if (!str) {
    return '';
  }
  const diacriticsRegex = /[\u0300-\u036F]/g;
  const spacesRegex = /\s+/g;
  const nonAlphanumericRegex = /[^a-z0-9-]/g;
  return str
    .toLowerCase()
    .normalize('NFKD')
    .replace(diacriticsRegex, '') // Remove diacritics
    .replace(spacesRegex, '-') // Replace spaces with dashes
    .replace(nonAlphanumericRegex, '') // Remove non-alphanumeric characters except dashes
    .trim();
}
