import merge from 'lodash/merge';

import {
  type Card,
  CardType,
  DocumentType,
  updateUserCard,
  uploadUserCard,
  uploadUserDocument,
  type UserCardType,
} from '../model/Document';
import { fetchUser, updateUser, type User, type UserWithoutConsents } from '../model/User';
import { updateUserProfile, type UserData } from '../model/UserData';
import { UserRegistrationActionType } from '../modules/UserRegistration/SumsubUserRegistration';
import { UserGuard } from '../services/ApiGuard';
import { AppState } from '../services/AppState';
import { SentryLogAdapter } from '../services/Sentry';
import Tracking from '../services/Tracking';

import { loadMyVehicles } from './cars';
import { loadFavoriteVehicles } from './favoriteVehicles';
import type { Action, ReduxState } from './index';
import { initFilters } from './rentCar';
import { SessionActionType } from './session';

export enum UserActionType {
  SET_USER = 'user/USER_SET_USER',
  DESTROY_USER = 'user/DESTROY_USER',
  UPDATE_USER = 'user/UPDATE_USER',
}

export type UserState = User | UserWithoutConsents | null;

const initialState: UserState = null;

type UserAction =
  | Action<SessionActionType.AUTHORIZE, { user: User }>
  | Action<SessionActionType.CLEAR_SESSION>
  | Action<UserRegistrationActionType.EXIT, { shouldDestroyUser: boolean }>
  | Action<UserActionType.UPDATE_USER, Partial<UserState>>
  | Action<UserRegistrationActionType.COMPLETE_PHONE, [string, string]>;

export default function userStore(state = initialState, action: UserAction): UserState {
  if (action.type === SessionActionType.AUTHORIZE) {
    return action.payload.user;
  }

  if (action.type === SessionActionType.CLEAR_SESSION) {
    return null;
  }

  if (action.type === UserRegistrationActionType.EXIT && action.payload.shouldDestroyUser) {
    return null;
  }

  if (action.type === UserActionType.UPDATE_USER) {
    return merge({}, state, action.payload);
  }

  if (action.type === UserRegistrationActionType.COMPLETE_PHONE) {
    return {
      ...state,
      data: {
        ...state.data,
        phone: action.payload.join(''),
      },
    };
  }

  return state;
}

export function updateUserStore(payload: DeepPartial<UserState>): ActionReturnType {
  return { type: UserActionType.UPDATE_USER, payload };
}

const onUserSet: ThunkAction<ReduxState> = async (dispatch, getState) => {
  dispatch(loadMyVehicles());
  dispatch(loadFavoriteVehicles());
  const {
    app: { state: appState, language },
    user,
  } = getState();
  if (appState !== AppState.INITIAL) {
    await dispatch(initFilters(language, user.country.alpha2_code));
  }
};

// @deprecated use loadSession instead
export function loadUser(): ThunkAction<ReduxState> {
  return async dispatch => {
    const response = await fetchUser();
    SentryLogAdapter.setUser(response.data);
    if (response.hasMessage(UserGuard.MISSING_USER_COUNTRY, UserGuard.MISSING_USER_CONSENTS)) {
      return;
    }
    Tracking.login(response.data);
    await dispatch(onUserSet);
  };
}

export function setUserAvatar(avatar: string): ThunkAction<ReduxState> {
  return async dispatch => {
    const document = await uploadUserDocument(DocumentType.AVATAR, avatar);
    dispatch(updateUserStore({ avatar_document: document }));
  };
}

export function setUserProfile(profile: Partial<UserData>): ThunkAction<ReduxState> {
  return async dispatch => {
    const response = await updateUserProfile(profile);
    dispatch(updateUserStore({ data: response.data }));
  };
}

export function setUserEmailVerified(): ThunkAction<ReduxState> {
  return dispatch => {
    dispatch(
      updateUserStore({
        data: { verifications: { email: true } },
        verification: { email: true },
      }),
    );
  };
}

export const setUserPersonalIDNumber =
  (pin: string): ThunkAction<ReduxState> =>
  async (dispatch, getState) => {
    const response = await updateUserProfile({ birth_number: pin });
    dispatch(
      updateUserStore({
        data: response.data,
        verification: { basic_info: true },
      }),
    );
    return getState().user;
  };

export const setUserCardSide =
  (cardType: UserCardType, type: DocumentType, card: string): ThunkAction<ReduxState> =>
  async dispatch => {
    const document = await uploadUserCard(cardType, type, card);
    const payload: DeepPartial<User> = {};
    switch (cardType) {
      case CardType.IDENTIFICATION_CARD:
        payload.id_card = document;
        break;
      case CardType.DRIVING_LICENSE:
        payload.driving_license = document;
        break;
    }
    dispatch(updateUserStore(payload));
  };

export function setUserCardData(cardType: UserCardType, data: Partial<Card<UserCardType>>): ThunkAction<ReduxState> {
  return async dispatch => {
    await updateUserCard(cardType, data);
    const payload: DeepPartial<User> = {};
    switch (cardType) {
      case CardType.IDENTIFICATION_CARD:
        payload.id_card = data;
        break;
      case CardType.DRIVING_LICENSE:
        payload.driving_license = data;
        break;
    }
    dispatch(updateUserStore(payload));
  };
}

export function updateDrivingLicenseNumber(number: string): ThunkAction<ReduxState> {
  return async dispatch => {
    const driving_license = await updateUserCard(CardType.DRIVING_LICENSE, {
      identification_number: number,
    });
    dispatch(updateUserStore({ driving_license }));
  };
}

export const setUserInvitationCode =
  (invitation_code: string): ThunkAction<ReduxState> =>
  async dispatch => {
    const user = await updateUser({ invitation_code });
    dispatch(updateUserStore(user));
  };
