import { PublicApiV3 } from '../services/Api';

import type { SearchFiltersAPI } from './SearchFilters';
import type { Trip } from './Trip';
import type { VehicleDetail, VehicleDetailAPI } from './Vehicle';
import { parseCalendarResponse, type VehicleCalendarAPI } from './VehicleCalendar';
import { parseVehicleEquipmentResponse } from './VehicleDetailEquipment';

export type SearchDetailAPI = Trip<VehicleDetailAPI>;
export type SearchDetail = Trip<VehicleDetail>;

export async function fetchSearchDetail(hash: string, filters: SearchFiltersAPI) {
  const response = await PublicApiV3.post<SearchDetailAPI>(`/vehicles/search/${hash}`, filters);
  const calendar = parseCalendarResponse(response.data.vehicle.calendar as VehicleCalendarAPI);
  const detail = {
    ...response.data,
    vehicle: {
      ...response.data.vehicle,
      calendar,
      equipment: parseVehicleEquipmentResponse(response.data.vehicle.equipment),
    },
  } as SearchDetail;
  response.findAnyMessage();
  if (response.message) {
    // @ts-ignore
    response.data = detail;
    throw response;
  }
  return detail;
}
