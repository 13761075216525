import { PublicApiV3 } from '../services/Api';
import type { Country } from '../services/Country';
import { Logger } from '../services/Logger';

import type { Vehicle } from './Vehicle';

export type HomepageFeed = Vehicle[];
export const FEED_LIMIT = 5;

let currentPromise: Promise<HomepageFeed> = null;
let currentRegion: Country = null;

async function loadHomepageFeed(region: Country, campaign?: string): Promise<HomepageFeed> {
  try {
    const apiURL = campaign ? `/vehicles/homepage/cars-by-tag` : `/vehicles/homepage/featured_cars`;
    const response = await PublicApiV3.get<HomepageFeed>(apiURL, {
      country_code: region,
      tag: campaign,
    });
    return response.data.slice(0, FEED_LIMIT + 1);
  } catch (error) {
    Logger.default.warning(`Error loading homepage feed`, { error });
    return [];
  }
}

export async function fetchHomepageFeed(region: Country, campaign?: string): Promise<HomepageFeed> {
  if (!currentPromise || currentRegion !== region) {
    currentRegion = region;
    currentPromise = loadHomepageFeed(region, campaign);
  }
  return currentPromise;
}
