import { PrivateApiV3, PublicApiV3 } from '../services/Api';
import auth, { type Authorization } from '../services/Authorization';
import type { Country } from '../services/Country';

import type { Document, UserCard } from './Document';
import type { LocationType, UserLocation } from './Location';
import type { RatingItem } from './Ratings';
import type { UserCompany } from './UserCompany';
import type { UserData } from './UserData';
import type { UserReward } from './UserReward';
import { UserRole } from './UserRole';
import type { UserVerification } from './UserVerification';
import type { Vehicle } from './Vehicle';

export enum UserState {
  ACTIVE = 'active',
  ACTIVE_NO_CREDIT_CHECK = 'active_no_credit_check',
  ACTIVE_NO_DL = 'active_no_dl',
  DRAFT = 'draft',
  REGISTRATION_IN_PROGRESS = 'registration_in_progress',
  INACTIVE = 'inactive',
  WAITING_FOR_REVISION = 'waiting_for_revision',
  WAITING_FOR_REVISION_AUTOMATED = 'waiting_for_revision_automated',
  WAITING_FOR_REVISION_MANUAL = 'waiting_for_revision_manual',
  IN_REVISION = 'in_revision',
  BANNED = 'banned',
  DECLINED = 'declined',
  BLOCKED = 'blocked',
  DELETED = 'deleted',
  BLOCKED_PL = 'blocked_pl',
}

interface UserBase {
  hash: string;
  hash_code: string;
  email: string;
  full_name: string;
  short_name: string;
  created_at: string;
  active: number;
  state: UserState;
  rating_average_float: number;
  rating_average_int: number;
  rating_count: number;
  share_code: string;
  trips_as_user: number;
  trips_as_owner: number;
  accepted_trips: number;
  responsed_trips: number;
  all_trips_as_owner: number;
  invitation_code: string;
  acceptance_ratio: string;

  is_owner?: boolean;

  data: UserData;
  verification: UserVerification;
  avatar_document?: Document;
  id_card?: UserCard;
  driving_license?: UserCard;
  reward: UserReward;
  company: UserCompany | null;
  location?: UserLocation<LocationType.HOME>;
}

export interface UserWithoutConsents extends UserBase {
  country: { alpha2_code: Country; currency: string } | null;
  registration_role: UserRole | null;
}

export interface User extends UserBase {
  country: { alpha2_code: Country; currency: string };
  registration_role: UserRole;
}

export interface PublicUser {
  acceptance_ratio: string;
  accepted_trips: number;
  active: number;
  all_trips_as_owner: number;
  avatar_document?: Document;
  created_at: string;
  data: UserData;
  hash: string;
  hash_code: string;
  name: string;
  rating_average_float: number;
  rating_average_int: number;
  rating_count: number;
  rating_recent: RatingItem[];
  response_time: number;
  responsed_trips: number;
  share_code: string;
  trips_as_owner: number;
  trips_as_user: number;
  vehicles: Vehicle[];
  legal_entity: string;
}

interface TripUser {
  hash: string;
  first_name: string;
  last_name: string;
  avatar_document?: Document;
  phone: string;

  average_rating: number;
  rating_count: number;
}

export type Driver = TripUser;

export interface Owner extends TripUser {
  acceptance_ratio: string;
  response_time: number;
}

export const INVITATION_CODE = 'invitation_code';

export function canUserAddCar(user: User): boolean {
  return [UserState.WAITING_FOR_REVISION, UserState.IN_REVISION, UserState.ACTIVE].includes(user.state);
}

export function isUserInVerification(user: User): boolean {
  return [
    UserState.WAITING_FOR_REVISION,
    UserState.WAITING_FOR_REVISION_AUTOMATED,
    UserState.WAITING_FOR_REVISION_MANUAL,
    UserState.IN_REVISION,
  ].includes(user.state);
}

export function isUserVerified(user: User): boolean {
  return [UserState.ACTIVE].includes(user.state);
}

export function isUserActive(user: User): boolean {
  return [UserState.ACTIVE, UserState.ACTIVE_NO_CREDIT_CHECK, UserState.ACTIVE_NO_DL].includes(user.state);
}

export function getUserRole(user: User): UserRole.DRIVER | UserRole.OWNER {
  return user.is_owner ? UserRole.OWNER : UserRole.DRIVER;
}

export async function fetchUser() {
  return await PrivateApiV3.get<User>('/user');
}

interface UserUpdateData {
  email: string;
  country_code: string;
  invitation_code?: string;
}

export async function updateUser(data: Partial<UserUpdateData>) {
  auth.disabled = false;
  const response = await PrivateApiV3.patch<User>('/user', data);
  return response.data;
}

export async function updateUserEmail(email: string) {
  return await PrivateApiV3.patch<Authorization>('/user/email', { email });
}

export async function fetchPublicProfile(hash: string) {
  const response = await PublicApiV3.get<PublicUser>(`/user/${hash}/info`);
  return response.data;
}
