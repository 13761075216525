const config = {
  appleClientId: process.env.GATSBY_APPLE_CLIENT_ID,
  appleRedirectURI: process.env.GATSBY_APPLE_REDIRECT_URI,
  googleApiKey: process.env.GATSBY_GOOGLE_API_KEY,
  googleClientId: process.env.GATSBY_GOOGLE_CLIENT_ID,
  facebookAppId: process.env.GATSBY_FACEBOOK_APP_ID,
  mixpanelToken: process.env.GATSBY_MIXPANEL_TOKEN,
};

export const APP_VERSION = `web_v2-${process.env.GATSBY_VERSION}`;

export default config;
