import React from 'react';
import { Provider } from 'react-redux';
import type { WrapRootElementBrowserArgs } from 'gatsby';

import { CampaignProvider } from './CampaignProvider';
import createStore from './store';

export function wrapRootElement({ element }: WrapRootElementBrowserArgs) {
  const store = createStore({});

  return (
    <Provider store={store}>
      <CampaignProvider>{element}</CampaignProvider>
    </Provider>
  );
}
