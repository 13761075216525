import { formatNumber, getNumberFractions } from '../helpers';
import { Country } from '../services/Country';
import { Language } from '../services/Language';

import type { Trip } from './Trip';

export enum CurrencyCode {
  CZK = 'CZK',
  PLN = 'PLN',
  EUR = 'EUR',
}

const fractionCurrencies: Record<CurrencyCode, [number, number]> = {
  [CurrencyCode.CZK]: [0, 2],
  [CurrencyCode.PLN]: [2, 2],
  [CurrencyCode.EUR]: [2, 2],
};

const LanguageMapping = {
  [Language.CS]: {
    currency: CurrencyCode.CZK,
    currencyFilter: CurrencyCode.CZK,
  },
  [Language.PL]: {
    currency: CurrencyCode.CZK,
    currencyFilter: CurrencyCode.CZK,
  },
  [Language.SK]: {
    currency: CurrencyCode.EUR,
    currencyFilter: CurrencyCode.EUR,
  },
  [Language.EN]: {
    currency: CurrencyCode.CZK,
    currencyFilter: CurrencyCode.CZK,
  },
};

const CountryMapping = {
  [Country.CZ]: {
    currency: CurrencyCode.CZK,
    vehiclePricePerDayStep: 1,
    vehiclePricePerKmStep: 1,
  },
  [Country.SK]: {
    currency: CurrencyCode.EUR,
    vehiclePricePerDayStep: 1,
    vehiclePricePerKmStep: 0.05,
  },
};

export const languageToCurrencyFilter = (lang: string): CurrencyCode => {
  return LanguageMapping[lang]?.currencyFilter;
};

export const languageToCurrencyCode = (lang: string): CurrencyCode => {
  return LanguageMapping[lang]?.currency;
};

export function countryToCurrency(country: Country): CurrencyCode {
  return CountryMapping[country]?.currency;
}

export function countryToPricePerDayStep(country: Country): number {
  return CountryMapping[country].vehiclePricePerDayStep;
}

export function countryToPricePerKmStep(country: Country): number {
  return CountryMapping[country].vehiclePricePerKmStep;
}

export type PriceType = {
  amount: number;
  currency: CurrencyCode;
};

export function getCurrencyFractions(currency: CurrencyCode): [number, number] {
  return fractionCurrencies[currency];
}

export function formatPrice(
  price: PriceType,
  lang?: Language,
  currency?: CurrencyCode,
  fraction?: number | [number, number],
): string;
export function formatPrice(
  price: number,
  lang?: Language,
  currency?: CurrencyCode,
  fraction?: number | [number, number],
): string;
export function formatPrice(
  price: number | PriceType,
  lang: Language = Language.CS,
  currency: CurrencyCode = CurrencyCode.CZK,
  fraction?: number | [number, number],
): string {
  if (typeof price === 'object') {
    currency = price.currency;
    price = price.amount;
  }
  if (isNaN(price) || price === null) {
    price = 0;
  }

  const options: Intl.NumberFormatOptions = {
    style: 'currency',
    currency,
  };
  if (typeof fraction === 'number') {
    options.minimumFractionDigits = fraction;
    options.maximumFractionDigits = fraction;
  } else if (Array.isArray(fraction)) {
    options.minimumFractionDigits = fraction[0];
    options.maximumFractionDigits = fraction[1];
  } else {
    const [min, max] = getCurrencyFractions(currency);
    options.minimumFractionDigits = min;
    options.maximumFractionDigits = max;
  }
  const fractionsCount = getNumberFractions(price, 2);
  if (fractionsCount < 2 && options.maximumFractionDigits > 0) {
    options.minimumFractionDigits = 2;
    if (options.maximumFractionDigits < options.minimumFractionDigits) {
      options.maximumFractionDigits = 2;
    }
  }
  return price.toLocaleString(lang, options);
}

export const getLowestVehiclePrice = (
  items: Array<Trip>,
  lang: Language,
  currency: CurrencyCode = CurrencyCode.CZK,
) => {
  if (!items || !items.length) return null;

  let price = items[0].price_total;

  for (const item of items) {
    if (item.price_total.currency === currency && item.price_total.amount < price.amount) {
      price = item.price_total;
    }
  }

  return formatPrice(price.amount, lang, currency);
};

export function roundPriceAmount(amount: number, currency: CurrencyCode = CurrencyCode.CZK, fraction?: number): number {
  const [, max] = getCurrencyFractions(currency);
  fraction ??= max;
  return Math.round(amount * Math.pow(10, fraction)) / Math.pow(10, fraction);
}

export function formatPriceAmount(
  amount: number,
  lang: Language = Language.CS,
  currency: CurrencyCode,
  fraction?: number,
): string {
  const [min, max] = getCurrencyFractions(currency);
  const roundedAmount = roundPriceAmount(amount, currency, fraction ?? max);
  return formatNumber(roundedAmount, lang, fraction ?? [min, max]);
}
