import type { Trip } from './model/Trip';

export interface TripModalContext {
  trip: Trip;
}

export enum ModalEnum {
  SIGN_IN = 0,
  SIGN_UP = 1,
  FACEBOOK = 2,
  GOOGLE = 3,
  APPLE = 4,
  PASSWORD_RESET = 5,
  TRIP = 6,
  REGION_SELECT = 7,
  DRIVING_LICENCE_NUMBER = 8,
}

export interface ModalOptions {
  redirect?: string;
  context?: Record<string, unknown> | TripModalContext;

  successCallback?();

  closeCallback?(success: boolean);
}
