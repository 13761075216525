import React from 'react';

import { PublicApiV3 } from '../services/Api';
import type { Country } from '../services/Country';

import type { CurrencyCode } from './Price';

export interface Attribute<T = string> {
  value: T;
  trans_code: string;
  label: string;
  description_trans_code?: string;
}

export interface CountryAttribute extends Attribute<Country> {
  calling_code: string;
  currency: CurrencyCode;
}

export interface City {
  name: string;
  slug: string;
  lat: number;
  lon: number;
}

export type VehicleEquipment = Attribute<boolean>;

export interface VehicleModel {
  model_id: number;
  name: string;
}

export interface Manufacturer {
  id: number;
  name: string;
  is_luxury: boolean;
  vehicle_models: VehicleModel[];
}

async function fetchAttributes<T = string>(name: string) {
  const response = await PublicApiV3.get<Attribute<T>[]>(`/attributes/${name}`);
  return response.data;
}

export async function fetchAttributeList<T = string>(list: string) {
  const response = await PublicApiV3.get<Attribute<T>[]>(`/attributes/attribute_list/${list}`);
  return response.data;
}

interface CountryEnumResponse {
  accept_language_default: string;
  calling_code: string;
  code: Country;
  currency: {
    code: CurrencyCode;
    name: string;
  };
  name: string;
}

export async function fetchAttributeListCountry(): Promise<CountryAttribute[]> {
  const response = await PublicApiV3.get<CountryEnumResponse[]>('/enums/countries', { all: true });
  return response.data.map(i => ({
    value: i.code,
    label: i.name,
    trans_code: i.code,
    calling_code: i.calling_code,
    currency: i.currency.code,
  }));
}

export async function fetchCities() {
  const response = await PublicApiV3.get<City[]>('/locations/cities');
  return response.data;
}

export async function fetchEquipments() {
  return await fetchAttributes<boolean>('equipment');
}

export async function fetchManufacturers() {
  const response = await PublicApiV3.get<Manufacturer[]>('/manufacturers');
  return response.data;
}

export async function fetchManufacturersUsedOnly() {
  const response = await PublicApiV3.get<Manufacturer[]>('/manufacturers/lists/used-models');
  return response.data;
}

export async function fetchRegions() {
  const response = await PublicApiV3.get<Attribute<Country>[]>('/configs/country');
  return response.data;
}

export function mapAttributesToValues<T = string>(attributes: Attribute<T>[] = []): T[] {
  return attributes.map(a => a.value);
}

export interface ValueLabelPair<T = string> {
  value: T;
  label: string;
  isLuxury?: boolean;
  icon?: string | React.ReactNode;
}

export function sortByAlphabet<T = string>(valueLabelPairs: ValueLabelPair<T>[]) {
  return valueLabelPairs.sort((a, b) => a.label.localeCompare(b.label));
}

export function mapAttributesToValueLabelPairs<T = string>(attributes: Attribute<T>[]): ValueLabelPair<T>[] {
  if (!attributes) {
    console.warn('Parametr mapAttributesToValueLabelPairs nebyl definován!');
    return [];
  }

  return attributes.map(attribute => ({
    value: attribute.value,
    label: attribute.label,
  }));
}

export function mapManufacturersToValueLabelPairs(
  manufacturers: Manufacturer[],
  filter: Manufacturer[] = [],
): ValueLabelPair<number>[] {
  if (!manufacturers) {
    console.warn('Parametr mapManufacturersToValueLabelPairs nebyl definován!');
    return [];
  }

  if (filter.length > 0) {
    const filterIds = filter.map(manufacturer => manufacturer.id);
    manufacturers = manufacturers.filter(manufacturer => filterIds.includes(manufacturer.id));
  }

  return sortByAlphabet(
    manufacturers.map(manufacturer => ({
      value: manufacturer.id,
      label: manufacturer.name,
      isLuxury: manufacturer.is_luxury,
    })),
  );
}

export function mapModelsToValueLabelPairs(models: VehicleModel[] = []): ValueLabelPair<number>[] {
  return sortByAlphabet(
    models.map(model => ({
      label: model.name,
      value: model.model_id,
    })),
  );
}

export function mapEquipmentsToValueLabelPairs(
  equipments: VehicleEquipment[],
  filter: VehicleEquipment[] = [],
): ValueLabelPair[] {
  if (!equipments) {
    console.warn('Parametr mapEquipmentsToValueLabelPairs nebyl definován!');
    return [];
  }

  if (filter.length > 0) {
    const filterCodes = filter.map(equipment => equipment.trans_code);
    equipments = equipments.filter(equipment => filterCodes.includes(equipment.trans_code));
  }

  return sortByAlphabet(
    equipments.map(equipment => ({
      value: equipment.trans_code,
      label: equipment.label,
    })),
  );
}
