import { ModalEnum } from '../../ModalEnum';
import { type Consents, updateUserConsents } from '../../model/Consents';
import { updateUserLocation, type UserLocation } from '../../model/Location';
import { updateUser, updateUserEmail, type User, UserState } from '../../model/User';
import { createUser, sendUserEmailVerificationCode } from '../../model/UserAuthorization';
import { setIDNumber, updateUserProfile } from '../../model/UserData';
import { updateUserRole, type UserRole } from '../../model/UserRole';
import { requestPhoneVerification, sendPhoneVerificationCode } from '../../model/UserVerification';
import RouteEnum from '../../RouteEnum';
import type { RouteContext } from '../../RouteProvider';
import { ApiError } from '../../services/Api';
import { UserGuard } from '../../services/ApiGuard';
import Auth from '../../services/Authorization';
import { CookieKeys, deleteCookie } from '../../services/Cookies';
import type { Country } from '../../services/Country';
import { SentryLogAdapter } from '../../services/Sentry';
import { fetchSumsubToken, notifySumSubCompletion } from '../../services/SumSub';
import type { ReduxState } from '../../store';
import { openModal } from '../../store/app';
import { loadEnum } from '../../store/enums';
import {
  afterSignIn,
  flashMessageError,
  loadSession,
  loginUserWithEmail,
  SessionActionType,
} from '../../store/session';

export enum UserRegistrationSteps {
  EMAIL = 0,
  COUNTRY = 1,
  ROLE = 2,
  EMAIL_VERIFICATION = 3,
  EMAIL_CHANGE = 4,
  PHONE = 5,
  PHONE_VERIFICATION = 6,
  ID_NUMBER = 7,
  ADDRESS = 8,
  SUMSUB_INTRO = 9,
  SUMSUB = 10,
  DONE = 11,
}

export interface UserRegistrationStore {
  step: UserRegistrationSteps;
  email: string | null;
  country: Country | null;
  consents: boolean;
  role: UserRole | null;
  email_verified: boolean;
  phone: [string, string] | null;
  phone_verified: boolean;
  id_number: boolean;
  address: UserLocation | null;
  sumsub_token: string | null;
}

const initialState: UserRegistrationStore = {
  step: UserRegistrationSteps.EMAIL,
  email: null,
  country: null,
  consents: false,
  role: null,
  email_verified: false,
  phone: null,
  phone_verified: false,
  id_number: false,
  address: null,
  sumsub_token: null,
};

export enum UserRegistrationActionType {
  UPDATE = 'userRegistration/update',
  EXIT = 'userRegistration/exit',
  COMPLETE_COUNTRY = 'userRegistration/completeCountry',
  COMPLETE_ROLE = 'userRegistration/completeRole',
  COMPLETE_PHONE = 'userRegistration/completePhone',
}

function updateUserRegistration(data: Partial<UserRegistrationStore>): ActionReturnType {
  return {
    type: UserRegistrationActionType.UPDATE,
    payload: data,
  };
}

export function returnToPreviousUserRegistrationStep(): ThunkAction<
  ReduxState,
  [RouteEnum | undefined, (() => void) | undefined]
> {
  return (dispatch, getState) => {
    const step = getState().userRegistration.step;

    if (step === UserRegistrationSteps.EMAIL) {
      return [RouteEnum.HOMEPAGE, () => dispatch(openModal(ModalEnum.SIGN_UP))];
    }

    if ([UserRegistrationSteps.COUNTRY, UserRegistrationSteps.ROLE].includes(step)) {
      return [
        RouteEnum.HOMEPAGE,
        () => {
          Auth.destroy();
          deleteCookie(CookieKeys.USER);
          deleteCookie(CookieKeys.CONSENTS);
          dispatch({
            type: UserRegistrationActionType.EXIT,
            payload: { shouldDestroyUser: true },
          });
        },
      ];
    }

    if ([UserRegistrationSteps.PHONE, UserRegistrationSteps.ADDRESS].includes(step)) {
      return [RouteEnum.HOMEPAGE, undefined];
    }

    dispatch(updateUserRegistration({ step: Math.max(0, step - 1) }));
    return [undefined, undefined];
  };
}

export function closeUserRegistration(): ThunkAction<ReduxState, RouteEnum> {
  return (dispatch, getState) => {
    const step = getState().userRegistration.step;
    let shouldDestroyUser = false;
    if (step <= UserRegistrationSteps.COUNTRY) {
      shouldDestroyUser = true;
      Auth.destroy();
      deleteCookie(CookieKeys.USER);
      deleteCookie(CookieKeys.CONSENTS);
    }
    dispatch({
      type: UserRegistrationActionType.EXIT,
      payload: { shouldDestroyUser },
    });
    if (step >= UserRegistrationSteps.ROLE) {
      return RouteEnum.MY_PROFILE;
    }
    return RouteEnum.HOMEPAGE;
  };
}

export function closeEmailVerification(hash: string, routeContext: RouteContext<UnsafeAny>): ThunkAction<ReduxState> {
  return (dispatch, getState) => {
    const isSignedIn = getState().session.authorized;
    const isSameUser = getState().user?.hash === hash;
    if (!isSignedIn || !isSameUser) {
      dispatch(
        afterSignIn(() => {
          routeContext.navigate(routeContext.generatePath(RouteEnum.USER_REGISTRATION), { replace: true });
        }),
      );
      return;
    }
    routeContext.navigate(routeContext.generatePath(RouteEnum.USER_REGISTRATION), { replace: true });
  };
}

export function completeEmail(
  email: string,
  password: string,
  invitationCode: string = null,
): ThunkAction<ReduxState, Promise<UserGuard | void>> {
  return async dispatch => {
    try {
      // TODO: createUser should also login user, right?
      const response = await createUser(email, password, invitationCode);
      if (response.hasMessage(UserGuard.EMAIL_ALREADY_USED, UserGuard.USERNAME_ALREADY_USED)) {
        return response.message as UserGuard;
      }
      await dispatch(loginUserWithEmail(email, password));
      dispatch(loadEnum(null, 'consents'));
      dispatch(updateUserRegistration({ email, step: UserRegistrationSteps.COUNTRY }));
    } catch (error) {
      if (
        error instanceof ApiError &&
        error.hasMessage(UserGuard.EMAIL_ALREADY_USED, UserGuard.USERNAME_ALREADY_USED)
      ) {
        return error.message as UserGuard;
      }
      dispatch(flashMessageError(error.message));
    }
  };
}

export function completeCountry(country: Country, consents: Consents): ThunkAction<ReduxState> {
  return async dispatch => {
    Auth.disabled = false;
    await updateUser({ country_code: country });
    await updateUserConsents(consents);
    dispatch({
      type: UserRegistrationActionType.COMPLETE_COUNTRY,
      payload: country,
    });
  };
}

export function completeRole(role: UserRole): ThunkAction<ReduxState> {
  return async dispatch => {
    await updateUserRole(role);
    dispatch({ type: UserRegistrationActionType.COMPLETE_ROLE, payload: role });
  };
}

export function changeEmailInRegistration(): ThunkAction<ReduxState> {
  return dispatch => {
    dispatch(updateUserRegistration({ step: UserRegistrationSteps.EMAIL_CHANGE }));
  };
}

export function completeEmailChange(email: string): ThunkAction<ReduxState, Promise<void | UserGuard>> {
  return async dispatch => {
    try {
      const response = await updateUserEmail(email);
      if (response.hasMessage(UserGuard.EMAIL_ALREADY_USED, UserGuard.USERNAME_ALREADY_USED)) {
        return response.message as UserGuard;
      }

      Auth.setAuth(response.data);
      SentryLogAdapter.setUser(response.data.user);
    } catch (error) {
      if (
        error instanceof ApiError &&
        error.hasMessage(UserGuard.EMAIL_ALREADY_USED, UserGuard.USERNAME_ALREADY_USED)
      ) {
        return error.message as UserGuard;
      }
      dispatch(flashMessageError(error.message));
      return;
    }
    dispatch(
      updateUserRegistration({
        email,
        step: UserRegistrationSteps.EMAIL_VERIFICATION,
      }),
    );
  };
}

export function completeEmailVerification(hash: string, code: string): ThunkAction<ReduxState> {
  return async (dispatch, getState) => {
    await sendUserEmailVerificationCode(code);
    const isSignedIn = getState().session.authorized;
    const isSameUser = getState().user?.hash === hash;
    if (!isSignedIn || !isSameUser) return;
    dispatch(
      updateUserRegistration({
        step: UserRegistrationSteps.PHONE,
        email_verified: true,
      }),
    );
  };
}

export function completePhone(dialCode: string, phone: string): ThunkAction<ReduxState> {
  return async dispatch => {
    await updateUserProfile({ phone: `${dialCode}${phone}` });
    await requestPhoneVerification();
    dispatch({
      type: UserRegistrationActionType.COMPLETE_PHONE,
      payload: [dialCode, phone],
    });
  };
}

export function completePhoneVerification(code: string): ThunkAction<ReduxState> {
  return async dispatch => {
    await sendPhoneVerificationCode(code);
    dispatch(
      updateUserRegistration({
        step: UserRegistrationSteps.ID_NUMBER,
        phone_verified: true,
      }),
    );
  };
}

export const ID_NUMBER_LENGTH = 10;

export function completeIDNumber(id_number: string, skipped = false): ThunkAction<ReduxState> {
  return async dispatch => {
    await setIDNumber(skipped ? null : id_number, skipped);
    dispatch(updateUserRegistration({ step: UserRegistrationSteps.ADDRESS }));
  };
}

export function completeAddress(address: UserLocation): ThunkAction<ReduxState> {
  return async dispatch => {
    await updateUserLocation(address);
    dispatch(
      updateUserRegistration({
        step: UserRegistrationSteps.SUMSUB_INTRO,
        address,
      }),
    );
  };
}

export function completeSumsubIntro(): ThunkAction<ReduxState> {
  return async dispatch => {
    const sumsub_token = await fetchSumsubToken();
    dispatch(
      updateUserRegistration({
        step: UserRegistrationSteps.SUMSUB,
        sumsub_token,
      }),
    );
  };
}

export function completeSumsub(): ThunkAction<ReduxState> {
  return async dispatch => {
    await notifySumSubCompletion();
    await dispatch(loadSession());
    dispatch(updateUserRegistration({ step: UserRegistrationSteps.DONE }));
  };
}

type Action =
  | {
      type: SessionActionType.AUTHORIZE;
      payload: { hasConsents: boolean; user: User };
    }
  | { type: SessionActionType.CLEAR_SESSION }
  | {
      type: UserRegistrationActionType.EXIT;
      payload: { shouldDestroyUser: boolean };
    }
  | {
      type: UserRegistrationActionType.UPDATE;
      payload: Partial<UserRegistrationStore>;
    }
  | { type: UserRegistrationActionType.COMPLETE_COUNTRY; payload: Country }
  | { type: UserRegistrationActionType.COMPLETE_ROLE; payload: UserRole }
  | {
      type: UserRegistrationActionType.COMPLETE_PHONE;
      payload: [string, string];
    };

export function reducer(state = initialState, action: Action): UserRegistrationStore {
  if (action.type === SessionActionType.AUTHORIZE) {
    if (action.payload.hasConsents === false) {
      return {
        ...initialState,
        step: UserRegistrationSteps.COUNTRY,
        country: action.payload.user.country?.alpha2_code,
      };
    }
    return {
      ...initialState,
      ...computeNextStateFromUser(action.payload.user),
    };
  }
  if (action.type === SessionActionType.CLEAR_SESSION) {
    return initialState;
  }
  if (action.type === UserRegistrationActionType.EXIT) {
    if (action.payload.shouldDestroyUser) {
      return initialState;
    }
    if (state.step === UserRegistrationSteps.SUMSUB) {
      return {
        ...state,
        step: UserRegistrationSteps.SUMSUB_INTRO,
        sumsub_token: null,
      };
    }
    return state;
  }
  if (action.type === UserRegistrationActionType.UPDATE) {
    return { ...state, ...action.payload };
  }
  if (action.type === UserRegistrationActionType.COMPLETE_COUNTRY) {
    return {
      ...state,
      country: action.payload,
      consents: true,
      step: UserRegistrationSteps.ROLE,
    };
  }
  if (action.type === UserRegistrationActionType.COMPLETE_ROLE) {
    let step = UserRegistrationSteps.EMAIL_VERIFICATION;
    if (state.email_verified) {
      step = UserRegistrationSteps.PHONE;
    }
    return { ...state, role: action.payload, step };
  }
  if (action.type === UserRegistrationActionType.COMPLETE_PHONE) {
    return {
      ...state,
      phone: action.payload,
      step: UserRegistrationSteps.PHONE_VERIFICATION,
    };
  }
  return state;
}

function computeNextStateFromUser(user: User): Partial<UserRegistrationStore> {
  const nextState: Partial<UserRegistrationStore> = {};

  if (typeof user.email == 'string') {
    nextState.step = UserRegistrationSteps.COUNTRY;
    nextState.email = user.email;
  }
  if (typeof user.country === 'object') {
    nextState.step = UserRegistrationSteps.ROLE;
    nextState.country = user.country.alpha2_code;
    nextState.consents = true;
  }
  if (typeof user.registration_role === 'string') {
    nextState.step = UserRegistrationSteps.EMAIL_VERIFICATION;
    nextState.role = user.registration_role;
  }
  if (user.verification.email) {
    if (nextState.step === UserRegistrationSteps.EMAIL_VERIFICATION) {
      nextState.step = UserRegistrationSteps.PHONE;
    }
    nextState.email_verified = true;
  }
  if (user.verification.phone) {
    nextState.step = UserRegistrationSteps.ID_NUMBER;
    nextState.phone_verified = true;
  }
  if (user.data.birth_number_screen_visited) {
    nextState.step = UserRegistrationSteps.ADDRESS;
  }
  if (typeof user.location === 'object' && user.location !== null) {
    nextState.step = UserRegistrationSteps.SUMSUB_INTRO;
    nextState.address = user.location;
  }
  if (
    [UserState.ACTIVE, UserState.WAITING_FOR_REVISION_AUTOMATED, UserState.WAITING_FOR_REVISION].includes(user.state)
  ) {
    nextState.step = UserRegistrationSteps.DONE;
  }

  return nextState;
}
