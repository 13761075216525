import {
  add,
  compareAsc,
  differenceInCalendarDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  endOfDay as dateEndOfDay,
  endOfMonth as dateEndOfMonth,
  endOfWeek,
  format,
  getUnixTime,
  isSameDay as isDateSameDay,
  isSameHour as isDateSameHour,
  isSameMinute as isDateSameMinute,
  isSameMonth as isDateSameMonth,
  isSameSecond as isDateSameSecond,
  isSameYear as isDateSameYear,
  isValid,
  set,
  setDay,
  setMilliseconds,
  startOfDay as dateStartOfDay,
  startOfMonth as dateStartOfMonth,
  startOfWeek,
  sub,
} from 'date-fns';
import { cs, enGB, pl, sk } from 'date-fns/locale';
import { toDate as toDateFn } from 'date-fns-tz';

export type Datelike = Date | string | number;

export function isDatelike(it: Datelike | UnsafeAny): it is Datelike {
  if (it === null || it === undefined) return false;
  const parsed = toDate(it);
  return parsed instanceof Date && isValid(parsed);
}

let locale: Locale = cs;

export function changeDateTimeLocale(lang: string): void {
  switch (lang.toUpperCase()) {
    case 'CS':
      locale = cs;
      break;
    case 'EN':
      locale = enGB;
      break;
    case 'PL':
      locale = pl;
      break;
    case 'SK':
      locale = sk;
      break;
  }
}

export function formatDatelike(it: Datelike, pattern: string): string {
  return format(toDate(it), pattern, { locale, weekStartsOn: 1 });
}

// region to*Date
export function toLocalDate(it: Datelike): Date {
  return toDate(it);
}

export function toUTCDate(it: Datelike): Date {
  const localDate = toDate(it);
  return setDate(
    setTime(new Date(), localDate.getUTCHours(), localDate.getUTCMinutes(), localDate.getUTCSeconds()),
    localDate.getUTCDate(),
    getMonth(localDate),
    getYear(localDate),
  );
}

export function toDate(it?: Datelike): Date {
  if (it === null || it === undefined) {
    it = new Date();
  }
  return setMilliseconds(toDateFn(it), 0);
}

// endregion

// region is*Date
export function isSameDatelike(it: Datelike, other: Datelike): boolean {
  return getUnixTime(toDate(it)) === getUnixTime(toDate(other));
}

export function isSameYear(it: Datelike, other: Datelike): boolean {
  return isDateSameYear(toDate(it), toDate(other));
}

export function isSameMonth(it: Datelike, other: Datelike): boolean {
  return isDateSameMonth(toDate(it), toDate(other));
}

export function isSameDay(it: Datelike, other: Datelike): boolean {
  return isDateSameDay(toDate(it), toDate(other));
}

export function isSameHour(it: Datelike, other: Datelike): boolean {
  return isDateSameHour(toDate(it), toDate(other));
}

export function isSameMinute(it: Datelike, other: Datelike): boolean {
  return isDateSameMinute(toDate(it), toDate(other));
}

export function isSameSecond(it: Datelike, other: Datelike): boolean {
  return isDateSameSecond(toDate(it), toDate(other));
}

export function isToday(it: Datelike): boolean {
  return isSameDay(it, new Date());
}

export function isAfter(it: Datelike, other: Datelike): boolean {
  const a = getUnixTime(toDate(it));
  const b = getUnixTime(toDate(other));
  return a > b;
}

export function isDaysAfter(it: Datelike, other: Datelike): boolean {
  return isAfter(it, preserveTime(other, it));
}

export function isMinutesAfter(it: Datelike, other: Datelike): boolean {
  return isAfter(it, setSeconds(other, getSeconds(it)));
}

export function isBefore(it: Datelike, other: Datelike): boolean {
  const a = getUnixTime(toDate(it));
  const b = getUnixTime(toDate(other));
  return a < b;
}

export function isDaysBefore(it: Datelike, other: Datelike): boolean {
  return isBefore(it, preserveTime(other, it));
}

export function isMinutesBefore(it: Datelike, other: Datelike): boolean {
  return isBefore(it, setSeconds(other, getSeconds(it)));
}

export function isSameOrAfter(it: Datelike, other: Datelike): boolean {
  const a = getUnixTime(toDate(it));
  const b = getUnixTime(toDate(other));
  return a >= b;
}

export function isSameOrBefore(it: Datelike, other: Datelike): boolean {
  const a = getUnixTime(toDate(it));
  const b = getUnixTime(toDate(other));
  return a <= b;
}

export function isSameDayOrAfter(it: Datelike, other: Datelike): boolean {
  const min = getUnixTime(setTime(other, 0, 0, 0));
  const parsed = getUnixTime(toDate(it));
  return min <= parsed;
}

export function isAfterNow(it: Datelike): boolean {
  return isAfter(it, new Date());
}

export function isBeforeNow(it: Datelike): boolean {
  return isBefore(it, new Date());
}

export function isBetweenDaysInclusive(it: Datelike, from: Datelike, to: Datelike): boolean {
  if (!from && !to) {
    return true;
  }

  const min = getUnixTime(getStartOfDay(from));
  const max = getUnixTime(getEndOfDay(to));
  const parsed = getUnixTime(toDate(it));

  if (!from) {
    return parsed <= max;
  }
  if (!to) {
    return min <= parsed;
  }
  return min <= parsed && parsed <= max;
}

export function isBetweenDaysInclusiveLeft(it: Datelike, from: Datelike, to: Datelike): boolean {
  if (!from && !to) {
    return true;
  }

  const min = getUnixTime(getStartOfDay(from));
  const max = getUnixTime(getStartOfDay(to));
  const parsed = getUnixTime(toDate(it));

  if (!from) {
    return parsed < max;
  }
  if (!to) {
    return min <= parsed;
  }
  return min <= parsed && parsed < max;
}

export function isBetweenMinutesInclusive(it: Datelike, from: Datelike, to: Datelike): boolean {
  if (!from && !to) {
    return true;
  }

  const min = getUnixTime(setSeconds(from, 0));
  const max = getUnixTime(setSeconds(to, 59));
  const parsed = getUnixTime(toDate(it));

  if (!from) {
    return parsed <= max;
  }
  if (!to) {
    return min <= parsed;
  }
  return min <= parsed && parsed <= max;
}

export function isBetweenMinutesInclusiveLeft(it: Datelike, from: Datelike, to: Datelike): boolean {
  if (!from && !to) {
    return true;
  }

  const min = getUnixTime(setSeconds(from, 0));
  const max = getUnixTime(setSeconds(to, 0));
  const parsed = getUnixTime(toDate(it));

  if (!from) {
    return parsed < max;
  }
  if (!to) {
    return min <= parsed;
  }
  return min <= parsed && parsed < max;
}

// endregion

// region set*

export function setYears(it: Datelike, year: number): Date {
  return set(toDate(it), { year });
}

export function setMonths(it: Datelike, month: number): Date {
  return set(toDate(it), { month });
}

export function setDays(it: Datelike, date: number): Date {
  return set(toDate(it), { date });
}

export function setHours(it: Datelike, hours: number): Date {
  return set(toDate(it), { hours });
}

export function setMinutes(it: Datelike, minutes: number): Date {
  return set(toDate(it), { minutes });
}

export function setSeconds(it: Datelike, seconds: number): Date {
  return set(toDate(it), { seconds });
}

export function setWeekday(it: Datelike, weekday: number): Date {
  return setDay(toDate(it), weekday, { locale });
}

export function setDate(it: Datelike, day: number, month: number, year: number): Date {
  return setYears(setMonths(setDays(it, day), month), year);
}

export function setTime(it: Datelike, hours: number, minutes: number, seconds = 0): Date {
  return setHours(setMinutes(setSeconds(setMilliseconds(toDate(it), 0), seconds), minutes), hours);
}

// endregion

// region add*
export function addYears(it: Datelike, amount: number): Date {
  return add(toDate(it), { years: amount });
}

export function addMonths(it: Datelike, amount: number): Date {
  return add(toDate(it), { months: amount });
}

export function addDays(it: Datelike, amount: number): Date {
  return add(toDate(it), { days: amount });
}

export function addHours(it: Datelike, amount: number): Date {
  return add(toDate(it), { hours: amount });
}

export function addMinutes(it: Datelike, amount: number): Date {
  return add(toDate(it), { minutes: amount });
}

export function addSeconds(it: Datelike, amount: number): Date {
  return add(toDate(it), { seconds: amount });
}

export function addWeeks(it: Datelike, amount: number): Date {
  return add(toDate(it), { weeks: amount });
}

// endregion

// region subtract*

export function subtractYears(it: Datelike, amount: number): Date {
  return sub(toDate(it), { years: amount });
}

export function subtractMonths(it: Datelike, amount: number): Date {
  return sub(toDate(it), { months: amount });
}

export function subtractDays(it: Datelike, amount: number): Date {
  return sub(toDate(it), { days: amount });
}

export function subtractHours(it: Datelike, amount: number): Date {
  return sub(toDate(it), { hours: amount });
}

export function subtractMinutes(it: Datelike, amount: number): Date {
  return sub(toDate(it), { minutes: amount });
}

export function subtractSeconds(it: Datelike, amount: number): Date {
  return sub(toDate(it), { seconds: amount });
}

export function subtractWeeks(it: Datelike, amount: number): Date {
  return sub(toDate(it), { weeks: amount });
}

// endregion

// region get*

export function getStartOfMonth(it: Datelike): Date {
  return dateStartOfMonth(toDate(it));
}

export function getStartOfDay(it: Datelike): Date {
  return dateStartOfDay(toDate(it));
}

export function getStartOfWeek(it: Datelike): Date {
  return startOfWeek(toDate(it), { locale });
}

export function getEndOfMonth(it: Datelike): Date {
  return dateEndOfMonth(toDate(it));
}

export function getEndOfDay(it: Datelike): Date {
  return dateEndOfDay(toDate(it));
}

export function getEndOfWeek(it: Datelike): Date {
  return endOfWeek(toDate(it), { locale });
}

export function getYear(it: Datelike): number {
  return toDate(it).getFullYear();
}

export function getMonth(it: Datelike): number {
  return toDate(it).getMonth();
}

export function getDay(it: Datelike): number {
  return toDate(it).getDate();
}

export function getHours(it: Datelike): number {
  return toDate(it).getHours();
}

export function getMinutes(it: Datelike): number {
  return toDate(it).getMinutes();
}

export function getSeconds(it: Datelike): number {
  return toDate(it).getSeconds();
}

export function getWeekday(it: Datelike): number {
  return daysBetween(getStartOfWeek(it), it, true);
}

// endregion

// region *Between

export function yearsBetween(from: Datelike, to: Datelike, absolute = true): number {
  const diff = getYear(from) - getYear(to);
  return absolute ? Math.abs(diff) : diff;
}

export function daysBetween(from: Datelike, to: Datelike, absolute = true): number {
  const diff = differenceInCalendarDays(toDate(from), toDate(to));
  return absolute ? Math.abs(diff) : diff;
}

export function hoursBetween(from: Datelike, to: Datelike, absolute = true): number {
  const diff = differenceInHours(toDate(from), toDate(to));
  return absolute ? Math.abs(diff) : diff;
}

export function minutesBetween(from: Datelike, to: Datelike, absolute = true): number {
  const diff = differenceInMinutes(toDate(from), toDate(to));
  return absolute ? Math.abs(diff) : diff;
}

export function secondsBetween(from: Datelike, to: Datelike, absolute = true): number {
  const diff = differenceInSeconds(toDate(from), toDate(to));
  return absolute ? Math.abs(diff) : diff;
}

export function weeksBetween(it: Datelike, other: Datelike, absolute = true): number {
  const daysDiff = daysBetween(getStartOfWeek(it), getEndOfWeek(other), absolute);
  const diff = Math.round(daysDiff / 7);
  return absolute ? Math.abs(diff) : diff;
}

// endregion

export function compareDatelikeAsc(a: Datelike, b: Datelike): number {
  return compareAsc(toDate(a), toDate(b));
}

export interface DateRangeQuery {
  date_from?: string;
  date_to?: string;
}

export const UTC_ISO_FORMAT = `yyyy-MM-dd'T'HH:mm:ss'Z'`;
export const TIME_FORMAT = 'H:mm';
export const SEC_IN_DAY = 86400;
export const MIN_MINUTES_IN_RANGE = 30;
export const MIN_HOURS_BEFORE_TRIP = 0;

export function toISOString(date?: Datelike): string {
  if (!date) return undefined;
  return toDate(date)
    .toISOString()
    .replace(/\.[0-9]{0,4}Z/, 'Z');
}

export function hoursTill(date: Datelike): number {
  return Math.max(hoursBetween(date, new Date(), false), 0);
}

export function minutesTill(date: Datelike): number {
  return minutesBetween(date, new Date());
}

export function timeTill(date: Datelike): [number, number] {
  let hours = 0;
  let minutes = minutesBetween(date, new Date());
  if (minutes > 60) {
    hours = Math.floor(minutes / 60);
    minutes = minutes / 60 - hours;
  }
  return [hours, minutes];
}

export function tripDaysBetween(dateFrom: Datelike, dateTo: Datelike): number {
  if (!dateFrom || !dateTo) return 1;
  const numberOfSeconds = secondsBetween(dateTo, dateFrom);
  let numOfDays = 1;

  if (numberOfSeconds > SEC_IN_DAY) {
    numOfDays += daysBetween(getStartOfDay(dateTo), getStartOfDay(dateFrom));
  }

  return numOfDays;
}

export function roundTime(time: Datelike): Date {
  let hours = getHours(time);
  let minutes = getMinutes(time);

  if (minutes <= 15) {
    minutes = 0;
  } else if (minutes > 15 && minutes <= 45) {
    minutes = 30;
  } else if (minutes > 45) {
    minutes = 0;
    hours = hours + 1 > 24 ? 0 : hours + 1;
  }

  return setHours(setMinutes(setSeconds(time, 0), minutes), hours);
}

export function preserveTime(date: Datelike, time: Datelike): Date {
  const hours = getHours(time);
  const minutes = getMinutes(time);
  const seconds = getSeconds(time);
  return setHours(setMinutes(setSeconds(date, seconds), minutes), hours);
}

export function getMinDate(date?: Datelike): Date {
  const min = roundTime(addHours(new Date(), MIN_HOURS_BEFORE_TRIP));
  if (!date) {
    return min;
  }
  let result = setHours(setMinutes(date, 0), 0);
  if (isMinutesBefore(result, min)) {
    result = roundTime(preserveTime(result, min));
  }
  return result;
}
