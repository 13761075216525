import {
  type BraintreePaymentMethod,
  fetchBrainTreeClientToken,
  getBraintreePaymentMethods,
  pairCard,
  unpairCard,
} from '../services/Braintree';

import type { ReduxState } from './index';
import { UserActionType } from './user';

export interface PaymentMethodsState {
  clientToken: string;
  methods: BraintreePaymentMethod[];
  loading: boolean;
}

const initialState: PaymentMethodsState = {
  clientToken: null,
  methods: null,
  loading: true,
};

export enum PaymentMethodsActionType {
  LOAD = 'paymentMethods/load',
  SET = 'paymentMethods/set',
}

export function loadBraintree(): ThunkAction<ReduxState> {
  return async (dispatch, getState) => {
    if (getState().paymentMethods.clientToken) return;
    const response = await fetchBrainTreeClientToken(getState().app.region);
    const clientToken = response.client_token;
    dispatch({ type: PaymentMethodsActionType.SET, payload: { clientToken } });
  };
}

export function loadPaymentMethods(): ThunkAction<ReduxState> {
  return async (dispatch, getState) => {
    const state = getState().paymentMethods;
    dispatch({ type: PaymentMethodsActionType.LOAD });
    const payload: Partial<PaymentMethodsState> = {};
    let clientToken = state.clientToken;
    if (!clientToken) {
      const response = await fetchBrainTreeClientToken(getState().app.region);
      clientToken = response.client_token;
      payload.clientToken = clientToken;
    }
    payload.methods = await getBraintreePaymentMethods(clientToken);
    dispatch({ type: PaymentMethodsActionType.SET, payload });
  };
}

export function addPaymentMethod(nonce: string): ThunkAction<ReduxState> {
  return async (dispatch, getState) => {
    await pairCard(nonce, getState().app.region);
    dispatch(loadPaymentMethods());
  };
}

export function removePaymentMethod(nonce: string): ThunkAction<ReduxState> {
  return async (dispatch, getState) => {
    await unpairCard(nonce, getState().app.region);
    dispatch(loadPaymentMethods());
  };
}

type Action =
  | { type: PaymentMethodsActionType.LOAD }
  | {
      type: PaymentMethodsActionType.SET;
      payload: Partial<PaymentMethodsState>;
    }
  | { type: UserActionType.DESTROY_USER };

export default function paymentMethods(state = initialState, action: Action): PaymentMethodsState {
  if (action.type === PaymentMethodsActionType.LOAD) {
    return { ...state, loading: true };
  }
  if (action.type === PaymentMethodsActionType.SET) {
    return { ...state, ...action.payload, loading: false };
  }
  if (action.type === UserActionType.DESTROY_USER) {
    return initialState;
  }
  return state;
}
