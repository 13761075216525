import { PrivateApiV3 } from '../services/Api';
import { getEmptyPage, type Page } from '../services/Paging';

import type { Document, DocumentType } from './Document';
import type { PriceType } from './Price';
import type { Vehicle, VehicleState } from './Vehicle';
import type { VehicleTag } from './VehicleTag';

export interface FavoriteVehicle {
  hash: string;
  state: VehicleState;
  name: string;
  main_image: Document<DocumentType.MAIN_PHOTO>;
  mileage: number;
  price_per_day: PriceType;
  extra_km_price: PriceType;
  rating_average_float: number;
  ratings_count: number;
  tags?: VehicleTag[];
}

export type FavoriteVehicles = Page<FavoriteVehicle>;

export async function fetchUserFavoriteVehicles(page?: number): Promise<FavoriteVehicles> {
  try {
    const response = await PrivateApiV3.get<FavoriteVehicles>('/users/favorite-vehicles/list', { page });
    return response.data;
  } catch {
    return getEmptyPage<FavoriteVehicle>();
  }
}

export async function addUserFavoriteVehicle(vehicleHash: string) {
  await PrivateApiV3.post(`/users/favorite-vehicles/${vehicleHash}`);
}

export async function deleteUserFavoriteVehicle(vehicleHash: string) {
  await PrivateApiV3.delete(`/users/favorite-vehicles/${vehicleHash}`);
}

export async function toggleUserFavoriteVehicle(vehicle: Vehicle): Promise<boolean> {
  if (vehicle.is_favorite) {
    await deleteUserFavoriteVehicle(vehicle.hash);
    return false;
  }
  await addUserFavoriteVehicle(vehicle.hash);
  return true;
}
