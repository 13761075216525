import { type ConsentsDefinition, fetchConsents } from '../model/Consents';
import {
  type Attribute,
  type CountryAttribute,
  fetchAttributeList,
  fetchAttributeListCountry,
  fetchEquipments,
  fetchManufacturers,
  fetchManufacturersUsedOnly,
  fetchRegions,
  type Manufacturer,
  type VehicleEquipment,
} from '../model/Enum';
import { fetchConfigs, type RegionAPIConfig } from '../model/RegionConfig';
import { Api } from '../services/Api';
import type { Country } from '../services/Country';

import type { ReduxState } from '.';

interface Resource<T> {
  loading: boolean;
  data: T;
  error?: string;
}

type ResourceState<T> = {
  [K in keyof T]: Resource<T[K]>;
};

type ResourceLoaders<T> = {
  [K in keyof T]: () => Promise<T[K]>;
};

interface EnumsState {
  regions: Attribute<Country>[];
  country: CountryAttribute[];
  manufacturers: Manufacturer[];
  manufacturersUsedOnly: Manufacturer[];
  equipments: VehicleEquipment[];
  fuel: Attribute[];
  transmission: Attribute[];
  type: Attribute[];
  color: Attribute[];
  consents: Record<Country, ConsentsDefinition>;
  config: RegionAPIConfig;
}

export type EnumsStore = ResourceState<EnumsState>;

enum ActionType {
  BEGIN = 'enums/begin',
  SUCCESS = 'enums/success',
  ERROR = 'enums/error',
}

type Action =
  | { type: ActionType.BEGIN; names: string[] }
  | { type: ActionType.ERROR; name: string; payload: string }
  | { type: ActionType.SUCCESS; name: string; payload: unknown };

const initialState: Partial<ResourceState<EnumsState>> = {};

const loaders: ResourceLoaders<EnumsState> = {
  regions: () => fetchRegions(),
  country: () => fetchAttributeListCountry(),
  manufacturers: () => fetchManufacturers(),
  manufacturersUsedOnly: () => fetchManufacturersUsedOnly(),
  equipments: () => fetchEquipments(),
  fuel: () => fetchAttributeList('fuel'),
  transmission: () => fetchAttributeList('transmission'),
  type: () => fetchAttributeList('type'),
  color: () => fetchAttributeList('color'),
  consents: () => fetchConsents(),
  config: () => fetchConfigs(),
};

export default function enums(state = initialState, action: Action) {
  if (action.type === ActionType.BEGIN) {
    const nextState = { ...state };
    for (const name of action.names) {
      nextState[name] = { loading: true, error: null, data: null };
    }
    return nextState;
  }
  if (action.type === ActionType.ERROR) {
    return {
      ...state,
      [action.name]: { loading: false, error: action.payload, data: null },
    };
  }
  if (action.type === ActionType.SUCCESS) {
    return {
      ...state,
      [action.name]: { loading: false, error: null, data: action.payload },
    };
  }
  return state;
}

const load =
  (name: keyof EnumsStore): ThunkAction =>
  async dispatch => {
    try {
      const payload = await loaders[name]();
      dispatch({ type: ActionType.SUCCESS, name, payload });
    } catch (error) {
      dispatch({ type: ActionType.ERROR, name, payload: error.message });
    }
  };

export const loadEnum =
  (lang = null, ...names: (keyof EnumsStore)[]): ThunkAction =>
  async (dispatch, getState) => {
    if (lang) Api.language = lang;
    const state = getState().enums;
    names = names.filter(it => !(it in state && (state[it].loading || state[it].data !== null)));
    dispatch({ type: ActionType.BEGIN, names });
    for (const name of names) {
      dispatch(load(name));
    }
  };

export const loadRestEnums = (): ThunkAction<ReduxState> => async (dispatch, getState) => {
  const loaded = Object.entries(getState().enums)
    .filter(([, value]) => !value.loading && !value.error && !!value.data)
    .map(([name]) => name);
  const missing = Object.keys(loaders).filter(it => !loaded.includes(it));
  dispatch(loadEnum(null, ...missing));
};

export const reloadEnums = (): ThunkAction<ReduxState> => async (dispatch, getState) => {
  const loaded = Object.entries(getState().enums)
    .filter(([, value]) => !value.loading && !value.error && !!value.data)
    .map(([name]) => name);
  for (const name of loaded) {
    dispatch(load(name));
  }
};
