import type { Manufacturer, VehicleModel } from '../model/Enum';
import type { PriceType } from '../model/Price';

import { PrivateApiV3 } from './Api';
import type { Country } from './Country';

export interface CebiaResponseData {
  display_name: string;
  consumption_mix?: number;
  engine_size?: number;
  first_registration?: string;
  fuel_tank_capacity?: number;
  fuel_type?: string;
  kind?: string;
  license_plate?: string;
  manufacturer?: Manufacturer;
  model?: VehicleModel;
  price?: PriceType;
  power?: number;
  raid?: number;
  seats?: number;
  total_weight?: number;
  transmission?: string;
  type?: string;
  version?: string;
  vin?: string;
}

export interface CebiaCheckResponse {
  manufacturers: Array<Manufacturer>;
  cebia_response: Array<CebiaResponseData>;
}

export interface CebiaRequestData {
  vin: string;
  raid: number;
  license_plate: string;
  country_code: Country;
}

export async function fetchCebiaCheck(data: CebiaRequestData): Promise<CebiaResponseData> {
  const response = await PrivateApiV3.post<CebiaCheckResponse>('/vehicles/cebia_check', data);
  if (!response.data.cebia_response.length) {
    return { display_name: '' };
  }

  return response.data.cebia_response[0];
}
