import type { VehicleEquipment } from './Enum';
import type { VehicleAttributes } from './VehicleAttributes';

export type VehicleDetailEquipment = {
  trans_code: string;
  label: string;
  value: boolean;
};

export function parseVehicleEquipment(
  attributes: Partial<VehicleAttributes>,
  equipment: VehicleEquipment[],
): Record<string, boolean> {
  const result: Record<string, boolean> = {};
  for (const item of equipment) {
    let isSelected = false;
    if (item.trans_code in attributes) {
      const it = attributes[item.trans_code];
      isSelected = typeof it === 'string' ? it === '1' : Boolean(it);
    }
    result[item.trans_code] = isSelected;
  }
  return result;
}

export function parseVehicleEquipmentResponse(equipment: VehicleDetailEquipment[]): Record<string, boolean> {
  const result: Record<string, boolean> = {};
  for (const item of equipment) {
    result[item.trans_code] = item.value;
  }
  return result;
}
