import qs from 'query-string';

import { permission } from '../decorators';

import { getCookie } from './Cookies';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace Optanon {
    type ConsentChangedEvent = CustomEvent<ConsentGroup[]>;
    type ConsentChangeListener = (event: ConsentChangedEvent) => void;

    interface Api {
      ToggleInfoDisplay(): void;

      changeLanguage(lang: string): void;

      OnConsentChanged(listener: ConsentChangeListener): void;
    }
  }

  interface Window {
    OneTrust?: Optanon.Api;

    OptanonWrapper(): void;
  }
}

export enum ConsentGroup {
  NECESSARY = 'C0001',
  ADVANCED_ANALYTICS = 'C0002',
  PREFERENCES = 'C0003',
  PERSONALISED_OFFERS = 'C0004',
}

export type OneTrustListener = (groups: Readonly<Record<ConsentGroup, boolean>>) => void;

class OneTrust {
  private callbacks: Set<OneTrustListener> = new Set();
  private settings: Record<ConsentGroup, boolean> = {
    [ConsentGroup.NECESSARY]: true,
    [ConsentGroup.ADVANCED_ANALYTICS]: false,
    [ConsentGroup.PREFERENCES]: false,
    [ConsentGroup.PERSONALISED_OFFERS]: false,
  };

  constructor() {
    if (typeof window === 'undefined') return;
    window.OptanonWrapper = () => {
      this.init();
    };
    window.OneTrust?.OnConsentChanged(event => {
      let settingsChanged = false;
      for (const group of Object.keys(this.settings)) {
        const enabled = event.detail.includes(group);
        if (this.settings[group] !== enabled) {
          this.settings[group] = event.detail.includes(group);
          settingsChanged = true;
        }
      }
      if (settingsChanged) this.emit();
    });

    this.init();
  }

  public isCookieConsentGroupAllowed(group: ConsentGroup): boolean {
    return this.settings[group];
  }

  @permission(typeof window !== 'undefined')
  public changeLanguage(lang: string) {
    window.OneTrust?.changeLanguage(lang);
  }

  @permission(typeof window !== 'undefined')
  public openCookieSettings() {
    window?.OneTrust?.ToggleInfoDisplay();
  }

  public subscribe(listener: OneTrustListener): void {
    this.callbacks.add(listener);
  }

  public unsubscribe(listener: OneTrustListener): void {
    this.callbacks.delete(listener);
  }

  public getActiveGroups(): ConsentGroup[] {
    return Object.keys(this.settings).filter(group => this.settings[group]);
  }

  private init() {
    const cookie = getCookie('OptanonConsent');
    if (!cookie) return;
    let settingsChanged = false;
    // groups is a string in format C0001:1,C0002:0,C0003:1,C0004:0
    const parsed = qs.parse(cookie) as { groups?: string };
    const settings = parsed.groups?.split(',') ?? [];
    for (const setting of settings) {
      const [group, consent] = setting.split(':');
      const enabled = consent === '1';
      if (this.settings[group as ConsentGroup] !== enabled) {
        this.settings[group as ConsentGroup] = enabled;
        settingsChanged = true;
      }
    }
    if (settingsChanged) this.emit();
  }

  private emit() {
    for (const callback of this.callbacks) {
      callback(this.settings);
    }
  }
}

const instance = new OneTrust();
export default instance;
