import { PrivateApiV3 } from '../services/Api';
import { type Datelike, toISOString } from '../services/DateTime';

export enum DocumentType {
  AVATAR = 'avatar_photo',
  DL_FRONT = 'dl_1',
  DL_BACK = 'dl_2',
  ID_FRONT = 'id_1',
  ID_BACK = 'id_2',
  ID_SELFIE = 'id_1_selfie',
  RC_FRONT = 'rc_1',
  RC_BACK = 'rc_2',

  // Vehicle + Handover photos
  MAIN_PHOTO = 'main_photo',
  FRONT_PHOTO = 'front_photo',
  FRONT_LEFT_PHOTO = 'front_left_photo',
  FRONT_RIGHT_PHOTO = 'front_right_photo',
  BACK_PHOTO = 'back_photo',
  BACK_LEFT_PHOTO = 'back_left_photo',
  BACK_RIGHT_PHOTO = 'back_right_photo',
  LEFT_PHOTO = 'left_photo',
  RIGHT_PHOTO = 'right_photo',
  SIDE_PHOTO_LEFT = 'side_photo_left',
  SIDE_PHOTO_RIGHT = 'side_photo_right',
  EXTERIER_PHOTO = 'exterier_photo',
  INTERIER_PHOTO = 'interier_photo',
  INTERIER2_PHOTO = 'interier2_photo',
  INTERIOR_PHOTO = 'interior_photo',
  INTERIOR_PHOTO_2 = 'interior_photo_2',
  INTERIOR_PHOTO_3 = 'interior_photo_3',
  OTHER_PHOTOS = 'other_photos',
  RAID_PHOTO = 'raid_photo',
  PHOTO = 'photo',
}

export interface Document<T extends DocumentType = DocumentType> {
  hash: string;
  type: T;
  state: string;
  mime_type: string;
  size: number;
  is_public: boolean;
  created_at: string;

  cdn_ident: string; // original size
  cdn_ident_s: string; // 160x120 size
  cdn_ident_m: string; // 667x500 size
  cdn_ident_l: string; // 1333x1000 size

  cdn_ident_webp: string; // original size
  cdn_ident_webp_s: string; // 160x120 size
  cdn_ident_webp_m: string; // 667x500 size
  cdn_ident_webp_l: string; // 1333x1000 size
}

export enum CardType {
  DRIVING_LICENSE = 'DL',
  IDENTIFICATION_CARD = 'ID',
  REGISTRATION_CARD = 'RC',
}

export interface Card<T extends CardType> {
  type: T;
  identification_number: string;
  expiration_date: Datelike;
  has_expiration_date: boolean;
  documents: Document[];
}

export type UserCardType = CardType.DRIVING_LICENSE | CardType.IDENTIFICATION_CARD;
export type UserCard = Card<UserCardType>;

export const base64ToArrayBuffer = (base64: string): ArrayBuffer => {
  const binaryString = window.atob(base64);
  const bytes = new Uint8Array(binaryString.length);

  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return bytes.buffer;
};

export const base64ImageToFormData = (base64: string) => {
  const data = base64ToArrayBuffer(base64.replace(/^data:image\/jpeg;base64,/, ''));
  const blob = new Blob([data], { type: 'image/jpeg' });

  const formData = new FormData();
  formData.append('file', blob, 'file');

  return formData;
};

export async function uploadUserDocument<T extends DocumentType>(type: T, base64: string) {
  const formData = base64ImageToFormData(base64);

  const response = await PrivateApiV3.post<Document<T>>(`/users/documents/${type}/file`, formData);
  return response.data;
}

export async function removeUserDocument(documentHash: string) {
  await PrivateApiV3.delete(`/user/documents/${documentHash}`);
}

export async function fetchUserCards() {
  const response = await PrivateApiV3.get<UserCard[]>('/user/documents/card');
  return response.data;
}

export async function updateUserCard<T extends UserCardType>(cardType: T, data: Partial<Card<T>>) {
  const response = await PrivateApiV3.post<Card<T>>(`/user/documents/card/${cardType}`, {
    identification_number: data.identification_number,
    expiration_date: toISOString(data.expiration_date),
    has_expiration_date: data.has_expiration_date ?? !!data.expiration_date,
  });
  return response.data;
}

export async function uploadUserCard<T extends UserCardType>(cardType: T, documentType: DocumentType, base64: string) {
  const formData = base64ImageToFormData(base64);

  const response = await PrivateApiV3.post<Card<T>>(`/users/cards/${cardType}/${documentType}/file`, formData);
  return response.data;
}

// --- VEHICLE ---

export async function fetchVehicleDocuments(hash: string) {
  const response = await PrivateApiV3.get<Document[]>(`/vehicles/${hash}/documents`);
  return response.data;
}

export async function uploadVehicleDocument<T extends DocumentType>(vehicleHash: string, type: T, base64: string) {
  const formData = base64ImageToFormData(base64);

  const response = await PrivateApiV3.post<Document<T>>(`/vehicles/${vehicleHash}/documents/${type}/file`, formData);
  return response.data;
}

export async function removeVehicleDocument(vehicleHash: string, documentHash: string) {
  await PrivateApiV3.delete(`/vehicles/${vehicleHash}/documents/${documentHash}`);
}

export async function uploadVehicleCard<T extends DocumentType>(
  vehicleHash: string,
  cardType: CardType,
  documentType: T,
  base64: string,
) {
  const formData = base64ImageToFormData(base64);

  const response = await PrivateApiV3.post<Document<T>>(
    `/vehicles/${vehicleHash}/cards/${cardType}/${documentType}/file`,
    formData,
  );

  return response.data;
}

// --- TRIP ---

export async function uploadTripDocument<T extends DocumentType>(hash: string, type: T, base64: string) {
  const formData = base64ImageToFormData(base64);

  const response = await PrivateApiV3.post<Document<T>>(`/trips/${hash}/handovers/documents/${type}`, formData);
  return response.data;
}
