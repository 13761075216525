import React from 'react';
import type { WrapPageElementBrowserArgs } from 'gatsby';

import type { Language } from './services/Language';
import PageWrapper from './PageWrapper';
import { RouteProvider } from './RouteProvider';
import { TranslationProvider } from './TranslationProvider';

interface PageContext {
  language: string;
  originalPath: string;
  parametrized: boolean;
  campaignId?: string;
  vehicleFeedTag?: string;
}

export function wrapPageElement({ element, props }: WrapPageElementBrowserArgs<UnsafeAny, PageContext>) {
  const language = props.pageContext.language;
  const usePrefix = props.path !== props.pageContext.originalPath;

  return (
    <TranslationProvider language={language as Language}>
      <RouteProvider
        parametrized={props.pageContext.parametrized}
        params={props.params}
        prefix={usePrefix ? language : undefined}
      >
        <PageWrapper
          campaignId={props.pageContext.campaignId}
          language={language}
          vehicleFeedFilter={props.pageContext.vehicleFeedTag}
        >
          {element}
        </PageWrapper>
      </RouteProvider>
    </TranslationProvider>
  );
}
