import { PrivateApiV3 } from '../services/Api';

export enum UserRole {
  DRIVER = 'driver',
  OWNER = 'owner',
  BOTH = 'both',
  UNKNOWN = 'unknown',
}

export async function updateUserRole(role: UserRole) {
  await PrivateApiV3.patch('/user/registration-role', { role });
}
