import { PrivateApiV3 } from '../services/Api';
import type { DateRangeQuery } from '../services/DateTime';
import { Logger } from '../services/Logger';

import type { OrderQuery } from './Order';
import type { PriceType } from './Price';

export enum OrderProductType {
  OWN_INSURANCE = 'own',
  SECOND_DRIVER = 'second_driver',
  CO2_COMPENSATION = 'co2',
}

export interface OrderProduct<Type extends OrderProductType | string = OrderProductType | string> {
  id: number;
  type: Type;
}

export interface SelectedOrderProduct<
  Type extends OrderProductType | string = OrderProductType | string,
  Data = UnsafeAny,
> {
  id: number;
  type: Type;
  data?: Data;
}

export interface SelectedOrderProducts {
  [OrderProductType.SECOND_DRIVER]?: SelectedOrderProduct<OrderProductType.SECOND_DRIVER, SecondDriverData>;
  [OrderProductType.CO2_COMPENSATION]?: SelectedOrderProduct<OrderProductType.CO2_COMPENSATION>;
  [OrderProductType.OWN_INSURANCE]?: SelectedOrderProduct<OrderProductType.OWN_INSURANCE>;

  [type: OrderProductType | string]: SelectedOrderProduct<string>;
}

export interface TripInsurance extends OrderProduct<OrderProductType.OWN_INSURANCE | string> {
  recommended: boolean;
  default: boolean;
  is_required: boolean;
  name: string;
  description: string;
  description_short?: string;
  description_list: {
    type: 'text' | 'link';
    content: string;
  }[];
  price: PriceType;
  deposit?: PriceType;
}

export interface TripSecondDriverService extends OrderProduct<OrderProductType.SECOND_DRIVER> {
  title: string;
  description: string;
  price: PriceType;
}

export interface TripCO2CompensationService extends OrderProduct<OrderProductType.CO2_COMPENSATION> {
  title: string;
  description: string;
  price: PriceType;
  description_short?: string;
  description_list: {
    type: 'text' | 'link';
    content: string;
  }[];
}

export type OrderProducts = TripInsurance | TripSecondDriverService | TripCO2CompensationService;

export const isTripMandatoryInsurance = (insurance: TripInsurance): boolean => insurance.is_required;

export const isTripSecondDriverService = (
  product: OrderProducts | SelectedOrderProduct,
): product is TripSecondDriverService => product.type === OrderProductType.SECOND_DRIVER;

export const isTripCO2CompensationService = (
  product: OrderProducts | SelectedOrderProduct,
): product is TripCO2CompensationService => product.type === OrderProductType.CO2_COMPENSATION;

export const isTripInsurance = (product: OrderProducts | SelectedOrderProduct): product is TripInsurance =>
  !isTripSecondDriverService(product) && !isTripCO2CompensationService(product);

export const isProductSelected = (
  product: OrderProducts | SelectedOrderProduct,
  selectedProducts: SelectedOrderProducts = {},
): boolean => product.type in selectedProducts && selectedProducts[product.type].id === product.id;

export function getOrderProductState<T extends OrderProductType | string>(
  product: OrderProduct<T>,
  selectedProducts: SelectedOrderProducts = {},
): SelectedOrderProducts[T] | undefined {
  if (!(product.type in selectedProducts)) {
    return undefined;
  }
  return selectedProducts[product.type];
}

async function fetchTripInsurances(hash: string, query: DateRangeQuery): Promise<TripInsurance[]> {
  try {
    const insurances = await PrivateApiV3.get<TripInsurance[]>(`/vehicles/${hash}/insurances`, {
      trip_from: query.date_from,
      trip_to: query.date_to,
    });
    return insurances.data;
  } catch {
    return [];
  }
}

async function fetchTripAdditionalServices(vehicleHash: string, query: OrderQuery): Promise<OrderProducts[]> {
  try {
    const response = await PrivateApiV3.get<OrderProducts[]>(`/vehicles/${vehicleHash}/additional_services`, {
      trip_from: query.date_from,
      trip_to: query.date_to,
    });
    return response.data;
  } catch (error) {
    Logger.default.debug('Error getting vehicle additional services', {
      error,
    });
    return [];
  }
}

export async function fetchOrderProducts(hash: string, query: OrderQuery) {
  const tripInsuranceChoices: TripInsurance[] = [];
  const otherTripInsurances: (TripInsurance | TripCO2CompensationService)[] = [];
  const otherProducts: OrderProduct[] = [];
  let selectedMandatoryInsurance: number | undefined;
  const selectedProducts: SelectedOrderProducts = {};

  const [insurances, additionalServices] = await Promise.all([
    fetchTripInsurances(hash, query).catch(() => [] as TripInsurance[]),
    fetchTripAdditionalServices(hash, query),
  ]);

  for (const insurance of insurances) {
    if (isTripMandatoryInsurance(insurance)) {
      tripInsuranceChoices.push(insurance);
      if (insurance.default) {
        selectedMandatoryInsurance = insurance.id;
      }
    } else {
      otherTripInsurances.push(insurance);
      if (insurance.default) {
        selectedProducts[insurance.type] = {
          id: insurance.id,
          type: insurance.type,
        };
      }
    }
  }

  for (const product of additionalServices) {
    if (isTripCO2CompensationService(product)) {
      otherTripInsurances.push(product);
    } else {
      otherProducts.push(product);
    }
  }

  return {
    tripInsuranceChoices,
    otherTripInsurances,
    otherProducts,
    selectedMandatoryInsurance,
    selectedProducts,
  };
}

export interface SecondDriverData {
  first_name: string;
  last_name: string;
  dl_number: string;
  email: string;
}
