import { PrivateApiV3 } from '../services/Api';
import type { Country } from '../services/Country';

import type { Coordinates } from './Coordinates';

export enum LocationType {
  HOME = 'home',
  BILLING_ADDRESS = 'billing_address',
  COMPANY_RESIDENCY = 'company_residency',
  PARKING = 'parking',
}

export type UserLocationTypes = LocationType.HOME | LocationType.BILLING_ADDRESS | LocationType.COMPANY_RESIDENCY;

export interface Location<T extends LocationType = LocationType> extends Coordinates {
  id?: number;
  type: T;
  street: string;
  number?: string;
  zip: string;
  city: string;
  country_code: Country;
  created_at?: string;
  updated_at?: string;
}

export type VehicleParking = Location<LocationType.PARKING>;
export type UserLocation<T extends UserLocationTypes = UserLocationTypes> = Location<T>;

export const getUserLocations = async (): Promise<UserLocation[]> => {
  try {
    const response = await PrivateApiV3.get<UserLocation[]>('/user/locations');
    return response.data;
  } catch (error) {
    return [];
  }
};

export const fetchUserLocation = async <T extends UserLocationTypes>(type: T): Promise<UserLocation<T>> => {
  const response = await PrivateApiV3.get<UserLocation<T>>(`/user/location/${type}`);
  return response.data;
};

export async function createUserLocation<T extends UserLocationTypes>(location: Location<T>) {
  return await PrivateApiV3.post<UserLocation<T>>(`/user/location/${location.type}`, {
    id: location.id,
    type: location.type,
    street: location.street,
    number: location.number,
    city: location.city,
    zip: location.zip,
    country_code: location.country_code,
  } as Location<T>);
}

export const updateUserLocation = async <T extends UserLocationTypes>(location: UserLocation<T>) => {
  let hasLocation = false;
  try {
    const userLocations = await getUserLocations();
    hasLocation = userLocations.some(userLocation => {
      return userLocation.type === location.type;
    });
  } catch (error) {
    // ignore
  }

  const data: Location<T> = {
    id: location.id,
    type: location.type,
    street: location.street,
    number: location.number,
    city: location.city,
    zip: location.zip,
    country_code: location.country_code,
  };
  if (hasLocation) {
    return await PrivateApiV3.patch<UserLocation<T>>(`/user/location/${data.type}`, data);
  } else {
    return await createUserLocation(data);
  }
};

export const getNavigationLink = (location: Coordinates) =>
  `http://www.google.com/maps/place/${location.lat},${location.lon}`;
