import { PrivateApiV3 } from '../services/Api';
import type { Country } from '../services/Country';
import type { Datelike } from '../services/DateTime';
import type { Page } from '../services/Paging';

import type { Document, DocumentType } from './Document';
import type { Attribute, VehicleEquipment } from './Enum';
import type { VehicleParking } from './Location';
import type { CurrencyCode, PriceType } from './Price';
import type { Trip } from './Trip';
import type { IVehicleState, VehicleCommon } from './Vehicle';
import { parseVehicleAttributes, type VehicleAttributes, type VehicleAttributesResponse } from './VehicleAttributes';
import type { VehicleCalendarAPI } from './VehicleCalendar';
import type { VehicleChecklist } from './VehicleChecklist';
import type { VehicleDetailPrices } from './VehicleDetailPrices';
import type { FlexibleDiscount, MyVehicleDiscount } from './VehiclePriceList';

export interface MyVehicle extends IVehicleState {
  hash: string;
  country: {
    alpha2_code: Country;
    currency: CurrencyCode;
  };
  name: string;
  manufacturer: string;
  manufacturer_id: number;
  model: string;
  model_id: number;
  is_electric: boolean;
  locations: VehicleParking[];
  main_image: Document<DocumentType.MAIN_PHOTO>;

  registration_number: string;
  checklist: VehicleChecklist;
  owner_tips: {
    check_calendar_availability: boolean;
    update_car_detail: boolean;
  };

  today_trip?: Trip;
  incoming_requests: number;
  confirmed_reservations: number;
  total_income: PriceType;

  flexible_discounts: FlexibleDiscount[];
  prices: VehicleDetailPrices;

  ratings_count: number;
  rating_average_int: number;
  rating_average_float: number;
}

export interface MyVehicleDetail extends MyVehicle, VehicleCommon {
  attributes: Partial<VehicleAttributes>;
  documents: Document[];
  equipments: VehicleEquipment[];
  calendar: VehicleCalendarAPI;
}

export interface MyVehicleBasicInfo {
  vin: string;
  license_plate: string;
  mileage: number;
  country_code: Country;
  has_own_insurance?: boolean;
}

export interface MyVehicleTechnicalDetail_1 {
  manufacturer_id: number;
  model_id: number;
  price: number;
  manufacture_year: number;
  total_weight: number;
  type: string;
  seats: number;
  fuel: string;
}

export interface MyVehicleTechnicalDetail_Combustion {
  transmission: string;
  power: number;
  engine_size: number;
  fuel_tank_capacity: number;
  consumption: number;
}

export interface MyVehicleTechnicalDetail_Electric {
  power: number;
  electric_battery_capacity: number;
  electric_charging_time: number;
  electric_range: number;
}

export enum AdditionalInfoType {
  NON_EUROPEAN = 0,
  FIRST_REGISTRATION_IN_PL = 1,
  IMPORTED_FROM_NA = 2,
}

export function getAdditionalInfoType(vehicleAttributes: Partial<VehicleAttributes>): AdditionalInfoType {
  if (vehicleAttributes.is_first_registration_in_pl) {
    return AdditionalInfoType.FIRST_REGISTRATION_IN_PL;
  }

  if (vehicleAttributes.non_european) {
    return AdditionalInfoType.NON_EUROPEAN;
  }

  if (vehicleAttributes.is_imported_from_na) {
    return AdditionalInfoType.IMPORTED_FROM_NA;
  }

  return null;
}

export interface MyVehicleTechnicalDetail_3 {
  number_of_doors: number;
  trim_style: string;
  number_of_owners: number;
  first_registration: Datelike;
  additional_info: AdditionalInfoType;
}

export type MyVehicleTechnicalDetail = MyVehicleTechnicalDetail_1 &
  MyVehicleTechnicalDetail_Combustion &
  MyVehicleTechnicalDetail_Electric &
  MyVehicleTechnicalDetail_3;

export interface MyVehiclePrices {
  price_per_day: number;
  price_per_km: number;
  flexible_discounts: MyVehicleDiscount[];
}

export async function fetchMyVehicles() {
  const response = await PrivateApiV3.get<Page<MyVehicle>>('/user/my_vehicles', { limit: 100 });
  return response.data;
}

export async function fetchMyVehicleDetail(hash: string) {
  const response = await PrivateApiV3.get<MyVehicleDetail>(`/user/my_vehicles/${hash}`);
  return response.data;
}

export async function createMyVehicle(data: MyVehicleBasicInfo) {
  const response = await PrivateApiV3.post<MyVehicleDetail>('/my_vehicles/registration', {
    vin: data.vin,
    mileage: data.mileage,
    license_plate: data.license_plate,
    country_code: data.country_code,
    own_insurance: Boolean(data.has_own_insurance),
  });
  return response.data;
}

type RegistrationAttributesResponse = {
  is_electric: boolean;
  attributes: VehicleAttributesResponse;
};

export async function updateMyVehicleRegistrationAttributes(
  hash: string,
  data: {
    model_id?: number;
    attributes?: Partial<VehicleAttributes>;
    is_last_step?: boolean;
  },
) {
  const response = await PrivateApiV3.patch<RegistrationAttributesResponse>(
    `/my_vehicles/${hash}/registration_attributes`,
    data,
  );
  return {
    is_electric: response.data.is_electric,
    attributes: parseVehicleAttributes(response.data.attributes),
  };
}

export interface UserBenefit extends Attribute<boolean> {
  vehicle_value: boolean;
}

export async function fetchMyVehicleUserBenefits(vehicleHash: string) {
  const response = await PrivateApiV3.get<UserBenefit[]>(`/my_vehicles/${vehicleHash}/user-benefits`);
  return response.data;
}
