import { PrivateApiV3 } from '../services/Api';
import type { Datelike } from '../services/DateTime';
import {
  type Constraints,
  getAddDoc,
  getCollection,
  getDocument,
  getQuery,
  getUpdateDoc,
  type TDocumentData,
  type TDocumentReference,
  type TQuery,
} from '../services/Firebase';
import type { Page } from '../services/Paging';

import { base64ImageToFormData, type Document } from './Document';
import type { TripState } from './Trip';

export type Message = {
  id: string;
  timestamp: Datelike;
  userHash: string;
  seenBy: string[];
};

export type TextMessage = Message & {
  type: 'text';
  text: string;
  documentHash: null;
};

export type PhotoMessage = Message & {
  type: 'photo';
  text: null;
  documentHash: string;
};

export const MESSAGE_TYPES = {
  TEXT: 'text',
  DOCUMENT: 'document',
};

export type ChatTripDetail = {
  vehicle_name: string;
  date_from: string;
  date_to: string;
  trip_hash: string;
  trip_state: TripState;
  trip_state_name: string;
  main_image: Document;
};

export type ChatParticipant = {
  hash: string;
  name: string;
  avatar?: Document;
};

export type ChatDetail = {
  chat_id: string;
  last_message?: {
    type: 'text' | 'document';
    text: string;
    document_hash: string;
    created_at: string;
    seen_by_me: boolean;
  };
  participants: ChatParticipant[];
  trip: ChatTripDetail;
};

export const fetchChatList = async (page?: number) => {
  const response = await PrivateApiV3.get<Page<ChatDetail>>('/chats', { page });
  return response.data;
};

export const getChatQuery = (chatId: string): TQuery => {
  const constraints: Constraints = { orderBy: 'timestamp' };
  const q: TQuery = getQuery(getCollection(`chats/${chatId}/messages`), constraints);
  return q;
};

export const getChatUserQuery = (chatId: string, userId: string): TQuery => {
  const constraints: Constraints = { where: 'userHash', userId };
  const q: TQuery = getQuery(getCollection(`chats/${chatId}/members/`), constraints);
  return q;
};

export const updateChatUser = async (chatId: string, userId: string, data: UnsafeAny): Promise<void> => {
  const chatUserRef: TDocumentReference = getDocument(`chats/${chatId}/members`, userId);

  await getUpdateDoc(chatUserRef, {
    ...data,
  });
};

export const addChatMessage = async (chatId, message, userId): Promise<TDocumentData> => {
  const docRef: TDocumentData = await getAddDoc(getCollection(`chats/${chatId}/messages`), {
    type: 'text',
    text: message,
    documentHash: null,
    timestamp: new Date(),
    userHash: userId,
    seenBy: [userId],
  });
  return docRef;
};

export const updateChatMessage = async (chatId, messageId, data) => {
  const chatMessageRef: TDocumentReference = getDocument(`chats/${chatId}/messages/${messageId}`, messageId);

  await getUpdateDoc(chatMessageRef, {
    ...data,
  });
};

export const uploadDocument = async (chatId: string, base64: string) => {
  const formData = base64ImageToFormData(base64);

  await PrivateApiV3.post(`/chats/${chatId}/documents/file`, formData);
};
