import { webalize } from '../helpers';
import { ApiError, PrivateApiV3 } from '../services/Api';
import type { Country } from '../services/Country';

import type { Document, DocumentType } from './Document';
import type { Attribute } from './Enum';
import type { VehicleParking } from './Location';
import type { TripInsurance } from './OrderProduct';
import type { CurrencyCode, PriceType } from './Price';
import type { RatingCategory, RatingItem } from './Ratings';
import type { Owner } from './User';
import { parseVehicleAttributes, type VehicleAttributes, type VehicleAttributesResponse } from './VehicleAttributes';
import type { VehicleCalendar, VehicleCalendarAPI } from './VehicleCalendar';
import type { VehicleDetailEquipment } from './VehicleDetailEquipment';
import type { VehicleDetailPrices } from './VehicleDetailPrices';
import type { VehicleTag } from './VehicleTag';

export enum VehicleState {
  DRAFT = 'draft',
  INACTIVE = 'inactive',
  WAITING_FOR_REVISION = 'waiting_for_revision',
  IN_REVISION = 'in_revision',
  ACTIVE = 'active',
  SUSPENDED = 'suspended',
  DELETED = 'deleted',
}

export interface IVehicleState {
  state: VehicleState;
}

export interface VehicleCommon extends IVehicleState {
  hash: string;
  name: string;
  description?: string;
  vin: string | null;
  distance?: number;

  country: {
    alpha2_code: Country;
    currency: CurrencyCode;
  };

  type: string;
  manufacturer: string;
  manufacturer_id: number;
  model: string;
  model_id: number;
  fuel: string;
  is_electric: boolean;

  ratings_count: number;
  rating_average_float: number;
  rating_average_int: number;
  rating_categories: RatingCategory[];

  price: PriceType;
  main_image: Document<DocumentType.MAIN_PHOTO>;
  locations: VehicleParking[];
  tags: VehicleTag[];
  instant_booking: boolean;
  is_possible_for_drivers_from18_years: boolean;
  is_favorite?: boolean;
}

export interface Vehicle extends VehicleCommon {
  equipment: Record<string, boolean>;
  prices: VehicleDetailPrices;
}

interface VehicleDetailCommon extends VehicleCommon {
  readonly vin: string;
  attributes: VehicleAttributes;
  ratings: RatingItem[];
  documents: Document[];
  owner: Owner;
  owner_insurance: boolean;
  own_insurances: TripInsurance[];
  prices: VehicleDetailPrices;
  user_benefits: Attribute<boolean>[];
}

export interface VehicleDetailAPI extends VehicleDetailCommon {
  calendar: VehicleCalendarAPI;
  equipment: VehicleDetailEquipment[];
}

export interface VehicleDetail extends VehicleDetailCommon {
  calendar: VehicleCalendar;
  equipment: Record<string, boolean>;
}

export const CONSUMPTION_MIN = 0;
export const CONSUMPTION_MAX = 40;
export const defaultVehicleConsumption = Math.round((CONSUMPTION_MIN + CONSUMPTION_MAX) / 2);

export const COMMISSION_PER_DAY_MIN = 200;
export const COMMISSION_PER_DAY_MAX = 20000;

export const PRICE_PER_KM_MIN = 0;
export const PRICE_PER_KM_MAX = 100;

export const MIN_DAYS_DEFAULT = 1;
export const MAX_DAYS_DEFAULT = 30;

export function canVehicleBeActivated(vehicle: IVehicleState): boolean {
  return VehicleState.SUSPENDED === vehicle.state;
}

export function canVehicleBeDeactivated(vehicle: IVehicleState): boolean {
  return [VehicleState.WAITING_FOR_REVISION, VehicleState.IN_REVISION, VehicleState.ACTIVE].includes(vehicle.state);
}

export function canVehicleBeDeleted(vehicle: IVehicleState): boolean {
  return [
    VehicleState.INACTIVE,
    VehicleState.WAITING_FOR_REVISION,
    VehicleState.IN_REVISION,
    VehicleState.ACTIVE,
    VehicleState.SUSPENDED,
  ].includes(vehicle.state);
}

export function isInstantBookable(vehicle: VehicleDetail): boolean {
  return vehicle.instant_booking;
}

export function isVehicleInRegistration(vehicle: IVehicleState): boolean {
  return VehicleState.INACTIVE === vehicle.state;
}

export function isVehicleElectric(vehicle: VehicleCommon): boolean {
  return vehicle.is_electric;
}

export async function deleteVehicle(hash: string, text: string) {
  const body = { reason: text };
  await PrivateApiV3.delete(`/vehicles/${hash}`, body);
}

export async function activateVehicle(hash: string) {
  const response = await PrivateApiV3.patch<VehicleDetail>(`/vehicles/${hash}/active`);
  return response.data;
}

export async function deactivateVehicle(hash: string, text: string) {
  const body = { reason: text };
  const response = await PrivateApiV3.patch<VehicleDetail>(`/vehicles/${hash}/suspend`, body);
  return response.data;
}

export async function updateVehicleAttributes(hash: string, attributes: Partial<VehicleAttributes>) {
  const body = { ...attributes };
  if ('manufacture_year' in attributes) {
    body.manufacturing_year = attributes.manufacture_year as string;
    delete body.manufacture_year;
  }
  if ('license_plate' in attributes) {
    body.spz = attributes.license_plate;
    delete body.license_plate;
  }
  const response = await PrivateApiV3.patch<VehicleAttributesResponse>(`/vehicles/${hash}/attributes`, body);
  return parseVehicleAttributes(response.data);
}

export async function addVehicleLocation(hash: string, location: VehicleParking) {
  if (location.id) {
    return await updateVehicleLocation(hash, location);
  }
  try {
    const response = await PrivateApiV3.post<VehicleParking>(`/vehicles/${hash}/location/${location.type}`, location);
    return response.data;
  } catch (error) {
    if (error instanceof ApiError) {
      error.findValidationError();
      if (error.message) {
        return Promise.reject(error);
      }
    }
    return await updateVehicleLocation(hash, location);
  }
}

export async function updateVehicleLocation(hash: string, location: Partial<VehicleParking>) {
  const body = { ...location };
  delete body.lat;
  delete body.lon;
  const response = await PrivateApiV3.patch<VehicleParking>(`/vehicles/${hash}/location/${location.type}`, body);
  return response.data;
}

export const generateCarDetailLinkParams = (
  manufacturer: string | undefined,
  model: string | undefined,
  type: string | undefined,
  fuel: string | undefined,
) => (manufacturer && model && type && fuel ? webalize([manufacturer, model, type, fuel].join('-')) : undefined);
